import React, {useEffect, useState} from 'react'
import './SystemNotes.scss'
import {Alert, Button, Form} from 'react-bootstrap'
import {useFormFields} from '../libs/formLib'
import LoaderButton from './LoaderButton'
import {api, apiGet} from '../libs/apiLib'
import {username} from '../libs/userLib'
import timezoneMoment from 'moment-timezone'
import Busy from './Busy'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const Note = props => {
  const noteId = props.id
  const [initialFields] = useState({
    subject: {
      value: props.subject || '',
      formFieldOptions: {noTrim: true},
    },
    body: {
      value: props.body || '',
      formFieldOptions: {noTrim: true},
    },
  })
  const [errors, setErrors] = useState({})
  const [maxSubjectLength] = useState(props.maxSubjectLength || 100)
  const [maxBodyLength] = useState(props.maxBodyLength || 1000)
  const [isLoading, setIsLoading] = useState(false)
  const [editMode, setEditMode] = useState(props.editMode || false)
  const getErrors = fields => {
    const errors = {}
    if (!fields.subject.length) errors.subject = 'Required'
    if (!fields.body.length) errors.body = 'Required'
    return errors
  }
  const validator = fields => {
    const errors = getErrors(fields)

    setErrors(errors)
    return errors
  }
  const [fields, handleFieldChange] = useFormFields(initialFields, validator)
  const onChange = event => {
    const {
      target: {id, value, type},
    } = event
    if (id === 'subject' && value.length > maxSubjectLength)
      return handleFieldChange({
        target: {
          id,
          type,
          value: value.slice(0, maxSubjectLength),
        },
      })
    if (id === 'body' && value.length > maxBodyLength)
      return handleFieldChange({
        target: {
          id,
          type,
          value: value.slice(0, maxBodyLength),
        },
      })
    handleFieldChange(event)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setErrors({})
    setIsLoading(true)
    await props.handleSubmit(noteId, fields)
    setIsLoading(false)
    if (noteId) setEditMode(false)
  }
  if (!editMode) {
    const user = props.users[props.updatedBy || props.cognitoUsername] // props.user if this is a new
    return (
      <div className={'system-note'} onClick={() => setEditMode(true)}>
        <div className={'subject-line'}>
          <div className={'subject'}>{props.subject}</div>
          <a
            className={'username'}
            href={`mailto:${user.email}?subject=re: ${props.subject}&body=${props.body}`}
          >
            {username(user)}
          </a>
          <div className={'date'}>
            {(props.updatedTimestamp || props.createdTimestamp) &&
              timezoneMoment(props.updatedTimestamp || props.createdTimestamp).format('HH:mm—MMM-D-YY')}
          </div>
        </div>
        <hr />
        <div className={'body'}>{props.body}</div>
      </div>
    )
  }
  return (
    <div className={'system-note-edit'}>
      <Form className={'system-note-form'} onSubmit={handleSubmit}>
        <Form.Group className="subject" controlId="subject">
          <Form.Control
            readOnly={!editMode}
            type="text"
            placeholder="Subject"
            onChange={onChange}
            value={fields.subject}
          />
          <Form.Text>{errors.subject && <span className="alert-danger">{errors.subject}</span>}</Form.Text>
        </Form.Group>
        <Form.Group className="body" controlId="body">
          <Form.Control
            readOnly={!editMode}
            as="textarea"
            value={fields.body}
            onChange={onChange}
            placeholder={'Message'}
          />
          <Form.Text>{errors.body && <span className="alert-danger">{errors.body}</span>}</Form.Text>
          {maxBodyLength - fields.body.length < 200 && (
            <Form.Text className={'char-count'}>{`${
              maxBodyLength - fields.body.length
            } characters remaining`}</Form.Text>
          )}
        </Form.Group>
      </Form>
      <div className={'button-column'}>
        <LoaderButton
          type="submit"
          isLoading={isLoading}
          disabled={Object.keys(getErrors(fields)).length > 0}
          onClick={handleSubmit}
        >
          Save
        </LoaderButton>
        {!props.noCancel && (
          <Button
            onClick={() => {
              /* nodeId is undefined if this is a new note and we are in newNoteMode */
              if (!noteId) props.setNewNoteMode(false)
              else setEditMode(false)
            }}
          >
            X
          </Button>
        )}
        {props.onDelete && (
          <div className={'trash-can'}>
            <FontAwesomeIcon icon="trash" onClick={() => props.onDelete(props.id)} />
          </div>
        )}
      </div>
    </div>
  )
}
export default props => {
  const localStorageSortOrderKey = `${props.id}-system-notes-sort-order`
  const [sortOrder, setSortOrder] = useState(localStorage.getItem(localStorageSortOrderKey) || 'oldest')
  const [notes, setNotes] = useState([])
  const [newNoteMode, setNewNoteMode] = useState(false)
  const [response, setResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    ;(async () => {
      try {
        const api = apiGet()
        const result = await api.get(`/device/${props.id}/note`)
        setNewNoteMode(!result.data.length)
        if (sortOrder === 'newest')
          setNotes(
            result.data.sort((a, b) => {
              return timezoneMoment(a.updatedTimestamp || a.createdTimestamp).valueOf() >
                timezoneMoment(b.updatedTimestamp || b.createdTimestamp).valueOf()
                ? 1
                : -1
            })
          )
        else
          setNotes(
            result.data.sort((b, a) => {
              return timezoneMoment(a.updateTimestamp || a.createdTimestamp).valueOf() >
                timezoneMoment(b.updateTimestamp || b.createdTimestamp).valueOf()
                ? 1
                : -1
            })
          )
      } catch (e) {
        let message = e.message
        // look for a more descriptive message
        try {
          if (e.response.statusText) message = e.response.statusText
          if (e.response.error) message = e.response.error
        } catch (e) {}
        setResponse(message)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [props.id, sortOrder])
  const onSortOrder = value => {
    localStorage.setItem(localStorageSortOrderKey, value)
    setSortOrder(value)
  }
  // const onCancel = (noteId) => {
  //   if (!noteId) setNewNoteMode(false)
  // }
  const handleSubmit = async (noteId, fields) => {
    try {
      setResponse(null)
      const {data: note} = noteId
        ? await api.put(`/device/${props.id}/note/${noteId}`, {
            ...fields,
            deviceId: props.deviceId,
          })
        : await api.post(`/device/${props.id}/note`, {...fields, deviceId: props.deviceId})
      if (!noteId) {
        setNotes(sortOrder === 'oldest' ? [...notes, note] : [note, ...notes])
        setNewNoteMode(false)
      } else setNotes(notes.map(n => (n.id === note.id ? note : n)))
    } catch (e) {
      let message = e.message
      // look for a more descriptive message
      try {
        if (e.response.statusText) message = e.response.statusText
        if (e.response.error) message = e.response.error
      } catch (e) {}
      setResponse(message)
    }
  }
  const onDelete = async noteId => {
    try {
      const api = apiGet()
      await api.delete(`/device/${props.id}/note/${noteId}`)
      const newNotes = notes.filter(n => n.id !== noteId)
      setNotes(newNotes)
      setNewNoteMode(!newNotes.length)
      setResponse(null)
    } catch (e) {
      let message = e.message
      // look for a more descriptive message
      try {
        if (e.response.statusText) message = e.response.statusText
        if (e.response.error) message = e.response.error
      } catch (e) {}
      setResponse(message)
    }
  }
  if (isLoading) return <Busy />
  return (
    <div className={'system-notes'}>
      {response && (
        <Alert className={'alert'} variant={'danger'}>
          {response}
        </Alert>
      )}
      {notes.length !== 0 && (
        <div className={'button-row'}>
          <Button className={'sort-button'} onClick={() => onSortOrder('newest')}>
            {'Sort by: NEWEST - OLDEST'}
          </Button>
          <Button className={'sort-button'} onClick={() => onSortOrder('oldest')}>
            {'Sort by: OLDEST - NEWEST'}
          </Button>
          <Button className={'new-note'} onClick={() => setNewNoteMode(true)}>
            <FontAwesomeIcon icon={'plus-circle'} />
            {'ADD NOTE'}
          </Button>
        </div>
      )}
      <hr />
      <div className={'note-list'}>
        {newNoteMode && (
          <Note
            deviceId={props.id}
            editMode={true}
            handleSubmit={handleSubmit}
            noCancel={!notes.length}
            // onCancel={onCancel}
            setNewNoteMode={setNewNoteMode}
            setResponse={setResponse}
            users={props.users}
          />
        )}
        {notes.map(n => (
          <Note
            deviceId={props.id}
            handleSubmit={handleSubmit}
            key={n.id}
            {...n}
            setResponse={setResponse}
            // onCancel={onCancel}
            onDelete={onDelete}
            user={props.users[n.updatedBy || n.cognitoUsername]}
            users={props.users}
          />
        ))}
      </div>
    </div>
  )
}
