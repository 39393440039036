import React, {useState} from 'react'
import './UserProfile.scss'
import ProfilePicture from '../components/ProfilePicture'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ImageCropperModal from '../components/ImageCropperModal'
import {apiGet} from '../libs/apiLib'
import {initials} from '../libs/userLib'

export default function UserProfile(props) {
  const {user} = props
  const [showImageCropperModal, setShowImageCropperModal] = useState(false)
  const [resetKey, setResetKey] = useState(0)
  const onNewSystemPhoto = async base64Image => {
    const api = apiGet()
    await api.put(`/user/${user.cognitoUsername}`, {profilePicture: base64Image})
    setShowImageCropperModal(false)
    user.profilePicture = base64Image
    props.setUser({...user})
  }
  const onRemovePhoto = async () => {
    const api = apiGet()
    await api.put(`/user/${user.cognitoUsername}`, {profilePicture: null})
    setShowImageCropperModal(false)
    user.profilePicture = null
    props.setUser({...user})
  }
  return (
    <div className="user-profile-page">
      <div className="user-profile-page-header">
        <div className="header-title">My Profile</div>
      </div>
      <div className="user-profile-page-body">
        <ImageCropperModal
          classPrefix={'profile-picture'}
          handleSubmit={onNewSystemPhoto}
          handleRemove={onRemovePhoto}
          imageSource={user.profilePicture}
          onCancel={() => setShowImageCropperModal(false)}
          resetKey={resetKey}
          setShow={setShowImageCropperModal}
          show={showImageCropperModal}
          title={'Profile Picture'}
        />
        <div className={'profile-image-wrapper'}>
          <ProfilePicture initials={initials(user)} image={user.profilePicture} />
          <FontAwesomeIcon
            icon={'pencil-alt'}
            className={'pencil-alt'}
            onClick={() => {
              setShowImageCropperModal(true)
              setResetKey(resetKey + 1)
            }}
          />
        </div>
        <hr />
        <div className={'user-profile-body-header'}>Security</div>
        <hr />
      </div>
    </div>
  )
}
