import React, {useState} from 'react'
import Auth from '@aws-amplify/auth'
import {Alert, Form, FormControl, FormGroup, FormLabel} from 'react-bootstrap'
import LoaderButton from '../components/LoaderButton'
import {useFormFields} from '../libs/formLib'
import {validatePassword} from '../libs/validateLib'
import FormPage from '../components/FormPage'
import './NewPassword.scss'

export default function NewPassword() {
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [email, setEmail] = useState('')
  const [fields, handleFieldChange] = useFormFields(
    {
      email: '',
      confirmationCode: '',
      password: '',
      password2: '',
    },
    email ? validateConfirmationForm : null
  )
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  function validateForm() {
    return fields.email.length > 0
  }

  async function handleSubmit(event) {
    event.preventDefault()

    setIsLoading(true)

    try {
      await Auth.forgotPassword(fields.email)
      setErrors({})
      setEmail(fields.email)
    } catch (e) {
      setErrors({response: e.message})
    } finally {
      setIsLoading(false)
    }
  }

  function renderForm() {
    return (
      <Form className="password-reset form-page" onSubmit={handleSubmit}>
        <div className="title">PASSWORD RESET</div>
        <FormGroup controlId="email">
          <FormLabel>Email</FormLabel>
          <FormControl autoFocus type="email" value={fields.email} onChange={handleFieldChange} />
          {errors.email && <Alert variant={'danger'}>{errors.email}</Alert>}
        </FormGroup>
        <p>We will send a confirmation code to your account email for selecting a new password.</p>
        {errors.response && <Alert variant={'danger'}>{errors.response}</Alert>}
        <div className="button-row">
          <LoaderButton type="submit" isLoading={isLoading} disabled={!validateForm()}>
            {' '}
            Submit{' '}
          </LoaderButton>
        </div>
      </Form>
    )
  }

  /***************************************************
   * form in response to confirmation code request   *
   ***************************************************/

  function renderConfirmationForm() {
    return (
      <FormPage>
        <Form className="password-reset-confirmation form-page" onSubmit={handleSubmitConfirmation}>
          <div className="title">CONFIRMATION</div>
          <FormGroup controlId="confirmationCode">
            <FormLabel>Confirmation Code</FormLabel>
            <FormControl
              autoComplete={'off'}
              onChange={handleFieldChange}
              value={fields.confirmationCode}
              placeholder="Please check email for code"
            />
          </FormGroup>
          <FormGroup controlId="password">
            <FormLabel>Password</FormLabel>
            <FormControl
              type="password"
              value={fields.password}
              onChange={handleFieldChange}
              autoComplete={'new-password'}
            />
            {errors.password && <Alert variant={'danger'}>{errors.password}</Alert>}
          </FormGroup>
          <FormGroup controlId="password2">
            <FormLabel>Confirm Password</FormLabel>
            <FormControl
              type="password"
              value={fields.password2}
              onChange={handleFieldChange}
              autoComplete={'new-password'}
            />
            {errors.password2 && <Alert variant={'danger'}>{errors.password2}</Alert>}
          </FormGroup>
          {errors.response && <Alert variant={'danger'}>{errors.response}</Alert>}
          <div className="button-row">
            <LoaderButton type="submit" isLoading={isLoading} disabled={Object.keys(errors).length > 0}>
              {' '}
              Submit{' '}
            </LoaderButton>
          </div>
        </Form>
      </FormPage>
    )
  }

  function validateConfirmationForm(fields) {
    const {confirmationCode, password, password2} = fields
    const errors = {}
    if (!confirmationCode.length) errors.confirmationCode = 'Required, check email'
    if (!Object.keys(fields).length) return setErrors(errors)
    validatePassword(password, errors)
    if (!errors.password && password2 && password2 !== password) errors.password2 = "Passwords don't match"
    setErrors(errors)
  }

  async function handleSubmitConfirmation(event) {
    event.preventDefault()

    setIsLoading(true)

    try {
      await Auth.forgotPasswordSubmit(fields.email, fields.confirmationCode, fields.password)
      setErrors({})
      setPasswordChanged(true)
    } catch (e) {
      setErrors({response: e.message})
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormPage>
      {!email && renderForm()}
      {email && !passwordChanged && renderConfirmationForm()}
      {email && passwordChanged && (
        <Form className="password-reset-redirect form-page">
          <Alert>
            Your password has been changed. You may now use it to{' '}
            <Alert.Link href="/login">login.</Alert.Link>
          </Alert>
        </Form>
      )}
    </FormPage>
  )
}
