import React from 'react'
import './Busy.scss'

export default ({height = '200px', width = '100%', clazz = 'busy-svg'}) => (
  <div className="busy">
    <svg
      version="1.1"
      id="Layer_1"
      className={clazz}
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 100 100"
    >
      <rect x="13" y="10" rx="1" width="4" height="40" fill="#005FA5" opacity="1">
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="1; .2; 1"
          begin="0s"
          dur="2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          values="40; 30; 40"
          begin="0s"
          dur=".6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          values="10; 15; 10"
          begin="0s"
          dur=".6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="29" y="15" rx="1" width="4" height="30" fill="#005FA5" opacity="1">
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="1; .2; 1"
          begin="0s"
          dur="2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          values="30; 40; 30"
          begin="0s"
          dur=".6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          values="15; 10; 15"
          begin="0s"
          dur=".6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="45" y="10" rx="1" width="4" height="40" fill="#005FA5" opacity="1">
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="1; .2; 1"
          begin="0s"
          dur="2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          values="40; 30; 40"
          begin="0s"
          dur=".6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          values="10; 15; 10"
          begin="0s"
          dur=".6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="61" y="15" rx="1" width="4" height="30" fill="#005FA5" opacity="1">
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="1; .2; 1"
          begin="0s"
          dur="2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          values="30; 40; 30"
          begin="0s"
          dur=".6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          values="15; 10; 15"
          begin="0s"
          dur=".6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="77" y="10" rx="1" width="4" height="40" fill="#005FA5" opacity="1">
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="1; .2; 1"
          begin="0s"
          dur="2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          values="40; 30; 40"
          begin="0s"
          dur=".6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          values="10; 15; 10"
          begin="0s"
          dur=".6s"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
  </div>
)
