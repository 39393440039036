import React from 'react'

export default ({className = 'its-a-bird-its-a-plane-its'}) => {
  const id = String(Math.random())
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
        <defs>
          <clipPath id={id}>
            <rect y="12.07" width="150" height="125.86" fill="none" />
          </clipPath>
        </defs>
        <g clipPath={`url(#${id})`}>
          <g>
            <path
              d="M103.24,12.25c20.24,5.84,14.38,33.92-6.38,30.32-19-5.12-12.89-33,6.38-30.32"
              fill="currentColor"
            />
            <path
              d="M90.26,84.92c4.4-.25,8.73.07,10.55,4.5a6.92,6.92,0,0,1-1.25,8.12Q86.7,110.23,73.63,122.66a7.29,7.29,0,0,1-10.29-.51l-.06-.07c-7-8.34,5.33-14.56,10-20.36-3.45.52-6.84-.74-9.11,2q-12.68,15-25.28,30.11a9.27,9.27,0,0,1-5.91,4,7.63,7.63,0,0,1-6.91-12.53c11-13.21,22.14-26.24,33.08-39.46A4.15,4.15,0,0,0,60,83.22c-.37-7.51-.85-15-1.29-22.5-.27-4.61,1.23-6.59,5.76-7.59,13.38-2.9,26.6-6.94,40.45-6.17A5.62,5.62,0,0,0,109,45.34c10-9.35,20.15-18.63,30.21-28A6.29,6.29,0,1,1,148.35,26a6,6,0,0,1-.89.78c-19.84,18.8-41.37,35.57-57.2,58.16M71.88,70.71l6.62-7.92-7,1.64c.14,2,.26,3.77.43,6.27"
              fill="currentColor"
            />
            <path
              d="M15.86,99.68,42.2,98.44c-1.91,1.81-3.63,6-7.06,6.12A113.69,113.69,0,0,1,0,102.31C32.12,87.09,41.3,37.54,81.48,38.54a2.76,2.76,0,0,1,3.21,2.22,3.52,3.52,0,0,1,0,.56c.85,5.76-18.58-1.46-32.91,15.43C39.16,70,29.84,86.15,16,98.27c-.46.36-.3.92-.16,1.41"
              fill="currentColor"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
