import {useState} from 'react'

/**
 *
 * @param initialState — object mapping for fields in form. object's keys are field names, object values
 * are interpreted as field value if typeof value is not an object with an object property called
 * 'formFieldOptions'.
 *
 * If value is an object and the value object has a 'formFieldOptions property, then the field's value is
 * object.value and the fields options are object.formFieldOptions.
 *
 * The only option supported now is 'noTrim', which prevents the fields values from being
 * trimmed of leading and trailing whitespace.
 * @param validateForm
 * @returns {({}|(function(*): (undefined|*))|*)[]}
 */
export function useFormFields(initialState, validateForm) {
  const [fields, setValues] = useState(
    Object.keys(initialState).reduce((acc, k) => {
      const field = initialState[k]
      acc[k] = field?.formFieldOptions && typeof field.formFieldOptions === 'object' ? field.value : field
      return acc
    }, {})
  )
  const [options] = useState(
    Object.keys(initialState).reduce((acc, k) => {
      const field = initialState[k]
      acc[k] = field && field.formFieldOptions ? field.formFieldOptions : field
      return acc
    }, {})
  )
  const [initialFields] = useState(fields)

  function handleFieldChange(event) {
    const {
      target: {id, type, value},
    } = event
    if (type === 'reset') {
      setValues(initialFields)
      return
    }
    if (type === 'react-select') {
      const newValues = {...fields, [id]: value}
      setValues(newValues)
      return validateForm && validateForm(newValues)
    }

    /*
     * it's difficult to read the checked state of react bootstrap checkboxes, so...
     * when a checkbox is checked, set its className to 'is-checked' so we can get its value.
     * */
    const isChecked =
      document.getElementById(id).parentElement.getAttribute('class').indexOf('is-checked') === -1
    const newValues = {
      ...fields,
      [id]:
        (type === 'checkbox' && isChecked) ||
        (options[id] && options[id].noTrim && value) ||
        (type !== 'checkbox' && value.trim()),
    }
    setValues(newValues)
    validateForm && validateForm(newValues)
  }

  function handleReactSelectFieldChange(id, value) {
    handleFieldChange({
      target: {id, value, type: 'react-select'},
    })
  }

  return [fields, handleFieldChange, handleReactSelectFieldChange]
}
