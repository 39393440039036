import React from 'react'

export default ({className = 'exclamation-point'}) => {
  const id = String(Math.random())
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
        <defs>
          <linearGradient
            id={id}
            x1="-68.16"
            y1="377.57"
            x2="-70.91"
            y2="374.68"
            gradientTransform="matrix(31, 0, 0, -31, 2229, 11736.67)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#f6d55f" />
            <stop offset="1" stopColor="#deb735" />
          </linearGradient>
        </defs>
        <g>
          <rect
            id="Rectangle_2598-2"
            x="20"
            y="20"
            width="110"
            height="110"
            rx="35.48"
            fill={`url(#${id})`}
          />
          <path
            d="M89.08,106A14.08,14.08,0,1,1,75,91.89h0A14.1,14.1,0,0,1,89.08,106ZM62.55,34.39l2.39,47.86a4.23,4.23,0,0,0,4.22,4H80.83a4.23,4.23,0,0,0,4.22-4l2.39-47.86a4.22,4.22,0,0,0-4-4.43H66.77a4.23,4.23,0,0,0-4.23,4.22C62.54,34.25,62.55,34.32,62.55,34.39Z"
            fill="#fff"
          />
        </g>
      </svg>
    </div>
  )
}
