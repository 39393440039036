import React, {useMemo, useState} from 'react'
import {Button} from 'react-bootstrap'
import {apiGet} from '../libs/apiLib'
import Table from '../components/Table'
import NewUserModal from '../components/NewUserModal'
import NotificationsTable from '../components/NotificationsTable'
import {BooleanCell, EmailCell, PhoneCell, UserJoinCell} from '../components/TableCells'
import moment from 'moment'
import {getFunctionName} from '../libs/utilLib'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import './Users.scss'
import AnchorButton from '../components/AnchorButton'
import ProfilePicture from '../components/ProfilePicture'
import {initials, isAdmin} from '../libs/userLib'
import AutoSuggest from 'react-autosuggest'

const api = apiGet()

export const CognitoStatusCell = props => {
  const {
    onUpdate,
    disabled,
    TEMPORARY_PASSWORD_VALIDITY_DAYS,
    row: {
      index,
      original: {inviteSent, cognitoUsername},
    },
  } = props
  const onClick = async () => {
    await api.put(`/user/${cognitoUsername}`, {invite: true})
    onUpdate(moment().format(), index, 'inviteSent')

    /* don't follow the link */
    return false
  }
  let text, linkText
  if (props.value === 'FORCE_CHANGE_PASSWORD') {
    if (!inviteSent) {
      text = 'New user'
      linkText = 'Activate'
    } else if (moment(inviteSent) < moment().subtract(TEMPORARY_PASSWORD_VALIDITY_DAYS, 'days')) {
      text = `Invite expired ${moment(inviteSent).add(TEMPORARY_PASSWORD_VALIDITY_DAYS, 'days').fromNow()}`
      linkText = 'Resend'
    } else text = `Invited ${moment(inviteSent).fromNow()}`
  } else text = 'Active'
  return (
    <div className="cognito-status">
      {linkText ? (
        <div className="is-link">
          {text && (
            <div className="text">
              {text}
              <hr />
            </div>
          )}
          <div className="link">
            <AnchorButton disabled={disabled} onClick={onClick} className="cognito-status">
              {linkText}
            </AnchorButton>
          </div>
        </div>
      ) : (
        <div className="no-link">{text}</div>
      )}
    </div>
  )
}

export default function Users(props) {
  const {
    location: {pathname},
    TEMPORARY_PASSWORD_VALIDITY_DAYS,
    user,
  } = props
  const [isAnAdmin] = useState(isAdmin(user.accounts))
  const isSuperuser = props.user.isSuperuser
  const accountCount = Object.values(user.accounts).filter(a => a.isAdmin).length
  const cognitoUsername = pathname.split('/').length ? pathname.split('/')[2] : null
  // if superuser, use allUsers
  const [data, setData] = useState(
    Object.values(cognitoUsername ? [user.allUsers[cognitoUsername]] : user.allUsers).sort((a, b) =>
      a.email > b.email ? 1 : -1
    )
  )
  const [modalShow, setModalShow] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const columns = useMemo(
    () =>
      [
        {
          accessor: 'profilePicture',
          Cell: props => {
            const {devices, isSuperuser, accounts} = props.row.values
            return (
              <ProfilePicture
                image={props.value}
                isSuperuser={isSuperuser}
                isAdmin={isAdmin(accounts, devices)}
                initials={initials(props.row.values)}
                alt={'profile'}
              />
            )
          },
          default: true,
        },
        {accessor: 'email', Header: 'Email Address', Cell: EmailCell, default: true},
        {
          accessor: 'accounts',
          Header: 'Accounts',
          Cell: props => <UserJoinCell {...props} joinType="account" />,
          default: true,
        },
        {
          accessor: 'devices',
          Header: 'Systems',
          Cell: props => <UserJoinCell {...props} joinType="device" route="system" />,
          default: true,
        },
        {accessor: 'family_name', Header: 'Last Name', default: true},
        {accessor: 'given_name', Header: 'First Name', default: true},
        {accessor: 'isSuperuser', Header: 'Superuser', Cell: BooleanCell, default: true, so: true},
        {
          accessor: 'phone_number',
          Header: 'Phone Number',
          Cell: props => <PhoneCell {...props} disabled={true} />,
          default: true,
        },
        {
          accessor: 'status',
          Header: 'Status',
          Cell: props => <CognitoStatusCell disabled={!isAnAdmin && !isSuperuser} {...props} />,
          default: true,
        },
        {accessor: 'enabled', Header: 'Enabled', Cell: BooleanCell, default: true},
        {accessor: 'salesforceId', Header: 'Salesforce Id', so: true},
        {accessor: 'cognitoUsername', Header: 'Cognito Id', so: true},
      ].filter(column => {
        const columnFilter = column => {
          const {so /* superuser only */, ao /* admin only */} = column || {}
          return (
            user.isSuperuser ||
            (!so && (isAnAdmin || !ao)) ||
            (column.accessor === 'accounts' && accountCount !== 1)
          )
        }
        if (column.accessor) return columnFilter(column)
        else {
          column.columns = column.columns.filter(columnFilter)
          return true
        }
      }),
    [user, isAnAdmin, accountCount, isSuperuser]
  )

  const onUpdate = async (value, index, id) => {
    data[index][id] = value
    setData([...data])
    const {cognitoUsername} = data[index]
    await api.put(`/user/${cognitoUsername}`, {[id]: value})
  }

  const onSuggestionsFetchRequested = async ({value}) => {
    if (value.length > 1) {
      const api = apiGet()
      const result = await api.get(`/user?search=${value}`)
      setSuggestions(result.data)
    }
  }

  const onSuggestionsClearRequested = async () => {
    setSuggestions([])
  }

  const onSearchTermChange = (event, {newValue}) => {
    setSearchTerm(newValue)
  }

  return (
    <div className="users-page">
      <NewUserModal {...{setModalShow, user, show: modalShow}} />
      <div className={'users-page-banner'}>
        <div className="header-title">Users</div>
      </div>
      <AutoSuggest
        {...{
          inputProps: {
            onChange: onSearchTermChange,
            type: 'search',
            value: searchTerm,
          },
          getSuggestionValue: value => value,
          onSuggestionsClearRequested,
          onSuggestionsFetchRequested,
          renderSuggestion: user => <span>{user.email}</span>,
          suggestions,
        }}
      />
      <Table
        {...{
          cellInjections: {TEMPORARY_PASSWORD_VALIDITY_DAYS, onUpdate, user, api},
          columns,
          onUpdate,
          storageKey: getFunctionName(),
          data: data,
          bannerItems:
            data.length !== 1
              ? [
                  <>
                    <Button className="add" onClick={() => setModalShow(true)}>
                      <FontAwesomeIcon icon="user-plus" />
                    </Button>
                  </>,
                ]
              : [],
        }}
      />
      {data.length === 1 && user.cognitoUsername === data[0].cognitoUsername && (
        <NotificationsTable cognitoUsername={data[0].cognitoUsername} user={user} />
      )}
    </div>
  )
}
