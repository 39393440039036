import React, {useState} from 'react'
import {Button, Dropdown} from 'react-bootstrap'
import './TableColumnChooser.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default function TableColumnChooser({allColumns, hiddenColumns, localStorageKey, setHiddenColumns}) {
  let hasDefaultColumns = false
  allColumns.forEach(c => (hasDefaultColumns = hasDefaultColumns || c.default))
  const [open, setOpen] = useState(false)
  const handleClick = id => onClick && onClick(id)
  const handleToggle = (isOpen, event, {source}) => {
    setOpen(!(source === undefined || source === 'rootClose'))
  }

  const onClick = key => {
    if (hiddenColumns.includes(key)) {
      const newSelections = hiddenColumns.filter(item => item !== key)
      localStorage.setItem(localStorageKey, JSON.stringify(newSelections))
      setHiddenColumns(newSelections)
    } else {
      hiddenColumns.push(key)
      localStorage.setItem(localStorageKey, JSON.stringify(hiddenColumns))
      setHiddenColumns([...hiddenColumns])
    }
  }
  const selectAll = () => {
    const newSelections = []
    localStorage.setItem(localStorageKey, JSON.stringify(newSelections))
    setHiddenColumns(newSelections)
  }

  const selectDefaults = () => {
    const defaults = allColumns.filter(column => !column.default).map(({id}) => id)
    localStorage.setItem(localStorageKey, JSON.stringify(defaults))
    setHiddenColumns(defaults)
  }
  const clearAll = () => {
    const allHidden = allColumns.map(c => c.id)
    localStorage.setItem(localStorageKey, JSON.stringify(allHidden))
    setHiddenColumns(allHidden)
  }

  return (
    <Dropdown show={open} onToggle={handleToggle}>
      <Dropdown.Toggle>
        <div className="cog-wrapper">
          <FontAwesomeIcon icon="cog" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight className="table-column-chooser">
        <div className="group-choosers">
          {hasDefaultColumns && (
            <Button size="sm" onClick={() => selectDefaults && selectDefaults()} variant="outline-primary">
              Select Defaults
            </Button>
          )}
          <Button size="sm" onClick={() => selectAll && selectAll()} variant="outline-primary">
            Select All
          </Button>
          <Button size="sm" onClick={() => clearAll && clearAll()} variant="outline-primary">
            Clear All
          </Button>
        </div>
        <Dropdown.Divider />
        <div className="selectors">
          {allColumns
            .sort(({Header: header1}, {Header: header2}) => (header1 > header2 ? 1 : -1))
            .map(({id, Header}) => (
              <Dropdown.Item id={id} onClick={() => handleClick(id)} key={Math.random()}>
                <Button
                  size="sm"
                  variant="outline-primary"
                  className={`checkbox ${hiddenColumns.includes(id) ? 'hidden' : ''}`}
                  value={id}
                >
                  ✓
                </Button>
                <div className="column-name">{Header}</div>
              </Dropdown.Item>
            ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
