import React from 'react'
import './NotFound.scss'

export default function NotFound() {
  return (
    <div className="container not-found">
      <h3 className="jumbotron">
        <span role="img" aria-label="rainy day">
          🌦️
        </span>
        Page Not Found
        <span role="img" aria-label="rainy day">
          🌦️
        </span>
      </h3>
    </div>
  )
}
