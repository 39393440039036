import _axios from 'axios'
import moment from 'moment'

let Auth
let lastRefreshTime = 0

/**
 * periodic refresh of cognito tokens, to assure that they are never stale
 * @returns {Promise<void>}
 */
async function refresh() {
  const currentTime = moment().valueOf()
  if (currentTime - lastRefreshTime > 5 * 60 * 1000) {
    try {
      const currentSession = await Auth.currentSession()
      apiSetAuthHeader(currentSession.idToken.jwtToken)
    } catch (e) {
      // login required...
      window.location.reload()
    }
  }
}

export const api = {
  get: async function (url, config) {
    await refresh()
    return _axios.get(url, config)
  },
  post: async function (url, data, config) {
    await refresh()
    return await _axios.post(url, data, config)
  },
  put: async function (url, data, config) {
    await refresh()
    return await _axios.put(url, data, config)
  },
  delete: async function (url, config) {
    await refresh()
    await _axios.delete(url, config)
  },
}
export const apiGet = () => api

export function apiInit(baseUrl, _Auth, headers = {}) {
  Auth = _Auth
  _axios.defaults.headers.common['Accept'] = 'application/json'
  _axios.defaults.headers.common['Content-Type'] = 'application/json'
  Object.assign(_axios.defaults.headers.common, headers)
  _axios.defaults.baseURL = baseUrl
  _axios.defaults.timeout = 20000
}

export function apiSetAuthHeader(value) {
  lastRefreshTime = moment().valueOf()
  _axios.defaults.headers.common['Auth'] = value
}
