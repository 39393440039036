import isEmail from 'isemail'
import PasswordValidator from 'password-validator'

const passwordErrorText = {
  min: 'At least 8 characters',
  uppercase: 'At least 1 uppercase letter required',
  lowercase: 'At least 1 lowercase letter required',
  symbols: 'At least 1 symbol required',
  digits: 'At least 1 digit required',
  max: 'No more than 100 characters',
}

const passwordSchema = new PasswordValidator()
  .is()
  .min(8)
  .is()
  .max(100)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .symbols()
  .has()
  .digits()
  .has()
  .not()
  .spaces()

export function validatePassword(password, errors = {}) {
  if (!password) {
    errors.password = 'Required'
  } else {
    const results = passwordSchema.validate(password, {list: true})
    /* only return 0th error */
    if (results.length) errors.password = passwordErrorText[results[0]] || 'invalid'
  }
  return errors
}

export function validateEmail(email, errors = {}) {
  if (!email) return (errors.email = 'Required')
  if (email && !isEmail.validate(email)) errors.email = 'invalid email address'
  return errors
}

export function validatePhoneNumber(phoneNumber, errors = {}) {
  const pattern = [
    /.*/,
    /^[1-9]$/,
    /^[1-9][0-9]$/,
    /^[1-9][0-9]{2}$/,
    /^[1-9][0-9]{2}-$/,
    /^[1-9][0-9]{2}-[1-9]$/,
    /^[1-9][0-9]{2}-[1-9][0-9]$/,
    /^[1-9][0-9]{2}-[1-9][0-9]{2}$/,
    /^[1-9][0-9]{2}-[1-9][0-9]{2}-$/,
    /^[1-9][0-9]{2}-[1-9][0-9]{2}-[0-9]$/,
    /^[1-9][0-9]{2}-[1-9][0-9]{2}-[0-9]{2}$/,
    /^[1-9][0-9]{2}-[1-9][0-9]{2}-[0-9]{3}$/,
    /^[1-9][0-9]{2}-[1-9][0-9]{2}-[0-9]{4}$/,
  ]
  if (!phoneNumber.match(pattern[phoneNumber.length])) errors.phoneNumber = 'Bad format'
  else if (phoneNumber.length && phoneNumber.length < 12) errors.phoneNumber = 'Not enough digits'
  return errors
}
export const formatPhoneNumber = phoneNumber => {
  if (!phoneNumber) return ''
  const digits = phoneNumber.replace(/^\+1|[^0-9]/g, '').split('')
  if (!digits.length) return ''
  let result = ['(']
  for (let i = 0; i < digits.length; i++) {
    result.push(digits[i])
    if (i === 2) result.push(') ')
    if (i === 5) result.push('-')
  }
  return result.join('')
}
