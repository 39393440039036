import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import clipboardCopy from 'clipboard-copy'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import MetricsGraph from '../components/MetricsGraph'
import {getQueryObject} from '../libs/queryLib'
import './Metrics.scss'
import moment from 'moment-timezone'
import OverlayButton from '../components/OverlayButton'
import MetricsDownloadModal from '../components/MetricsDownloadModal'

function getMillis(value) {
  if (!value) return null
  if (typeof value === 'string' && value.match(/^[0-9]+$/)) return parseInt(value)
  try {
    return moment(value).valueOf()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`${value}: ${e.message}`)
    return moment().subtract(1, 'week').valueOf()
  }
}

const interval = {
  day: {count: 1, duration: 'day', label: 'Day'},
  week: {count: 1, duration: 'week', label: 'Week'},
  month: {count: 1, duration: 'month', label: 'Month'},
  quarter: {count: 3, duration: 'months', label: 'Three Months'},
  year: {count: 1, duration: 'year', label: 'Year'},
}

function GraphInterval({value, onIntervalChange, onBackButton, onForwardButton, onNowButton}) {
  const handleChange = event => {
    const val = event.target.value
    if (val !== value) onIntervalChange(val)
  }

  return (
    <div className="graph-interval shadow">
      <Button className="back-button" size="sm" onClick={onBackButton}>
        {'<'}
      </Button>
      {Object.keys(interval).map(key => {
        const {label} = interval[key]
        return (
          <Button
            value={key}
            key={key}
            className={value === key ? 'selected' : ''}
            onClick={handleChange}
            size="sm"
          >
            {label}
          </Button>
        )
      })}
      <Button className="forward-button" size="sm" onClick={onForwardButton}>
        {'>'}
      </Button>
      <Button className="now-button" size="sm" onClick={onNowButton}>
        {'>|'}
      </Button>
    </div>
  )
}

function AttributeChooser({attributes, onChange}) {
  const handleChange = event => {
    onChange(event.target.value)
  }

  return (
    <div className="attribute-chooser shadow">
      <Button
        className={attributes['ambientTemp'] ? 'selected' : ''}
        value={'ambientTemp'}
        onClick={handleChange}
        size="sm"
      >
        Ambient Temperature
      </Button>
      <Button
        className={attributes['arrayCurrent'] ? 'selected' : ''}
        value={'arrayCurrent'}
        onClick={handleChange}
        size="sm"
      >
        Array Current
      </Button>
      <Button
        className={attributes['arrayVoltage'] ? 'selected' : ''}
        value={'arrayVoltage'}
        onClick={handleChange}
        size="sm"
      >
        Array Voltage
      </Button>
      <Button
        className={attributes['batteryCurrent'] ? 'selected' : ''}
        value={'batteryCurrent'}
        onClick={handleChange}
        size="sm"
      >
        Battery Current
      </Button>
      <Button
        className={attributes['batteryVoltage'] ? 'selected' : ''}
        value={'batteryVoltage'}
        onClick={handleChange}
        size="sm"
      >
        Battery Voltage
      </Button>
      <Button
        className={attributes['loadCurrent'] ? 'selected' : ''}
        value={'loadCurrent'}
        onClick={handleChange}
        size="sm"
      >
        Load Current
      </Button>
      <Button
        className={attributes['loadVoltage'] ? 'selected' : ''}
        value={'loadVoltage'}
        onClick={handleChange}
        size="sm"
      >
        Load Voltage
      </Button>
    </div>
  )
}

export default function Metrics(props) {
  const query = getQueryObject()

  const localSpan = localStorage.getItem('span') || 'day'
  const [modalShow, setModalShow] = useState(false)
  const [deviceId] = useState(props.id || localStorage.getItem('deviceId'))
  const [attributes, setAttributes] = useState(
    (query.attributes &&
      query.attributes.split(',').reduce((o, a) => {
        o[a] = true
        return o
      }, {})) ||
      (localStorage.getItem('attributes') &&
        localStorage
          .getItem('attributes')
          .split(',')
          .reduce((o, a) => {
            o[a] = true
            return o
          }, {})) || {batteryVoltage: true}
  )
  const [attributeString, setAttributeString] = useState(
    Object.keys(attributes)
      .filter(k => attributes[k])
      .join(',')
  )
  const [startMillis, setStartMillis] = useState(
    getMillis(query.start) || moment().subtract(1, localSpan).valueOf()
  )
  const [span, setSpan] = useState(query.span || localSpan)
  const [endMillis, setEndMillis] = useState(getMillis(query.end) || moment().valueOf())

  function onIntervalChange(newSpan) {
    if (newSpan !== span) {
      const {count, duration} = interval[newSpan]
      setStartMillis(
        moment(endMillis || moment().valueOf())
          .subtract(count, duration)
          .valueOf()
      )
      setSpan(newSpan)
    }
  }

  function onBackButton() {
    const {count, duration} = interval[span]
    setEndMillis(
      moment(endMillis || moment().valueOf())
        .subtract(count, duration)
        .valueOf()
    )
    setStartMillis(moment(endMillis).subtract(count, duration).valueOf())
  }

  function onForwardButton() {
    const {count, duration} = interval[span]
    setEndMillis(
      Math.min(
        moment(endMillis || moment().valueOf())
          .add(count, duration)
          .valueOf(),
        moment().valueOf()
      )
    )
    setStartMillis(moment(endMillis).subtract(count, duration).valueOf())
  }

  function onNowButton() {
    const {count, duration} = interval[span]
    setEndMillis(undefined)
    setStartMillis(moment(endMillis).subtract(count, duration).valueOf())
  }

  function updateAttributes(attribute) {
    // maintain at least one attribute selection at all times
    if (Object.keys(attributes).filter(k => attributes[k]).length === 1 && attributes[attribute]) return
    const newAttributes = {...attributes, [attribute]: !attributes[attribute]}
    setAttributes(newAttributes)
    setAttributeString(
      Object.keys(newAttributes)
        .filter(k => newAttributes[k])
        .join(',')
    )
  }

  useEffect(() => {
    const attributeString = Object.keys(attributes)
      .filter(k => attributes[k])
      .join(',')
    const location = `/system/${deviceId}/metrics?&attributes=${attributeString}&span=${span}`
    window.history.replaceState(null, null, location)
    localStorage.setItem('deviceId', deviceId)
    localStorage.setItem('span', span)
    localStorage.setItem('attributes', attributeString)
  }, [attributes, span, deviceId])

  async function handlePermalinkClick() {
    const {host, protocol} = window.location
    const end = endMillis ? `&end=${moment(endMillis).format()}` : ''
    const location = `/system/${deviceId}/metrics?start=${moment(
      startMillis
    ).format()}${end}&attributes=${attributeString}&span=${span}`
    await clipboardCopy([protocol, '//', host, location].join(''))
  }

  return (
    <div className="metrics">
      <AttributeChooser attributes={attributes} onChange={updateAttributes} />
      <GraphInterval
        value={span}
        onIntervalChange={onIntervalChange}
        onBackButton={onBackButton}
        onForwardButton={onForwardButton}
        onNowButton={onNowButton}
      />
      <MetricsDownloadModal {...{setModalShow, user: props.user, show: modalShow, joinType: 'device'}} />
      {deviceId && attributes && startMillis && endMillis && (
        <MetricsGraph
          {...{
            deviceId,
            attributes: attributeString,
            startMillis,
            endMillis,
          }}
        >
          <OverlayButton
            popoverId="metrics-clipboard-message"
            message="Copied to clipboard"
            className="clipboard-copy"
            onClick={handlePermalinkClick}
            title="Copy link to clipboard"
            size="sm"
          >
            <FontAwesomeIcon className="icon link" icon="link" />
          </OverlayButton>
          <OverlayButton
            popoverId="metrics-download-message"
            message="Download CSV Data"
            className="download"
            onClick={() => setModalShow(true)}
            title="Download CSV Data"
            size="sm"
          >
            <FontAwesomeIcon className="icon download" icon="download" />
          </OverlayButton>
        </MetricsGraph>
      )}
    </div>
  )
}
