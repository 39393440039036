import React from 'react'

export default ({className = 'thermometer', error = false}) => {
  const id = String(Math.random())
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
        <defs>
          <linearGradient
            id={id}
            x1="-381.42"
            y1="544.71"
            x2="-378.07"
            y2="547.25"
            gradientTransform="matrix(14, 0, 0, -28, 5396.1, 15357.33)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#345b82" />
            <stop offset="1" stopColor="#4b81a1" />
          </linearGradient>
        </defs>
        <g>
          <path
            d="M88.75,102.5A13.75,13.75,0,1,1,68.13,90.59V68.12a6.88,6.88,0,0,1,13.75,0V90.59A13.77,13.77,0,0,1,88.75,102.5Zm6.88-18.19A27.51,27.51,0,0,1,75,130h-.2A27.5,27.5,0,0,1,54.38,84.31V40.62a20.63,20.63,0,0,1,41.25,0ZM92.19,102.5a20,20,0,0,0-6.88-14.29V40.62a10.31,10.31,0,0,0-20.62,0V88.21a20,20,0,0,0-6.88,14.2A17.27,17.27,0,0,0,74.87,119.7H75A17.22,17.22,0,0,0,92.19,102.5Z"
            fill={error ? 'white' : `url(#${id})`}
          />
        </g>
      </svg>
    </div>
  )
}
