import React from 'react'
import './SeveritySelector.scss'
import Bell from './Bell'
import ExclamationPoint from './ExclamationPoint'
import CheckMark from './CheckMark'
import ReactSelect from 'react-select'
import {eventSeverityLevels} from '../libs/eventsLib'
import InfoIcon from './InfoIcon'
export default function SeveritySelector(props) {
  const Option = ({isFocused, ...props}) => {
    const {value} = props.data
    return (
      <div ref={props.innerRef} {...props.innerProps} className={`option ${isFocused ? 'is-focused' : ''}`}>
        {value === '' && <div className="choice">Select...</div>}
        {value === 'alarm' && (
          <div className="choice" title={'Alarm'}>
            <Bell />
            Alarm
          </div>
        )}
        {value === 'warning' && (
          <div className="choice" title={'Warning'}>
            <ExclamationPoint />
            Warning
          </div>
        )}
        {value === 'recovery' && (
          <div className="choice" title={'Recovery'}>
            <CheckMark />
            Recovery
          </div>
        )}
        {value === 'info' && (
          <div className="choice" title={'Info'}>
            <InfoIcon />
            Info
          </div>
        )}
      </div>
    )
  }

  function onChange(option, changeType) {
    if (changeType.action === 'select-option') props.onChange && props.onChange(option.value)
  }

  return (
    <ReactSelect
      classNamePrefix="severity-selector"
      className="severity-selector"
      options={Object.values(eventSeverityLevels)}
      components={{
        Option: Option,
        SingleValue: Option,
      }}
      onChange={onChange}
      value={props.value}
    />
  )
}
