import React from 'react'
import './WeatherStrip.scss'

const weatherMap = {
  200: {desc: 'thunderstorm with light rain', icon: ['11d']},
  201: {desc: 'thunderstorm with rain', icon: ['11d']},
  202: {desc: 'thunderstorm with heavy rain', icon: ['11d']},
  210: {desc: 'light thunderstorm', icon: ['11d']},
  211: {desc: 'thunderstorm', icon: ['11d']},
  212: {desc: 'heavy thunderstorm', icon: ['11d']},
  221: {desc: 'ragged thunderstorm', icon: ['11d']},
  230: {desc: 'thunderstorm with light drizzle', icon: ['11d']},
  231: {desc: 'thunderstorm with drizzle', icon: ['11d']},
  232: {desc: 'thunderstorm with heavy drizzle', icon: ['11d']},
  300: {desc: 'light intensity drizzle', icon: ['09d']},
  301: {desc: 'drizzle', icon: ['09d']},
  302: {desc: 'heavy intensity drizzle', icon: ['09d']},
  310: {desc: 'light intensity drizzle rain', icon: ['09d']},
  311: {desc: 'drizzle rain', icon: ['09d']},
  312: {desc: 'heavy intensity drizzle rain', icon: ['09d']},
  313: {desc: 'shower rain and drizzle', icon: ['09d']},
  314: {desc: 'heavy shower rain and drizzle', icon: ['09d']},
  321: {desc: 'shower drizzle', icon: ['09d']},
  500: {desc: 'light rain', icon: ['10d']},
  501: {desc: 'moderate rain', icon: ['10d']},
  502: {desc: 'heavy intensity rain', icon: ['10d']},
  503: {desc: 'very heavy rain', icon: ['10d']},
  504: {desc: 'extreme rain', icon: ['10d']},
  511: {desc: 'freezing rain', icon: ['13d']},
  520: {desc: 'light intensity shower rain', icon: ['09d']},
  521: {desc: 'shower rain', icon: ['09d']},
  522: {desc: 'heavy intensity shower rain', icon: ['09d']},
  531: {desc: 'ragged shower rain', icon: ['09d']},
  600: {desc: 'light snow', icon: ['13d']},
  601: {desc: 'Snow', icon: ['13d']},
  602: {desc: 'Heavy snow', icon: ['13d']},
  611: {desc: 'Sleet', icon: ['13d']},
  612: {desc: 'Light shower sleet', icon: ['13d']},
  613: {desc: 'Shower sleet', icon: ['13d']},
  615: {desc: 'Light rain and snow', icon: ['13d']},
  616: {desc: 'Rain and snow', icon: ['13d']},
  620: {desc: 'Light shower snow', icon: ['13d']},
  621: {desc: 'Shower snow', icon: ['13d']},
  622: {desc: 'Heavy shower snow', icon: ['13d']},
  701: {desc: 'mist', icon: ['50d']},
  711: {desc: 'Smoke', icon: ['50d']},
  721: {desc: 'Haze', icon: ['50d']},
  731: {desc: 'sand/ dust whirls', icon: ['50d']},
  741: {desc: 'fog', icon: ['50d']},
  751: {desc: 'sand', icon: ['50d']},
  761: {desc: 'dust', icon: ['50d']},
  762: {desc: 'volcanic ash', icon: ['50d']},
  771: {desc: 'squalls', icon: ['50d']},
  781: {desc: 'tornado', icon: ['50d']},
  800: {desc: 'clear sky', icon: ['01d', 'e01n']},
  801: {desc: 'few clouds: 11-25%', icon: ['02d', '02n']},
  802: {desc: 'scattered clouds: 25-50%', icon: ['03d', '03n']},
  803: {desc: 'broken clouds: 51-84%', icon: ['04d', '04n']},
  804: {desc: 'overcast clouds: 85-100%', icon: ['04d', '04n']},
}

const WeatherImage = props => {
  try {
    const {className, code, ...rest} = props
    return (
      <img
        src={`http://openweathermap.org/img/wn/${weatherMap[code].icon[0]}@2x.png`}
        alt=""
        title={weatherMap[code].desc}
        className={`weather-image ${className ? className : ''}`}
        {...rest}
      />
    )
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e.message)
  }
  return null
}
// N.B.: rain commented out because its value is only ever zero or null
export default props => (
  <div className={'weather-strip'}>
    <WeatherImage code={props.weathercode} />
    <div className="temp">{props.temp ? `${Math.round(props.temp)}°` : '—'}</div>
    <div className="weather-data-column">
      {/*<div*/}
      {/*  className='rain'>Rain: {(props.rain !== undefined && String(props.rain)) || '—'}</div>*/}
      <div className="humidity">Humidity: {props.humidity || '—'}%</div>
      <div className="pressure">Pressure: {`${props.pressure || '—'}`} mm</div>
    </div>
  </div>
)
