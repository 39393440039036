import React from 'react'
import elide from 'ellipsize'
import Load from './PowerPlug'
import Battery from './Battery'
import SolarPanel from './SolarPanel'
import {isConnected, simpleStateOfChargeFraction} from '../libs/utilLib'
import './SystemCard.scss'
import WeatherStrip from './WeatherStrip'
import TimeSince from './TimeSince'
import moment from 'moment'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const CardColumn = props => {
  const {className = '', ...rest} = props
  return <div className={`card-column ${className}`} {...rest} />
}
const CardRow = props => {
  const {className = '', ...rest} = props
  return <div className={`card-row ${className}`} {...rest} />
}

const Metric = props => {
  const {className = '', ...rest} = props
  return (
    <div className={`metric ${className}`} {...rest}>
      {props.icon && <CardColumn>{[props.icon || <div />]}</CardColumn>}
      <CardColumn> {props.children} </CardColumn>
    </div>
  )
}

export default function SystemCard(props) {
  if (!props || !Object.keys(props).length) return null
  const errorConditions = props.errorConditions || {}
  const warningConditions = props.warningConditions || {}
  const hasError = Object.keys(errorConditions).length > 0
  const hasWarning = !hasError && Object.keys(warningConditions).length > 0
  const weatherTime = props.weather && props.weather.timestamp ? moment(props.weather.timestamp) : null
  const connected = isConnected(props.metricsTimestamp, props.reportingIntervalSeconds)
  return (
    <div className="system-card">
      <CardRow className={`id-row ${hasError ? 'error' : ''} ${hasWarning ? 'warning' : ''}`}>
        {props.systemPhoto && (
          <CardColumn className={'system-image-column'}>
            <img className="system-image" src={props.systemPhoto} alt="system" />
          </CardColumn>
        )}
        <CardColumn>
          <CardRow className="bell">
            <FontAwesomeIcon icon="bell" />
          </CardRow>
          <CardRow className="warn">
            <FontAwesomeIcon icon="exclamation" />
          </CardRow>

          <CardRow
            className={`system-name ${hasError ? 'error' : ''} ${hasWarning ? 'warning' : ''}`}
            title={props.name}
          >
            {elide(props.name, 20)}
          </CardRow>
          <CardRow
            className={`location ${hasError ? 'error' : ''} ${hasWarning ? 'warning' : ''}`}
            title={props.location && props.location.formattedAddress}
          >
            {props.location && props.location.city && props.location.state
              ? `${props.location.city}, ${props.location.state}`
              : '—'}
          </CardRow>
          <CardRow className={`last-connection ${connected ? '' : 'error'}`}>
            Last Connection — <TimeSince timestamp={props.metricsTimestamp} />
          </CardRow>
        </CardColumn>
      </CardRow>
      <CardRow className="metric-row-1">
        <Metric
          className={`battery ${errorConditions.battery ? 'error' : ''} ${
            warningConditions.battery ? 'warning' : ''
          }`}
          icon={<Battery key={Math.random()} error={!!errorConditions.battery} />}
        >
          <CardColumn className="data">
            <CardRow
              className={`row-top ${errorConditions.battery ? 'error' : ''} ${
                warningConditions.battery ? 'warning' : ''
              }`}
            >
              BATTERY
            </CardRow>
            <CardRow
              className={`row-middle ${errorConditions.battery ? 'error' : ''} ${
                warningConditions.battery ? 'warning' : ''
              }`}
            >
              {connected &&
              props.batteryVoltage &&
              moment.duration(moment().diff(props.batteryVoltageTimestamp)).asHours() < 1
                ? `${Math.round(
                    100 *
                      simpleStateOfChargeFraction(props.batteryVoltage / (props.nominalBatteryVoltage / 12))
                  )}%`
                : '—'}
            </CardRow>
            <CardRow
              className={`row-bottom ${errorConditions.battery ? 'error' : ''} ${
                warningConditions.battery ? 'warning' : ''
              }`}
            >
              {isConnected && props.batteryVoltage ? `${props.batteryVoltage}V` : '—'}
            </CardRow>
          </CardColumn>
        </Metric>
        <Metric
          className={`solar ${errorConditions.solar ? 'error' : ''} ${
            warningConditions.solar ? 'warning' : ''
          }`}
          icon={<SolarPanel key={Math.random()} error={!!errorConditions.solar} />}
        >
          <CardColumn className="data">
            <CardRow
              className={`row-top ${errorConditions.solar ? 'error' : ''} ${
                warningConditions.solar ? 'warning' : ''
              }`}
            >
              CURRENT
            </CardRow>
            <CardRow
              className={`row-middle ${errorConditions.solar ? 'error' : ''} ${
                warningConditions.solar ? 'warning' : ''
              }`}
            >
              {connected &&
              props.arrayCurrent &&
              moment.duration(moment().diff(props.arrayCurrentTimestamp)).asHours() < 1
                ? `${props.arrayCurrent}A`
                : '—'}
            </CardRow>
            <CardRow className={`row-bottom empty`}>empty</CardRow>
          </CardColumn>
        </Metric>
      </CardRow>
      <CardRow>
        <Metric
          className={`load ${errorConditions.load ? 'error' : ''} ${warningConditions.load ? 'warning' : ''}`}
          icon={<Load key={Math.random()} error={!!errorConditions.load} />}
        >
          <CardColumn className="data">
            <CardRow
              className={`row-top ${errorConditions.load ? 'error' : ''} ${
                warningConditions.load ? 'warning' : ''
              }`}
            >
              LOAD
            </CardRow>
            <CardRow
              className={`row-middle ${errorConditions.load ? 'error' : ''} ${
                warningConditions.load ? 'warning' : ''
              }`}
            >
              {connected &&
              moment.duration(moment().diff(props.loadCurrentTimestamp)).asHours() < 1 &&
              props.loadCurrent
                ? `${props.loadCurrent}A`
                : '—'}
            </CardRow>
            <CardRow className="row-bottom empty">empty</CardRow>
          </CardColumn>
        </Metric>
        <Metric className="weather">
          <CardRow className="row-top">
            <div>Local Weather</div>
          </CardRow>
          <CardRow className="row-middle">
            {connected && weatherTime && moment.duration(moment().diff(weatherTime)).asHours() < 3 ? (
              <WeatherStrip {...props.weather} />
            ) : (
              '—'
            )}
          </CardRow>
        </Metric>
      </CardRow>
    </div>
  )
}
