import React from 'react'
import AsyncSelect from 'react-select/async'
import {apiGet} from '../libs/apiLib'
import './AsyncDeviceSelect.scss'

export default props => {
  const toDeviceSelections = devices => {
    return devices
      .map(device => {
        return {
          value: parseInt(device.id),
          label: device.Name || device.name || '',
        }
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1))
  }
  const searchDevices = async value => {
    if (value.length > 1) {
      const api = apiGet()
      const result = await api.get(
        //`/device?search=${value}&attributes=${['systemPhoto', 'name']}&withHighlights=1`
        `/device?search=${value}&attributes=${[/* 'systemPhoto',*/ 'name']}&queryFields=${['name']}`
      )
      return toDeviceSelections(result.data.device)
    }
  }
  const onChange = selectedOptions => {
    props.onChange(selectedOptions)
  }

  return (
    <AsyncSelect
      className="async-device-select"
      classNamePrefix="async-device-select"
      isMulti
      cacheOptions
      isDisabled={props.isDisabled}
      defaultOptions
      // style={{width: devicesWidth}}
      onChange={onChange}
      value={props.value}
      loadOptions={searchDevices}
    />
  )
}
