import React from 'react'
import {Button} from 'react-bootstrap'
import './LoaderButton.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default function LoaderButton({isLoading, className = '', disabled = false, ...props}) {
  return (
    <Button className={`loader-button ${className}`} disabled={disabled || isLoading} {...props}>
      {isLoading && <FontAwesomeIcon className="sync" icon="sync" spin />}
      {props.children}
    </Button>
  )
}
