import React, {useState} from 'react'
import {Alert, Button, Form, FormControl, FormGroup, FormLabel} from 'react-bootstrap'
import LoaderButton from '../components/LoaderButton'
import {useFormFields} from '../libs/formLib'
import './SystemSettingsForm.scss'

export default function SystemSettingsForm(props) {
  const {shadow} = props
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [fields, handleFieldChange] = useFormFields(
    {
      version: shadow.version,
      enableTunnelPort: shadow.enableTunnelPort === true || false,
      reportingIntervalSeconds: shadow.reportingIntervalSeconds,
    },
    () => {
      const errors = {}
      setErrors(errors)
    }
  )

  async function handleSubmit(event) {
    event.preventDefault()
    setErrors({})
    setIsLoading(true)
    try {
      await props.onUpdate({...fields, reportingIntervalSeconds: Number(fields.reportingIntervalSeconds)})
    } catch (e) {
      setErrors({response: e.message})
    } finally {
      setIsLoading(false)
    }
  }

  function validateSystemSettingsForm() {
    return true
  }

  const options = props.versions.map(
    ({id, gitHash, majorVersion = 1, minorVersion = 0, maintenanceVersion = 0, message}) => (
      <option key={id} value={gitHash}>
        {`${majorVersion}.${minorVersion}.${maintenanceVersion}-build-${id}: "${message}"`}
      </option>
    )
  )

  return (
    <Form className="container system-settings-form " onSubmit={handleSubmit}>
      {errors.response && <Alert variant={'danger'}>{errors.response}</Alert>}
      <FormGroup controlId="version">
        <FormLabel>Version</FormLabel>
        <Form.Control as="select" value={fields.version} onChange={handleFieldChange}>
          {options}
        </Form.Control>
      </FormGroup>
      <FormGroup controlId="reportingIntervalSeconds">
        <FormLabel>Reporting Interval (Seconds)</FormLabel>
        <FormControl type="number" value={fields.reportingIntervalSeconds} onChange={handleFieldChange} />
      </FormGroup>
      <FormGroup controlId="enableTunnelPort">
        <Form.Check
          type="checkbox"
          label="Enable Ssh Tunnel"
          checked={fields.enableTunnelPort}
          onChange={handleFieldChange}
          className={fields.enableTunnelPort && 'is-checked'}
        />
      </FormGroup>
      <div className="button-row">
        <Button disabled={!validateSystemSettingsForm()} onClick={() => props.onCancel && props.onCancel()}>
          Cancel
        </Button>
        <LoaderButton type="submit" isLoading={isLoading} disabled={!validateSystemSettingsForm()}>
          Save
        </LoaderButton>
      </div>
    </Form>
  )
}
