import React from 'react'
export default ({className = 'bell', error = false}) => {
  const id = String(Math.random())
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
        <defs>
          <linearGradient
            id={id}
            x1="-237.4"
            y1="377.81"
            x2="-240.29"
            y2="374.76"
            gradientTransform="matrix(31, 0, 0, -31, 7482.33, 11736.67)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#d25558" />
            <stop offset="1" stopColor="#cf4444" />
          </linearGradient>
        </defs>
        <g>
          <g>
            <rect x="20" y="20" width="110" height="110" rx="35.48" fill={error ? 'white' : `url(#${id})`} />
            <path
              d="M75,115.1a10,10,0,0,0,10-10H65a10,10,0,0,0,10,10Zm33.62-23.45c-3-3.26-8.66-8.16-8.66-24.18A24.72,24.72,0,0,0,80,43.16V39.89a5,5,0,0,0-10,0v3.27A24.72,24.72,0,0,0,50,67.47c0,16-5.65,20.93-8.66,24.17A4.9,4.9,0,0,0,40,95a5,5,0,0,0,5,5H105a5,5,0,0,0,5-5h0A4.86,4.86,0,0,0,108.62,91.65Z"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
