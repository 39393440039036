import React from 'react'
import './EventTypeIcon.scss'
import Thermometer from './Thermometer'
import Battery from './Battery'
import SolarPanel from './SolarPanel'
import Bolt from './Bolt'
import {fromCamelToSpacedTitleCase} from '../libs/utilLib'
import PowerPlug from './PowerPlug'

export default props => {
  const {eventType} = props
  return (
    <div className="event-type-icon">
      {eventType === 'ambientTemp' && <Thermometer />}
      {eventType === 'arrayCurrent' && <SolarPanel />}
      {eventType === 'arrayVoltage' && <SolarPanel />}
      {eventType === 'batteryCurrent' && <Battery />}
      {eventType === 'batteryVoltage' && <Battery />}
      {eventType === 'loadCurrent' && <PowerPlug />}
      {eventType === 'loadVoltage' && <PowerPlug />}

      {eventType === 'float16' && <Bolt />}
      {eventType === 'uint16' && <Bolt />}
      {eventType === 'signedUint7' && <Bolt />}
      {eventType === 'signedUint15' && <Bolt />}
      {eventType === 'boolean' && <Bolt />}
      {eventType === 'booleanUint16' && <Bolt />}
      <span>{fromCamelToSpacedTitleCase(eventType)}</span>
    </div>
  )
}
