import React, {useEffect, useState} from 'react'
import './ImageEdit.scss'

import RangeSlider from 'react-bootstrap-range-slider'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.scss'
import Cropper from 'react-easy-crop'

/**
 *
 * @param url
 * @returns {Promise<unknown>}
 */
export const createImageSourceUrl = async url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = image.width
      canvas.height = image.height
      ctx.drawImage(image, 0, 0)
      canvas.toBlob(async blob => {
        const base64 = await blobToBase64Url(blob)
        resolve(base64)
      }, 'image/jpeg')
    })
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.loading = 'eager'
    image.src = url // triggers loading
  })
/**
 *
 * @param imageSource
 * @param crop
 * @param width
 * @param height
 * @returns {Promise<unknown>}
 */
export const cropImage = async (imageSource, crop, width, height) => {
  return new Promise(resolve => {
    ;(async () => {
      const imageSourceUrl = await createImageSourceUrl(imageSource)
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement('canvas')
        /* setting canvas width & height sizes crop image */
        canvas.height = height
        canvas.width = width
        const ctx = canvas.getContext('2d')

        ctx.drawImage(img, crop.x, crop.y, crop.width, crop.height, 0, 0, width, height)
        resolve(canvas.toDataURL())
      }
      img.src = imageSourceUrl
    })()
  })
}

export async function blobToBase64Url(blob) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export function ImageEdit(props) {
  const [zoom, setZoom] = useState(1)
  const [crop, setCrop] = useState({x: 0, y: 0})

  const onCropComplete = (_, croppedAreaPixels) => {
    props.onCropComplete && props.onCropComplete(croppedAreaPixels)
    return true
  }

  useEffect(() => {
    ;(async () => {
      setZoom(1)
      setCrop({x: 0, y: 0})
    })()
  }, [props.resetKey])

  const classPrefix = props.classPrefix ? `${props.classPrefix}-` : ''
  return (
    <div className={`${classPrefix}image-edit`}>
      <div className="image-cropper">
        <Cropper
          aspect={1}
          crop={crop}
          cropShape={props.cropShape}
          image={props.imageSource}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          showGrid={false}
          zoom={zoom}
        />
      </div>
      <div className={'edit-controls'}>
        Zoom
        <div className={'slider'}>
          <RangeSlider
            aria-labelledby="slider"
            disabled={!props.imageSource}
            max={3}
            min={1}
            onChange={e => setZoom(Number(e.target.value))}
            size="sm"
            step={0.02}
            tooltip={'off'}
            value={zoom}
          />
        </div>
        <label className={'btn btn-primary'}>
          Choose New File
          <input type="file" accept=".jpg, .jpeg, .png" hidden onChange={props.handleFileSelection} />
        </label>
      </div>
    </div>
  )
}
