import React from 'react'
export default ({className = 'battery', error = false}) => {
  const id = String(Math.random())
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
        <defs>
          <linearGradient
            id={id}
            x1="-402.95"
            y1="711.83"
            x2="-399.63"
            y2="714.35"
            gradientTransform="matrix(37.66, 0, 0, -28.25, 15203.36, 20212.63)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#345b82" />
            <stop offset="1" stopColor="#4b81a1" />
          </linearGradient>
        </defs>
        <path
          className="battery-blue"
          fill={error ? 'white' : `url(#${id})`}
          d="M139.17,38.33H130V24.58A4.58,4.58,0,0,0,125.42,20H97.91a4.58,4.58,0,0,0-4.58,4.58h0V38.33H56.67V24.58A4.59,4.59,0,0,0,52.08,20H24.58A4.58,4.58,0,0,0,20,24.58h0V38.33H10.83A9.17,9.17,0,0,0,1.67,47.5v73.33A9.16,9.16,0,0,0,10.83,130H139.17a9.16,9.16,0,0,0,9.16-9.17h0V47.5A9.17,9.17,0,0,0,139.17,38.33Zm-82.5,39a2.3,2.3,0,0,1-2.29,2.29H22.31A2.29,2.29,0,0,1,20,77.28V72.71a2.29,2.29,0,0,1,2.29-2.29H54.39a2.3,2.3,0,0,1,2.29,2.29Zm73.33,0a2.3,2.3,0,0,1-2.29,2.29H116.25V91A2.3,2.3,0,0,1,114,93.32h-4.58A2.29,2.29,0,0,1,107.09,91V79.58H95.62a2.29,2.29,0,0,1-2.29-2.29V72.71a2.3,2.3,0,0,1,2.29-2.29h11.46V58.94a2.3,2.3,0,0,1,2.29-2.29H114a2.3,2.3,0,0,1,2.29,2.29V70.4H127.7A2.29,2.29,0,0,1,130,72.69Z"
        />
      </svg>
    </div>
  )
}
