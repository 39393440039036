import React from 'react'
import './SystemOverview.scss'
import {ReactComponent as PowerFlowChart} from './power-flow-chart.svg'
import {precision} from '../libs/utilLib'
import timezoneMoment from 'moment-timezone'

export default function SystemOverview(props) {
  const {arrayCurrent, arrayVoltage, batteryCurrent, batteryVoltage, loadCurrent, loadVoltage} = props
  const formatDate = value => timezoneMoment(value).format('MMMM Do, YYYY')
  return (
    <div className={'system-overview-panel'}>
      <div className={'heading'}>Power Flow Chart</div>
      <div className={'power-flow-chart'}>
        <PowerFlowChart />
        <div className={'array'}>
          <div className={'power-name'}>power input</div>
          <div className={'power-value'}>
            {typeof arrayVoltage === 'number' && typeof arrayCurrent === 'number'
              ? `${precision(arrayVoltage * arrayCurrent, 2)}W`
              : '—'}
          </div>
        </div>
        <div className={'battery'}>
          <div className={'power-name'}>battery</div>
          <div className={'power-value'}>
            {typeof batteryVoltage === 'number' && typeof batteryCurrent === 'number'
              ? `${precision(batteryVoltage * batteryCurrent, 2)}W`
              : '—'}
          </div>
        </div>
        <div className={'load'}>
          <div className={'power-name'}>load</div>
          <div className={'power-value'}>
            {typeof loadVoltage === 'number' && typeof loadCurrent === 'number'
              ? `${precision(loadVoltage * loadCurrent, 2)}W`
              : '—'}
          </div>
        </div>
      </div>
      <div className={'heading'}>System Design Info</div>
      <div className={'system-design-info'}>
        {[
          {prop: 'accessoryModel', label: 'Accessory Model'},
          {prop: 'anticipatedInstallDate', label: 'Anticipated Install Date', format: formatDate},
          {prop: 'batteryModel', label: 'Battery Model'},
          {prop: 'c100BatteryCapacity', label: 'designed battery', unit: 'Ah'},
          {prop: 'chargeControllerModel', label: 'Charge Controller'},
          {prop: 'customerSiteNickname', label: 'Site Name'},
          {prop: 'designDaysOfAutonomy', label: 'autonomy', unit: ' Days'},
          {prop: 'drawing', label: 'Drawing'},
          {prop: 'enclosureModel', label: 'Enclosure Model'},
          {prop: 'loadControllerModel', label: 'Load Controller'},
          {prop: 'mountModel', label: 'Mount Model'},
          {prop: 'nominalBatteryVoltage', label: 'system voltage', unit: 'V'},
          {prop: 'providedMountModel', label: 'Provided Mount Model'},
          {prop: 'salesOrder', label: 'Sales Order'},
          {prop: 'solarArrayPower', label: 'Designed Solar', unit: 'W'},
          {prop: 'solarPanelModel', label: 'Solar Panel Model'},
          {prop: 'systemQCPerformedDate', label: 'System QC', format: formatDate},
        ]
          .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
          .filter(({prop}) => props[prop])
          .map(({prop, label, unit = '', format}) => (
            <div className={'spec'} key={prop}>
              <div className={'spec-name'}>{label}</div>
              <div className={'spec-value'}>
                {`${format ? format(props[prop]) : props[prop]}${unit || ''}`}
              </div>
            </div>
          ))}
      </div>
      <div className={'heading'}>System Components</div>
    </div>
  )
}
