import React, {useEffect} from 'react'
import {Route, Redirect} from 'react-router'

export default function AuthenticatedRoute({
  location,
  component: Component,
  onLocationChange,
  isAuthenticated,
  appProps,
  ...rest
}) {
  useEffect(() => onLocationChange(location.pathname + location.search), [location, appProps])
  //console.log(`Authenticated route for ${Component.name} user: ${JSON.stringify((appProps.user && appProps.user.email) || false)}`)
  return (
    <Route
      {...rest}
      render={routerProps =>
        isAuthenticated ? (
          <Component {...routerProps} {...appProps} />
        ) : (
          <Redirect to={'/login?redirect=' + encodeURIComponent(location.pathname + location.search)} />
        )
      }
    />
  )
}
