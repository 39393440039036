import React from 'react'
import {Alert, Form} from 'react-bootstrap'
import PropTypes from 'prop-types'
/* simple cookie cutter form element */
export default function FormGroup({
  controlId,
  type,
  label,
  options,
  errors,
  onChange,
  multiple,
  value,
  children,
}) {
  return (
    <Form.Group controlId={controlId} className={controlId}>
      {label && type !== 'checkbox' && <Form.Label>{label}</Form.Label>}
      {type === 'select' && (
        <Form.Control as="select" onChange={onChange} multiple={multiple} value={value}>
          {' '}
          {options}{' '}
        </Form.Control>
      )}
      {type === 'checkbox' && (
        <Form.Check
          inline
          type="checkbox"
          label={label}
          checked={value}
          className={value && 'is-checked'}
          onChange={onChange}
        />
      )}
      {type !== 'select' && type !== 'checkbox' && (
        <Form.Control as={type} onChange={onChange} value={value} />
      )}
      {errors[controlId] && <Alert variant={'danger'}>{errors[controlId]}</Alert>}
      {children}
    </Form.Group>
  )
}
FormGroup.propTypes = {
  controlId: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  type: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
}
