import React from 'react'
export default ({className = 'solar-panel', error = false}) => {
  const id = String(Math.random())
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 40">
        <defs>
          <linearGradient
            id={id}
            x1="-758.59"
            y1="694.57"
            x2="-757.7"
            y2="695.24"
            gradientTransform="matrix(48, 0, 0, -38.4, 36417.08, 26697.2)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#345b82" />
            <stop offset="1" stopColor="#4b81a1" />
          </linearGradient>
        </defs>
        <path
          fill={error ? 'white' : `url(#${id})`}
          d="M33.75,35H30V32.5H20V35H16.25A1.25,1.25,0,0,0,15,36.27h0v2.48A1.25,1.25,0,0,0,16.25,40h0l17.5,0A1.25,1.25,0,0,0,35,38.73h0V36.25A1.25,1.25,0,0,0,33.75,35Zm12-32.92A2.53,2.53,0,0,0,43.21,0H6.79A2.53,2.53,0,0,0,4.28,2.09C-.26,28.84,0,27.19,0,27.5A2.54,2.54,0,0,0,2.55,30h44.9A2.53,2.53,0,0,0,50,27.52c0-.32.26,1.32-4.28-25.43ZM20.3,5h9.4l.76,7.5H19.54ZM14.43,25H5.55L7,16.25h8.28ZM15.7,12.5h-8L9,5h7.51ZM18.26,25l.89-8.75h11.7L31.74,25ZM33.54,5h7.51l1.28,7.5h-8Zm2,20-.89-8.75H43L44.45,25Z"
        />
      </svg>
    </div>
  )
}
