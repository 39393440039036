import React, {useRef, useState} from 'react'
import {Button, Overlay, Popover} from 'react-bootstrap'

export default function OverlayButton(props) {
  const [show, setShow] = useState(false)
  const target = useRef(null)

  function handleClick() {
    props.onClick && props.onClick()
    setShow(true)
    setTimeout(() => {
      setShow(false)
    }, 3000)
  }

  return (
    <div className={`${props.className ? props.className : ''} button-overlay`}>
      <Button ref={target} size="sm" title={props.title} onClick={handleClick}>
        {' '}
        {props.children}{' '}
      </Button>
      <Overlay className="fade" message={props.message} target={target.current} show={show} placement="left">
        <Popover id={props.popoverId}>
          <Popover.Content> {props.message} </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  )
}
