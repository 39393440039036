import classNames from 'classnames'
import parseHTML from 'react-html-parser'
import React, {useEffect, useState} from 'react'
import {Route, Switch, withRouter} from 'react-router'
import Auth from '@aws-amplify/auth'
import Amplify from '@aws-amplify/core'
import {ListGroup, ListGroupItem, Nav, NavDropdown} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import './App'
import Accounts from './containers/Accounts'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import Busy from './components/Busy'
import Login from './containers/Login'
import Map from './containers/Map'
import NewPassword from './containers/NewPassword'
import NotFound from './containers/NotFound'
import Notifications from './containers/Notifications'
import SwaggerApi from './containers/SwaggerApi'
import SystemsList from './containers/SystemsPage'
import SystemsPage from './containers/SystemsPage'
import UnauthenticatedRoute from './components/UnauthenticatedRoute'
import UserProfile from './containers/UserProfile'
import Users from './containers/Users'
import {apiGet, apiInit, apiSetAuthHeader} from './libs/apiLib'
import {getDevices, initials} from './libs/userLib'
import timezoneMoment from 'moment-timezone'
import './libs/iconLib'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import elide from 'ellipsize'
import SystemDetail from './containers/SystemDetail'
import logo from './logo.png'
import {ReactComponent as UsersIcon} from './Users.svg'
import {ReactComponent as HomeIcon} from './Home.svg'
import {ReactComponent as LogoutIcon} from './Log Out.svg'
import {ReactComponent as ApiIcon} from './API.svg'
import ProfilePicture from './components/ProfilePicture'
import {escapeRegex, isConnected} from './libs/utilLib'
import SuperusersPage from './containers/Superusers'

const TEMPORARY_PASSWORD_VALIDITY_DAYS = 14
Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
})

timezoneMoment.tz.setDefault(Intl.DateTimeFormat().resolvedOptions().timeZone || 'GMT')
apiInit(
  process.env.REACT_APP_API_ENDPOINT,
  Auth,
  process.env.REACT_APP_COGNITO_USERNAME
    ? {'x-cognito-username': process.env.REACT_APP_COGNITO_USERNAME}
    : null
)

function Routes({isBooting, isAuthenticated, onLocationChange, appProps}) {
  const isSuperuser = appProps.user && appProps.user.isSuperuser
  return (
    <Switch>
      <Route path="/new-password" exact component={NewPassword} appProps={appProps} />
      <AuthenticatedRoute
        path="/"
        exact
        component={SystemsPage}
        {...{isAuthenticated, onLocationChange, appProps}}
      />
      <AuthenticatedRoute
        path="/system/:id"
        {...{isAuthenticated, onLocationChange, appProps}}
        component={SystemDetail}
      />
      <AuthenticatedRoute
        path="/systems/list"
        {...{isAuthenticated, onLocationChange, appProps}}
        component={SystemsList}
      />
      <AuthenticatedRoute
        path="/system"
        {...{isAuthenticated, onLocationChange, appProps}}
        component={SystemsList}
      />
      <AuthenticatedRoute
        path="/accounts"
        component={Accounts}
        {...{isAuthenticated, onLocationChange, appProps}}
      />
      <AuthenticatedRoute
        path="/account"
        component={Accounts}
        {...{isAuthenticated, onLocationChange, appProps}}
      />
      <AuthenticatedRoute
        path="/notifications"
        component={Notifications}
        {...{isAuthenticated, onLocationChange, appProps}}
      />
      <AuthenticatedRoute path="/map" component={Map} {...{isAuthenticated, onLocationChange, appProps}} />
      <AuthenticatedRoute
        path="/user-profile"
        component={UserProfile}
        {...{isAuthenticated, onLocationChange, appProps}}
      />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={Login}
        isAuthenticated={isAuthenticated}
        isBooting={isBooting}
        appProps={appProps}
      />
      {isSuperuser && (
        <AuthenticatedRoute
          path="/superusers"
          {...{isAuthenticated, onLocationChange}}
          component={SuperusersPage}
        />
      )}
      {isSuperuser && (
        <AuthenticatedRoute
          path="/api"
          exact
          component={SwaggerApi}
          {...{isAuthenticated, onLocationChange, appProps}}
        />
      )}
      {isSuperuser && (
        <AuthenticatedRoute
          path="/users"
          exact
          component={Users}
          {...{isAuthenticated, onLocationChange, appProps}}
        />
      )}
      <AuthenticatedRoute
        path="/user/:id"
        exact
        component={Users}
        {...{isAuthenticated, onLocationChange, appProps}}
      />
      {isBooting ? <Busy /> : <Route component={NotFound} />}
    </Switch>
  )
}

function App(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(true)
  const [isBooting, setIsBooting] = useState(true)
  const [user, setUser] = useState(null)
  const api = apiGet()
  const [favorites, setFavorites] = useState({})
  const [connections, setConnections] = useState({})
  const [selectedDeviceId, setSelectedDeviceId] = useState(0)
  const [isActiveSearch, setActive] = useState(false)
  const toggleOmniSearch = () => {
    setActive(!isActiveSearch)
  }

  const onLocationChange = location => {
    if (!location.match(/^\/system/)) setSelectedDeviceId(0)
  }
  const updateDevice = async (id, key, value) => {
    const result = await api.put(`/device/${id}`, {[key]: value})

    const device = user.isSuperuser ? user.allDevices[id] : user.devices[id]
    device[key] = value
    if (key === 'deployedTimestamp') {
      /* server adds deployedBy , so update locally */
      const users = user.allUsers || user.users
      device['deployedBy'] = users[result.data.deployedBy]
    }
    setUser({...user})
  }

  useEffect(() => {
    let mounted = true

    async function refreshTokens() {
      try {
        if (isAuthenticated) {
          const currentSession = await Auth.currentSession()
          apiSetAuthHeader(currentSession.idToken.jwtToken)
          const {data} = await api.get(`/user?graph=true`)
          if (!mounted) return
          /* eslint-disable no-eval */
          data.hydrateMap = eval(data.hydrateMap)
          const {user} = data.hydrateMap(data)

          Object.assign(
            user,
            {...currentSession.idToken.payload},
            {idToken: currentSession.idToken.jwtToken},
            {
              getDevices,
            }
          )
          setUser(user)
          setIsAuthenticated(true)
          if (user) {
            const temp = Object.values(user.allDevices || user.devices).reduce((acc, d) => {
              acc[d.id] = d.isFavorite
              return acc
            }, {})
            setFavorites(temp)
            const connections = Object.values(user.allDevices || user.devices).reduce((acc, d) => {
              acc[d.id] = isConnected(d.metricsTimestamp, d.reportingIntervalSeconds)
              return acc
            }, {})
            setConnections(connections)
          }
        }
      } catch (e) {
        setUser(null)
        setIsAuthenticated(false)

        if (props.history.location.pathname !== '/new-password') props.history.push('/login')
      } finally {
        setIsBooting(false)
      }
      return function cleanUp() {
        mounted = false
      }
    }

    return refreshTokens()
  }, [isAuthenticated, api, props.history])

  const toggleFavorite = async id => {
    favorites[id] = !favorites[id]
    await api.put(`/device/${id}`, {isFavorite: favorites[id]})
    setFavorites({...favorites})
  }

  async function handleLogout() {
    try {
      await Auth.signOut()

      setUser(null)
      setIsAuthenticated(false)
      props.history.push('/login')
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message)
    }
  }

  function SystemBrowser(props) {
    const storageKey = 'system-browser'
    const [panelOpen, setPanelOpen] = useState(localStorage.getItem(storageKey) === '1')
    const [devices] = useState(
      props.user
        ? Object.values(props.user.allDevices || props.user.devices).sort((a, b) =>
            a.name > b.name ? 1 : -1
          )
        : []
    )
    const [filteredDevices, setFilteredDevices] = useState(devices)
    const [filterText, setFilterText] = useState('')

    useEffect(() => {
      if (!filterText.length) return setFilteredDevices(devices)
      ;(async () => {
        try {
          const api = apiGet()
          const result = await api.get(
            `/device?search=${filterText}&attributes=${['name']}&queryFields=${['name']}`
          )
          setFilteredDevices(
            result.data.device.map(d => {
              d.name = d.Name
              delete d.Name
              return d
            })
          )
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(`search error: ${e.message}`)
        }
      })()
    }, [filterText, devices])

    const onToggleOpen = () => {
      localStorage.setItem(storageKey, panelOpen ? '0' : '1')
      setPanelOpen(!panelOpen)
    }
    const onDeviceClick = id => {
      setSelectedDeviceId(parseInt(id))
      props.history.push(`/system/${id}/detail`)
    }
    const decorateName = (name, queryString) => {
      name = elide(name, 20)
      const regex = new RegExp(`(${escapeRegex(queryString)})`, 'ig')
      return parseHTML(name.replace(regex, `<span>$1</span>`))
    }
    if (!props.user) return null
    return (
      <div className={`system-browser ${!panelOpen ? 'panel-closed' : ''}`}>
        <div className="control-button">
          <button onClick={onToggleOpen}>{panelOpen ? '<' : '>'}</button>
        </div>
        <div className="panel">
          <div className={'panel-items'}>
            <div className="panel-title">all systems</div>
            <div className="search-frame">
              <FontAwesomeIcon className="search-icon" icon="search" />
              <input type="search" value={filterText} onChange={e => setFilterText(e.target.value)} />
            </div>
            <ListGroup>
              <div className="subHeader first">
                <FontAwesomeIcon icon="heart" />
                Favorites
                {(filteredDevices || [])
                  .filter(d => favorites[d.id])
                  .map(d => (
                    <ListGroupItem
                      action
                      key={d.id}
                      className={classNames({
                        error: d.id === 11,
                        warning: d.id === 19,
                        active: d.id === selectedDeviceId,
                      })}
                    >
                      <div onClick={() => onDeviceClick(d.id)} title={d.name} className={'name'}>
                        {decorateName(d.name, filterText)}
                      </div>
                      <FontAwesomeIcon className="bell" icon="bell" />
                      <FontAwesomeIcon className="warn" icon="exclamation" />
                    </ListGroupItem>
                  ))}
              </div>
              <div className="subHeader">
                <FontAwesomeIcon icon="bell" />
                Alarms & warnings
                {(filteredDevices || [])
                  .filter(d => [11, 19].includes(d.id) || !connections[d.id])
                  .map(d => (
                    <ListGroupItem
                      action
                      key={d.id}
                      className={classNames({
                        error: d.id === 11,
                        warning: d.id === 19,
                        active: d.id === selectedDeviceId,
                      })}
                    >
                      <div onClick={() => onDeviceClick(d.id)} title={d.name} className={'name'}>
                        {decorateName(d.name, filterText)}
                      </div>
                      <FontAwesomeIcon className="bell" icon="bell" />
                      <FontAwesomeIcon className="warn" icon="exclamation" />
                    </ListGroupItem>
                  ))}
              </div>
              <div className="subHeader">
                <FontAwesomeIcon icon="solar-panel" />
                systems
                {(filteredDevices || [])
                  .filter(d => ![11, 19].includes(d.id) && !favorites[d.id] && connections[d.id])
                  .map(d => (
                    <ListGroupItem
                      action
                      key={d.id}
                      className={classNames({
                        error: d.id === 11,
                        warning: d.id === 19,
                        active: d.id === selectedDeviceId,
                      })}
                    >
                      <div onClick={() => onDeviceClick(d.id)} title={d.name} className={'name'}>
                        {decorateName(d.name, filterText)}
                      </div>
                      <FontAwesomeIcon className="bell" icon="bell" />
                      <FontAwesomeIcon className="warn" icon="exclamation" />
                    </ListGroupItem>
                  ))}
              </div>
            </ListGroup>
          </div>
        </div>
      </div>
    )
  }

  const adminCount = user && user.accounts && Object.values(user.accounts).filter(a => a.isAdmin).length
  /* Note: due to bug in bootstrap 4.x and 5.x, must set our own active classname called 'is-active' */
  const location = props.location.pathname
  return (
    <div className="app">
      {user && (
        <Nav className="menu flex-column">
          <div className="upperGroup">
            <img src={logo} alt="" />
            <LinkContainer to="/">
              <Nav.Link className={location === '/' ? 'is-active' : null} title="home">
                <div>
                  <HomeIcon />
                </div>
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to="/map">
              <Nav.Link className={location === '/map' ? 'is-active' : null} title="map">
                <div>
                  <FontAwesomeIcon icon="map-marker-alt" />
                </div>
              </Nav.Link>
            </LinkContainer>
            <hr />
            {/* <LinkContainer to="/notifications">
              <Nav.Link className={location === '/notifications' ? 'is-active' : null} title="notifications">
                <div>
                  <BellIcon />
                </div>
              </Nav.Link>
            </LinkContainer> */}

            <Nav.Link
              className={isActiveSearch ? 'is-active search' : 'search'}
              onClick={toggleOmniSearch}
              title="search"
            >
              <div>
                <FontAwesomeIcon icon="search" />
              </div>
            </Nav.Link>
          </div>
          <NavDropdown
            title={
              <div>
                <ProfilePicture
                  initials={initials(user)}
                  isSuperuser={user.isSuperuser}
                  image={user.profilePicture}
                />
              </div>
            }
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item title="sign out" onClick={handleLogout}>
              <LogoutIcon />
              <div className="dropdown-item-text">Logout</div>
            </NavDropdown.Item>
            <LinkContainer to="/user-profile">
              <NavDropdown.Item title="profile">
                <div className="dropdown-item-text">Profile</div>
              </NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/new-password">
              <NavDropdown.Item title="change password">
                <div className="dropdown-item-text">Change Password</div>
              </NavDropdown.Item>
            </LinkContainer>
            {(user.isSuperuser || adminCount > 0) && (
              <LinkContainer to="/accounts">
                <NavDropdown.Item title="accounts">
                  <div className="dropdown-item-text">{`Account${
                    user.isSuperuser || adminCount > 1 ? 's' : ''
                  }`}</div>
                </NavDropdown.Item>
              </LinkContainer>
            )}
            {user.isSuperuser && (
              <LinkContainer to={'/api'}>
                <NavDropdown.Item title="api">
                  <ApiIcon />
                  <div className="dropdown-item-text">Api</div>
                </NavDropdown.Item>
              </LinkContainer>
            )}
            {user.isSuperuser && (
              <LinkContainer to={'/users'}>
                <NavDropdown.Item title="users">
                  <UsersIcon />
                  <div className="dropdown-item-text">Users</div>
                </NavDropdown.Item>
              </LinkContainer>
            )}
            {user.isSuperuser && (
              <LinkContainer to={'/superusers'}>
                <NavDropdown.Item title="superusers">
                  <UsersIcon />
                  <div className="dropdown-item-text">{"Superusers' Powers Page"}</div>
                </NavDropdown.Item>
              </LinkContainer>
            )}
          </NavDropdown>
          {!user && (
            <LinkContainer to="/login">
              <Nav.Link>Sign In</Nav.Link>
            </LinkContainer>
          )}
        </Nav>
      )}
      <div className={isActiveSearch ? 'open omni' : 'omni'}>
        <h1>Omni Search</h1>
      </div>
      <SystemBrowser history={props.history} user={user} />
      <div className="routes">
        <Routes
          appProps={{
            TEMPORARY_PASSWORD_VALIDITY_DAYS,
            setIsAuthenticated,
            user,
            setUser,
            updateDevice,
            favorites,
            connections,
            toggleFavorite,
            onLocationChange,
          }}
          {...{
            TEMPORARY_PASSWORD_VALIDITY_DAYS,
            connections,
            favorites,
            isAuthenticated,
            isBooting,
            onLocationChange,
            setIsAuthenticated,
            setUser,
            toggleFavorite,
            updateDevice,
          }}
        />
      </div>
    </div>
  )
}

export default withRouter(App)
