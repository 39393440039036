import React, {useState} from 'react'
import './SystemsPage.scss'
import {Button} from 'react-bootstrap'
import {ReactComponent as ListMode} from './list-mode.svg'
import {ReactComponent as CardMode} from './card-mode.svg'
import SystemsList from '../components/SystemsList'
import SystemCards from '../components/SystemCards'

export default function SystemsPage(props) {
  const localStorageKey = 'systems-page-mode'
  const [pageMode, setPageMode] = useState(localStorage.getItem(localStorageKey) || 'cards')
  const onClick = mode => {
    setPageMode(mode)
    localStorage.setItem(localStorageKey, mode)
  }
  return (
    <div className="systems-page">
      <div className="systems-page-header content-header">
        <div className="header-title">Systems</div>
        <div className="page-mode">
          <Button
            onClick={() => onClick('cards')}
            className={pageMode === 'cards' ? 'cards selected-mode' : 'cards'}
          >
            <CardMode /> Card View{' '}
          </Button>
          <Button
            onClick={() => onClick('list')}
            className={pageMode === 'list' ? 'list selected-mode' : 'list'}
          >
            <ListMode /> List View{' '}
          </Button>
        </div>
      </div>
      <div className="systems-page-body">
        {pageMode === 'cards' && <SystemCards {...props} />}
        {pageMode === 'list' && <SystemsList {...props} />}
      </div>
    </div>
  )
}
