import React, {useState} from 'react'
import Table from '../components/Table'
import {getFunctionName} from '../libs/utilLib'
import {ContactsCell, DateCell, SystemsCell, UserLinkCell} from '../components/TableCells'
import {apiGet} from '../libs/apiLib'
import {Button} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import NewUserModal from '../components/NewUserModal'
import './Accounts.scss'

const api = apiGet()

export default function Accounts({user, location: {pathname}}) {
  const [modalShow, setModalShow] = useState(false)
  const accounts = user.allAccounts || user.accounts
  const [isAnAdmin] = useState(Object.values(accounts).filter(a => a.isAdmin).length > 0)
  const columns = [
    {accessor: 'name', Header: 'Name', default: true},
    {accessor: 'id', Header: 'Id', so: true},
    {
      accessor: 'devices',
      Header: 'Systems',
      Cell: ({value}) => <SystemsCell value={Object.values(value)} />,
      default: true,
    },
    {
      accessor: 'contacts',
      Header: 'Contacts',
      default: true,
      Cell: props => (
        <ContactsCell
          {...{
            ...props,
            joinType: 'account',
            users: Object.values(user.allUsers),
            contacts: Object.values(props.value),
            joinTypeId: props.row.original.id,
          }}
        />
      ),
    },
    {accessor: 'salesforceId', Header: 'Salesforce Id', so: true},
    {accessor: 'createdBy', Header: 'Created By', Cell: UserLinkCell, so: true},
    {accessor: 'createDate', Header: 'Created', Cell: DateCell, so: true},
    {accessor: 'deletedBy', Header: 'DeletedBy', Cell: UserLinkCell, so: true},
    {accessor: 'deleteTimestamp', Header: 'Deleted', Cell: DateCell, so: true},
    {accessor: 'lastModifiedBy', Header: 'Last Modified By', Cell: UserLinkCell, ao: true},
    {accessor: 'lastModifiedDate', Header: 'Last Modified', Cell: DateCell, ao: true},
  ].filter(column => {
    const columnFilter = column => {
      const {so /* superuser only */, ao /* admin only */} = column || {}
      return user.isSuperuser || (!so && (isAnAdmin || !ao))
    }
    if (column.accessor) return columnFilter(column)
    else {
      column.columns = column.columns.filter(columnFilter)
      return true
    }
  })

  const accountId = pathname.split('/')[2]

  const data = accountId ? [accounts[accountId]] : Object.values(accounts)
  if (!data) return null
  const heading =
    data.length === 0
      ? 'No Accounts Found'
      : data.length > 1
      ? 'Accounts'
      : `Account — ${data[0].name || 'with no name'}`
  return (
    <div className="container-fluid accounts-page">
      <NewUserModal {...{setModalShow, user, show: modalShow, joinType: 'account'}} />
      <Table
        {...{
          cellInjections: {users: user.users, accounts: user.accounts, api},
          columns: user.isSuperuser || isAnAdmin ? columns : columns.filter(c => c.Header !== 'Contacts'),
          heading,
          storageKey: getFunctionName(),
          data: data,
          bannerItems: isAnAdmin
            ? [
                <>
                  <Button className="add" onClick={() => setModalShow(true)}>
                    <FontAwesomeIcon icon="user-plus" />
                  </Button>
                </>,
              ]
            : [],
        }}
      />
    </div>
  )
}
