import React, {useEffect, useState} from 'react'
import {apiGet} from '../libs/apiLib'
import Busy from '../components/Busy'
import {DateTimeCell, MetricsCell, SystemsCell} from './TableCells'
import Table from '../components/Table'
import {getFunctionName} from '../libs/utilLib'
import {username} from '../libs/userLib'

const api = apiGet()

export default function NotificationsTable({cognitoUsername, className, user}) {
  const devices = Object.values(user.allDevices || user.devices)
  const columns = [
    {
      accessor: 'deviceName',
      Header: 'System',
      Cell: props => <SystemsCell value={props.devices[props.row.original.deviceId]} />,
      default: true,
    },
    {accessor: 'description', Header: 'Event', default: true},
    {accessor: 'severity', Header: 'Severity', default: true},
    {accessor: 'metrics', Header: 'Metrics', Cell: MetricsCell, default: true},
    {accessor: 'role', Header: 'As', default: false},
    {accessor: 'sent', Header: 'Delivery Timestamp', Cell: DateTimeCell, default: false},
    {accessor: 'type', Header: 'Delivery Method', default: false},
    {
      accessor: 'ts',
      Header: 'Timestamp',
      Cell: ({value}) => <DateTimeCell value={value * 1000} />,
      default: true,
    },
  ]

  const [data, setData] = useState([])
  useEffect(() => {
    ;(async () => {
      try {
        const result = await api.get(
          `/user${cognitoUsername ? '/' + cognitoUsername + '/notifications' : '/notifications'}`
        )
        setData(result.data)
        setState(HAVE_DATA)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e.message)
      }
    })()
  }, [cognitoUsername])

  const HAVE_DATA = 'HAVE_DATA',
    BUSY = 'BUSY'
  const [state, setState] = useState(BUSY)
  return (
    <div className={className}>
      {state === HAVE_DATA ? (
        <Table
          {...{
            cellInjections: {
              devices,
            },
            columns,
            heading: `Notifications — ${username(user)}`,
            storageKey: getFunctionName(),
            data: data,
          }}
        />
      ) : (
        <Busy />
      )}
    </div>
  )
}
