export function getQueryVariable(variable) {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1])
    }
  }
  // console.log('Query variable %s not found', variable)
}
export function getQueryObject() {
  const query = window.location.search.substring(1)
  if (!query.length) return {}
  const vars = query.split('&')
  return vars.reduce((a, q) => {
    const [key, value] = q.split('=')
    a[key] = decodeURIComponent(value)
    return a
  }, {})
}
