import React from 'react'

export default ({className = 'power-plug', error = false}) => {
  const id1 = String(Math.floor(Math.random() * 1000000000))
  const id2 = String(Math.floor(Math.random() * 1000000000))
  const id3 = String(Math.floor(Math.random() * 1000000000))
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
        <defs>
          <linearGradient
            id={id1}
            x1="-34.04"
            y1="518.17"
            x2="-30.74"
            y2="520.68"
            gradientTransform="matrix(3.55, 0, 0, -7.06, 212.32, 3698.47)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#345b82" />
            <stop offset="1" stopColor="#4b81a1" />
          </linearGradient>
          <linearGradient
            id={id2}
            x1="-42.67"
            y1="518.18"
            x2="-39.37"
            y2="520.69"
            gradientTransform="matrix(3.55, 0, 0, -7.06, 201.68, 3699.18)"
            href={`#${id1}`}
          />
          <linearGradient
            id={id3}
            x1="-69.05"
            y1="540.01"
            x2="-65.75"
            y2="542.52"
            gradientTransform="matrix(21.29, 0, 0, -19.52, 1511.26, 10647.88)"
            href={`#${id1}`}
          />
        </defs>
        <g>
          <path
            d="M102.52,26.72a6.89,6.89,0,0,0-13.77,0V47.38h13.77Z"
            fill={error ? 'white' : `url(#${id1})`}
          />
          <path
            d="M61.24,26.72a6.88,6.88,0,0,0-13.76,0V47.38H61.24Z"
            fill={error ? 'white' : `url(#${id2})`}
          />
          <g>
            <path
              d="M112.87,54.26H37.14a3.45,3.45,0,0,0-3.45,3.44h0v6.88A3.45,3.45,0,0,0,37.14,68h3.44v6.89a34.42,34.42,0,0,0,27.54,33.75V130H81.87V108.66a34.43,34.43,0,0,0,27.55-33.75V68h3.44a3.44,3.44,0,0,0,3.45-3.43h0V57.7A3.44,3.44,0,0,0,112.87,54.26Z"
              fill={error ? 'white' : `url(#${id3})`}
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
