import React, {useEffect, useState} from 'react'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import {username} from '../libs/userLib'
import './SystemPermissions.scss'

export default function SystemPermissions(props) {
  const [treeData, setTreeData] = useState({})

  useEffect(() => {
    if (props.user) {
      const accounts = props.user.allAccounts || props.user.accounts
      if (accounts) {
        const data = Object.values(accounts).map(a => {
          const node = {
            label: `Account: ${a.name}`,
            value: `a: ${a.id}`,
            className: 'account-node',
            tagClassName: 'account-node',
          }
          if (a.contacts) {
            let checked = true
            node.children = Object.values(a.contacts).map(user => {
              checked = !checked
              return {
                checked,
                label: `User: ${username(user)}`,
                value: `a:${a.id}u:${user.cognitoUsername}`,
                className: 'user-node',
                tagClassName: 'user-node',
                children: [
                  {
                    label: `Administrator: ${username(user)}`,
                    value: `a:${a.id}u:${user.cognitoUsername}:admin`,
                  },
                ],
              }
            })
          }
          return node
        })
        setTreeData(data)
      }
    }
  }, [props])
  return (
    <div>
      <h1
        style={{
          fontSize: '40pt',
          color: '#00000080',
          transform: 'rotate(-45deg)',
        }}
      >
        USERS AND ACCOUNTS TBD
      </h1>
      <DropdownTreeSelect
        className="system-permissions"
        data={treeData}
        texts={{noMatches: 'No Matches', placeholder: 'Search...'}}
        keepTreeOnSearch
      />
    </div>
  )
}
