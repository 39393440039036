import React, {useEffect, useMemo, useState} from 'react'
import {apiGet} from '../libs/apiLib'
import Table from '../components/Table'
import {DateTimeCell, EmailCell, PhoneCell} from '../components/TableCells'
import {getFunctionName, titleCase} from '../libs/utilLib'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import './Superusers.scss'
import AnchorButton from '../components/AnchorButton'
import ProfilePicture from '../components/ProfilePicture'
import {initials} from '../libs/userLib'
import EventDefinitionModal from '../components/EventDefinitionModal'
import {metricOnlyFields, modbusOnlyFields, units} from '../libs/eventsLib'
import Alarm from '../components/Bell'
import ExclamationPoint from '../components/ExclamationPoint'
import CheckMark from '../components/CheckMark'
import InfoIcon from '../components/InfoIcon'
import Superman from '../components/Superman'
import EventTypeIcon from '../components/EventTypeIcon'
import Busy from '../components/Busy'

// import SearchBar from '../components/SearchBar'

function EventDefinitionsPanel(props) {
  const [rowIndex, setRowIndex] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [modalData, setModalData] = useState(undefined)
  const [modalMode] = useState(props.modalMode)
  const [salesOrderSelections, setSalesOrderSelections] = useState(undefined)
  const [modbusDeviceTypeGroups, setModbusDeviceTypeGroups] = useState(undefined)
  const onEventDefinitionCreate = () => {
    setModalShow(true)
    setModalData(undefined)
  }
  useEffect(
    /* fetch sales orders */
    (mounted = true) => {
      if (modalMode === 'modbus' && !modbusDeviceTypeGroups) {
        const api = apiGet()
        Promise.all([api.get('/modbus_device_type_group')]).then(([{data: modbusDeviceTypeGroups}]) => {
          if (mounted) setModbusDeviceTypeGroups(modbusDeviceTypeGroups)
        })
      }
      return () => (mounted = false)
    },
    [modbusDeviceTypeGroups, modalMode]
  )

  /* fetch sales orders */
  useEffect(
    (mounted = true) => {
      if (modalMode === 'metric' && !salesOrderSelections) {
        const api = apiGet()
        Promise.all([api.get('/sales_order')]).then(([{data: salesOrderSelections}]) => {
          if (mounted) setSalesOrderSelections(salesOrderSelections)
        })
      }
      return () => (mounted = false)
    },
    [salesOrderSelections, modalMode]
  )

  const ValueCell = ({
    row: {
      original: {eventType},
    },
    value,
  }) => <span>{`${value} ${units[eventType] || ''}`}</span>
  const EditCell = ({row: {original, index}}) => {
    return (
      <button
        className="edit"
        onClick={() => {
          setModalShow(true)
          setModalData(original)
          setRowIndex(index)
        }}
      >
        Edit
      </button>
    )
  }
  const RemoveDefinitionCell = ({row: {index}}) => (
    <button className="remove-definition" onClick={() => props.onDelete(index)}>
      <FontAwesomeIcon icon={'trash'} />
    </button>
  )

  const eventDefinitionColumns = useMemo(() =>
    [
      {accessor: 'name', Header: 'Event Name', default: true},
      {accessor: 'description', Header: 'Description', default: true},
      {
        accessor: 'eventType',
        Header: 'When...',
        default: true,
        Cell: ({value}) => <EventTypeIcon eventType={value} />,
      },
      {accessor: 'registerAddress', Header: 'In Register...', default: true},
      {accessor: 'operator', Header: 'Is...', default: true},
      {accessor: 'operand', Header: 'This Value...', default: true, Cell: props => <ValueCell {...props} />},
      {accessor: 'shift', Header: 'Shifted By', default: true},
      {accessor: 'factor', Header: 'Multiplied By', default: true},
      {accessor: 'mask', Header: 'Masked By', default: true},
      {
        accessor: 'modbusDeviceTypeGroupSelections',
        Header: 'And These Modbus Profile(s)',
        default: true,
        Cell: ({value}) => (
          <div className="selected-modbus-event-type-groups">
            {value.map(v => (
              <span className="selected-modbus-event-type-group" key={v.value}>
                {v.label}
              </span>
            ))}
          </div>
        ),
      },
      {
        accessor: 'deviceSelections',
        Header: 'For These System(s)',
        default: true,
        Cell: ({
          value,
          row: {
            original: {isSystemGlobal},
          },
        }) => (
          <div className="selected-devices">
            {isSystemGlobal && <span className="is-system-global">ALL SYSTEMS</span>}
            {!isSystemGlobal &&
              value.map(({label}) => (
                <span className="selected-device" key={label}>
                  {label}
                </span>
              ))}
          </div>
        ),
      },
      {
        accessor: 'salesOrderSelections',
        Header: 'And These Sales Order(s)',
        default: true,
        Cell: ({value}) => (
          <div className="selected-sales-orders">
            {value.map(({value}) => (
              <span className="selected-sales-order" key={value}>
                {value}
              </span>
            ))}
          </div>
        ),
      },
      {
        accessor: 'severity',
        Header: 'Generate',
        default: true,
        Cell: ({value}) => {
          return (
            <div title={titleCase(value)} className="value-wrapper">
              {value === 'alarm' && <Alarm />}
              {value === 'warning' && <ExclamationPoint />}
              {value === 'recovery' && <CheckMark />}
              {value === 'info' && <InfoIcon />}
              <div>{titleCase(value)}</div>
            </div>
          )
        },
      },
      {accessor: 'createdByName', Header: 'Created By', default: false},
      {accessor: 'createdTimestamp', Header: 'Created', default: false, Cell: DateTimeCell},
      {accessor: 'lastModifiedByName', Header: 'Last Modified By', default: false},
      {accessor: 'lastModifiedDate', Header: 'Last Modified Date', default: false, Cell: DateTimeCell},
      {accessor: 'id', default: true, Cell: EditCell},
      {accessor: 'delete', default: true, Cell: RemoveDefinitionCell},
    ].filter(({accessor}) =>
      modalMode === 'modbus' ? !metricOnlyFields.includes(accessor) : !modbusOnlyFields.includes(accessor)
    )
  )

  if (!props.eventDefinitions.length) return <Busy />
  return (
    <div className={`event-definitions-panel ${props.className || ''}`}>
      <EventDefinitionModal
        className="event-definition-modal"
        show={modalShow}
        {...modalData}
        salesOrders={salesOrderSelections || []}
        modbusDeviceTypeGroups={modbusDeviceTypeGroups || []}
        {...{
          eventDefinitions: props.eventDefinitions,
          eventNames: props.eventNames,
          modalMode,
          onUpdate: props.onUpdate,
          onCreate: props.onCreate,
          onDelete: props.onDelete,
          rowIndex,
          setModalShow,
          setModalData,
        }}
      />
      <div className="button-bar">
        <button className="create" onClick={onEventDefinitionCreate}>
          <Superman />
          Create New Event
        </button>
      </div>
      <Table
        {...{
          cellInjections: {},
          columns: eventDefinitionColumns,
          storageKey: getFunctionName(),
          data: props.eventDefinitions || [],
          bannerItems: [],
          tableProps: {striped: false},
        }}
      />
    </div>
  )
}

function CompaniesPanel(props) {
  const columns = useMemo(
    () => [
      {
        accessor: 'profilePicture',
        Cell: props => {
          return (
            <ProfilePicture image={props.value} initials={initials(props.row.original)} alt={'profile'} />
          )
        },
        default: true,
      },
      {accessor: 'name', Header: 'Name', default: true},
      {accessor: 'userCount', Header: 'Total Users', default: true},
      {accessor: 'companyCode', Header: 'Company Code', default: true},
    ],
    []
  )

  const onClick = () => {}

  return (
    <div className={`companies-panel ${props.className || ''}`}>
      <div className="invite-new-superuser">
        <button onClick={onClick}>
          <Superman />
          ADD NEW COMPANY
        </button>
      </div>
      <Table
        {...{
          cellInjections: {onUpdate: props.onUpdate, api: apiGet()},
          columns,
          onUpdate: props.onUpdate,
          storageKey: getFunctionName(),
          data: props.companies,
        }}
      />
    </div>
  )
}

const SuperusersPanel = props => {
  const NameCell = ({
    row: {
      original: {given_name = '', family_name = ''},
    },
  }) => <div className="name-cell">{[given_name, family_name].join(' ')}</div>
  const RemoveCell = ({row: {index}, onUpdate}) => (
    <button className="remove-superuser" onClick={() => onUpdate(false, index, 'isSuperuser')}>
      <FontAwesomeIcon icon={'trash'} />
    </button>
  )

  const columns = useMemo(
    () => [
      {
        accessor: 'profilePicture',
        Cell: props => {
          return (
            <ProfilePicture image={props.value} initials={initials(props.row.original)} alt={'profile'} />
          )
        },
        default: true,
      },
      {accessor: 'family_name', Header: 'Name', Cell: NameCell, default: true},
      {accessor: 'email', Header: 'Email Address', Cell: EmailCell, default: true},
      {
        accessor: 'phone_number',
        Header: 'Phone Number',
        Cell: props => <PhoneCell {...props} disabled={true} />,
        default: true,
      },
      {
        accessor: 'cognitoUsername',
        Header: 'Remove',
        default: true,
        Cell: RemoveCell,
      },
    ],
    []
  )

  const onClick = () => {}
  return (
    <div className={`superusers-panel ${props.className || ''}`}>
      <div className="invite-new-superuser">
        <button onClick={onClick}>
          <Superman />
          INVITE NEW SUPERUSER
        </button>
      </div>
      <Table
        {...{
          cellInjections: {onUpdate: props.onUpdate, api: apiGet()},
          columns,
          onUpdate: props.onUpdate,
          storageKey: getFunctionName(),
          data: props.superusers,
        }}
      />
    </div>
  )
}

export default function SuperusersPage(props) {
  const localStorageKey = `superusers-page`
  const [superusers, setSuperUsers] = useState([])
  const [metricEventDefinitions, setMetricEventDefinitions] = useState([])
  const [modbusEventDefinitions, setModbusEventDefinitions] = useState([])
  const [eventNames, setEventNames] = useState({})
  const [companies, setCompanies] = useState([])
  const [tabs] = useState(['superusers', 'companies', 'metric-event-definitions', 'modbus-event-definitions'])
  const [tab, setTab] = useState(
    (tabs.includes(props.match.params.tab) && props.match.params.tab) ||
      localStorage.getItem(localStorageKey) ||
      tabs[0]
  )
  /* fetch metric definitions */
  useEffect(
    (mounted = true) => {
      if (metricEventDefinitions.length) return
      const api = apiGet()
      api.get('/event/metric/definition').then(({data: metricEventDefinitions}) => {
        if (mounted) {
          setMetricEventDefinitions(
            metricEventDefinitions.map(med => {
              return {...med, remove: med.id}
            })
          )
        }
      })
      return () => (mounted = false)
    },
    [metricEventDefinitions]
  )
  /* fetch modbus definitions */
  useEffect(
    (mounted = true) => {
      if (modbusEventDefinitions.length) return
      const api = apiGet()
      api.get('/event/modbus/definition').then(({data: modbusEventDefinitions}) => {
        if (mounted) {
          setModbusEventDefinitions(
            modbusEventDefinitions.map(med => {
              return {...med, remove: med.id}
            })
          )
        }
      })
      return () => (mounted = false)
    },
    [modbusEventDefinitions]
  )
  // fetch companies
  useEffect(() => {
    let mounted = true

    if (companies.length) return
    const api = apiGet()
    api.get(`/company`).then(({data: superusers}) => {
      if (mounted) setCompanies(superusers)
    })
    return () => (mounted = false)
  }, [companies])

  // fetch superusers
  useEffect(() => {
    let mounted = true
    const api = apiGet()
    if (superusers.length) return
    api.get(`/user?isSuperuser`).then(({data}) => {
      if (!mounted) return
      setSuperUsers(data)
    })
    return () => (mounted = false)
  }, [superusers])

  /* combine event names into one list so that names are globally unique */
  useEffect(() => {
    setEventNames(
      [...modbusEventDefinitions, ...metricEventDefinitions]
        .map(e => e.name.toLowerCase())
        .reduce((acc, key) => {
          acc[key] = true
          return acc
        }, {})
    )
  }, [metricEventDefinitions, modbusEventDefinitions])

  const onSuperuserUpdate = async (value, index, id) => {
    const {cognitoUsername} = superusers[index]
    superusers.splice(index, 1)
    setSuperUsers([...superusers])
    const api = apiGet()
    await api.put(`/user/${cognitoUsername}`, {[id]: value})
  }

  const onCompanyUpdate = async (value, index, id) => {
    const {id: companyId} = companies[index]
    const api = apiGet()
    await api.put(`/companies/${companyId}`, {[id]: value})
    setCompanies(companies.filter(row => row.id !== id))
  }

  const onMetricEventDefinitionCreated = metricEventDefinition => {
    setMetricEventDefinitions([metricEventDefinition, ...metricEventDefinitions])
  }
  const onMetricEventDefinitionUpdated = (row, rowIndex) => {
    metricEventDefinitions[rowIndex] = row
    setMetricEventDefinitions([...metricEventDefinitions])
  }
  const onMetricEventDefinitionDeleted = async index => {
    const api = apiGet()
    const id = metricEventDefinitions[index].id
    const newEventDefinitions = metricEventDefinitions.filter(med => med.id !== id)
    setMetricEventDefinitions(newEventDefinitions)
    api.delete(`/event/metric/definition/${metricEventDefinitions[index].id}`)
  }
  const onModbusEventDefinitionCreated = modbusEventDefinition => {
    setModbusEventDefinitions([modbusEventDefinition, ...modbusEventDefinitions])
  }
  const onModbusEventDefinitionUpdated = (row, rowIndex) => {
    modbusEventDefinitions[rowIndex] = row
    setModbusEventDefinitions([...modbusEventDefinitions])
  }
  const onModbusEventDefinitionDeleted = async index => {
    const api = apiGet()
    const id = modbusEventDefinitions[index].id
    const newEventDefinitions = modbusEventDefinitions.filter(med => med.id !== id)
    setModbusEventDefinitions(newEventDefinitions)
    api.delete(`/event/modbus/definition/${modbusEventDefinitions[index].id}`)
  }
  const onTabClick = tab => {
    setTab(tab)
    localStorage.setItem(localStorageKey, tab)
    window.history.replaceState(null, null, `/superusers/${tab}`)
  }
  try {
    return (
      <div className="superusers-page">
        <div className="superusers-page-banner">
          <Superman />
          <div className="header-title">{"Superusers' Powers Page"}</div>
        </div>
        <div className="superusers-page-button-bar">
          <AnchorButton
            className={tab === 'superusers' && 'is-active'}
            disabled={tab === 'superusers'}
            onClick={() => onTabClick('superusers')}
          >
            Superusers
          </AnchorButton>
          <AnchorButton
            className={tab === 'companies' && 'is-active'}
            disabled={tab === 'companies'}
            onClick={() => onTabClick('companies')}
          >
            All Companies
          </AnchorButton>
          <AnchorButton
            className={tab === 'metricEventDefinitions' && 'is-active'}
            disabled={tab === 'metricEventDefinitions'}
            onClick={() => onTabClick('metricEventDefinitions')}
          >
            Metric Event Prompts
          </AnchorButton>
          <AnchorButton
            className={tab === 'modbusEventDefinitions' && 'is-active'}
            disabled={tab === 'modbusMetricEventDefinitions'}
            onClick={() => onTabClick('modbusEventDefinitions')}
          >
            Modbus Event Prompts
          </AnchorButton>
        </div>
        <CompaniesPanel
          className={tab === 'companies' ? 'selected-panel' : ''}
          onUpdate={onCompanyUpdate}
          companies={companies}
        />
        <SuperusersPanel
          className={tab === 'superusers' ? 'selected-panel' : ''}
          superusers={superusers}
          onUpdate={onSuperuserUpdate}
        />
        <EventDefinitionsPanel
          modalMode="metric"
          eventNames={eventNames}
          className={tab === 'metricEventDefinitions' ? 'selected-panel' : ''}
          eventDefinitions={metricEventDefinitions}
          onCreate={onMetricEventDefinitionCreated}
          onDelete={onMetricEventDefinitionDeleted}
          onUpdate={onMetricEventDefinitionUpdated}
        />
        <EventDefinitionsPanel
          modalMode="modbus"
          className={tab === 'modbusEventDefinitions' ? 'selected-panel' : ''}
          eventNames={eventNames}
          eventDefinitions={modbusEventDefinitions}
          onCreate={onModbusEventDefinitionCreated}
          onDelete={onModbusEventDefinitionDeleted}
          onUpdate={onModbusEventDefinitionUpdated}
        />
      </div>
    )
  } catch (e) {
    return <span>{e.message}</span>
  }
}
