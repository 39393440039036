import React, {useState} from 'react'
import {formatPhoneNumber, validateEmail} from '../libs/validateLib'
import {useFormFields} from '../libs/formLib'
import {Alert, Col, Form, FormCheck, FormControl, FormGroup, FormLabel, Modal} from 'react-bootstrap'
import Select from 'react-select'
import LoaderButton from './LoaderButton'
import './NewUserModal.scss'
import {api} from '../libs/apiLib'

export default props => {
  const joinType = props.joinType
  const administeredAccounts = Object.values(props.user.allAccounts || props.user.accounts).filter(
    a => props.user.isSuperuser || a.isAdmin
  )
  const administeredAccountDevices = [].concat(...administeredAccounts.map(a => Object.values(a.devices)))
  const administeredNonAccountDevices =
    joinType === 'devices'
      ? Object.values(props.user.allDevices || props.user.devices).filter(
          d => props.user.isSuperuser || d.isAdmin
        )
      : []
  const administeredDevices = [...new Set([...administeredNonAccountDevices, ...administeredAccountDevices])]
  const [errors, setErrors] = useState({})
  const [response, setResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const validator = fields => {
    const errors = {}
    if (!fields.given_name) errors.given_name = 'required'
    if (!fields.family_name) errors.family_name = 'required'
    if (fields.phone_number && fields.phone_number.length < 12) errors.phone_number = 'incomplete'
    else if (!fields.phone_number.length) errors.phone_number = 'required'
    if (fields.email && fields.email.length) validateEmail(fields.email, errors)
    else errors.email = 'required'
    if (!props.user.isSuperuser) {
      if (!fields.devices.length && !fields.accounts.length)
        errors.devices = errors.accounts = 'At least one device or account association required'
    } else if (joinType === 'device') {
      if (administeredDevices.length > 1 && !fields.devices.length)
        errors.devices = 'At least one device association required'
    } else if (joinType === 'account') {
      if (administeredAccounts.length > 1 && !fields.accounts.length)
        errors.accounts = 'At least one account association required'
    }
    setErrors(errors)
  }
  const initialFields = {
    email: '',
    given_name: '',
    family_name: '',
    isSuperuser: false,
    phone_number: '',
    accounts: [],
    devices: [],
    isAccountAdmin: false,
    isDeviceAdmin: false,
  }
  const [fields, handleFieldChange] = useFormFields(initialFields, validator)

  const handlePhoneNumberChange = e => {
    const phone_number = fields.phone_number
    const syntheticEvent = {
      target: {
        id: e.target.id,
        type: e.target.type,
      },
    }
    const backspace = e.target.value.length === formatPhoneNumber(phone_number).length - 1
    if (backspace)
      syntheticEvent.target.value =
        phone_number.length === 3 ? '' : phone_number.substring(0, phone_number.length - 1)
    else {
      const nationalNumber = e.target.value.replace(/[^0-9]/g, '').substring(0, 10)
      syntheticEvent.target.value = nationalNumber.length
        ? '+1' + e.target.value.replace(/[^0-9]/g, '').substring(0, 10)
        : ''
    }
    handleFieldChange(syntheticEvent)
  }

  function handleAccountsChange(selectedOptions = []) {
    const selectionsMap = {}

    /* when user selects both a contact and admin role for the same account, discard the older choice */
    for (const selection of selectedOptions) selectionsMap[selection.value.split('-')[0]] = selection

    handleFieldChange({target: {id: 'accounts', value: Object.values(selectionsMap), type: 'react-select'}})
  }

  function handleDevicesChange(selectedOptions = []) {
    const selectionsMap = {}

    /* when user selects both a contact and admin role for the same device, discard the older choice */
    for (const selection of selectedOptions) selectionsMap[selection.value.split('-')[0]] = selection

    handleFieldChange({target: {id: 'devices', value: Object.values(selectionsMap), type: 'react-select'}})
  }

  async function handleSubmit(event) {
    event.preventDefault()
    setErrors({})
    setResponse(false)
    setIsLoading(true)
    try {
      const accountMemberships =
        administeredAccounts.length === 1
          ? [{name: administeredAccounts[0].name, id: administeredAccounts[0].id, isAdmin: fields.isAdmin}]
          : fields.accounts.map(({name, value}) => {
              const [id, role] = value.split('-')
              return {name, id, isAdmin: role === 'Administrator'}
            })
      const deviceMemberships =
        administeredDevices.length === 1
          ? [
              {
                name: administeredDevices[0].name,
                id: administeredDevices[0].id,
                isAdmin: fields.isDeviceAdmin,
              },
            ]
          : fields.devices.map(({name, value}) => {
              const [id, role] = value.split('-')
              return {name, id, isAdmin: role === 'Administrator'}
            })
      delete fields.isAccountAdmin
      delete fields.isDeviceAdmin
      const {sendInvite, isSuperuser, email, given_name, family_name} = fields
      await api.post('/user', {...fields, accounts: accountMemberships, devices: deviceMemberships})
      const messages = []
      messages.push(
        `New ${
          isSuperuser ? 'superuser' : 'user'
        } '${given_name} ${family_name}' with email '${email}' created.`
      )
      messages.push(`${sendInvite ? 'Invitation' : 'No invitation'} email with temporary password sent.`)
      if (joinType === 'account') delete fields.devices
      if (props.user.isSuperuser || joinType === 'account')
        messages.push(
          `Account memberships${accountMemberships.length > 1 ? 's' : ''}: ${
            accountMemberships.length
              ? accountMemberships
                  .map(({name, isAdmin}) => {
                    return name + (isAdmin ? ' (Administrator)' : ' (Contact)')
                  })
                  .join(', ')
              : 'None'
          }`
        )
      if (joinType === 'device') delete fields.accounts
      if (props.user.isSuperuser || joinType === 'device')
        messages.push(
          `Device membership${deviceMemberships.length > 1 ? 's' : ''}: ${
            deviceMemberships.length
              ? deviceMemberships
                  .map(({name, isAdmin}) => {
                    return name + (isAdmin ? ' (Administrator)' : ' (Contact)')
                  })
                  .join(', ')
              : 'None'
          }`
        )
      setResponse({
        type: 'info',
        messages,
      })
    } catch (e) {
      let message = e.message
      // look for a more descriptive message
      try {
        if (e.response.statusText) message = e.response.statusText
        if (e.response.error) message = e.response.error
      } catch (e) {}
      setResponse({messages: [message], type: 'danger'})
    } finally {
      setIsLoading(false)
    }
  }

  let titleText
  if (props.user.isSuperuser) titleText = 'Create User'
  else
    titleText =
      joinType === 'device'
        ? (titleText = `${
            administeredDevices.length === 1 ? `${administeredDevices[0].name} — ` : ''
          }Create System User`)
        : (titleText = `${
            administeredAccounts.length === 1 ? `${administeredAccounts[0].name} — ` : ''
          }Create Account User`)

  if (response)
    return (
      <>
        <Modal className="new-user-form" show={props.show} backdrop="static">
          <Modal.Header>
            <Modal.Title>{titleText}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant={response.type}>
              {response.messages.map(message => (
                <p key={message}>{message}</p>
              ))}
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <LoaderButton
              onClick={() => {
                if (response.type === 'info') {
                  props.setModalShow(false)
                  /* reset values */
                  handleFieldChange({target: {type: 'reset'}})
                }
                setResponse(false)
              }}
            >
              Close
            </LoaderButton>
          </Modal.Footer>
        </Modal>
      </>
    )
  return (
    <>
      <Modal
        className="new-user-form"
        show={props.show}
        backdrop="static"
        onHide={() => props.setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{titleText}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Row>
              <Col>
                <FormGroup controlId="given_name">
                  <FormLabel>First Name</FormLabel>
                  <FormControl
                    type="input"
                    value={fields.given_name}
                    autoFocus
                    onChange={handleFieldChange}
                    autoComplete="given_name"
                  />
                  <Form.Text>
                    {errors.given_name && <span className="alert-danger">{errors.given_name}</span>}
                  </Form.Text>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup controlId="family_name">
                  <FormLabel>Last Name</FormLabel>
                  <FormControl
                    type="input"
                    value={fields.family_name}
                    onChange={handleFieldChange}
                    autoComplete="family_name"
                  />
                  <Form.Text>
                    {errors.family_name && <span className="alert-danger">{errors.family_name}</span>}
                  </Form.Text>
                </FormGroup>
              </Col>
            </Form.Row>
            <Form.Row className="email">
              <Col>
                <Form.Group controlId="email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" onChange={handleFieldChange} autoComplete="email" />
                  <Form.Text>
                    {!errors.email && "We'll never share this email address with anyone."}
                    {errors.email && <span className="alert-danger">{errors.email}</span>}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <FormGroup controlId="phone_number">
                  <FormLabel>Mobile Phone</FormLabel>
                  <FormControl
                    type="tel"
                    value={formatPhoneNumber(fields.phone_number)}
                    onChange={handlePhoneNumberChange}
                    autoComplete="tel-national"
                  />
                  <Form.Text>
                    {errors.phone_number && <span className="alert-danger">{errors.phone_number}</span>}
                    {!errors.phone_number && "We'll never share this number with anyone."}
                  </Form.Text>
                </FormGroup>
              </Col>
            </Form.Row>
            <Form.Row>
              {joinType === 'account' && administeredAccounts.length === 1 && (
                <Form.Group controlId="isAccountAdmin">
                  <FormCheck
                    inline
                    type="checkbox"
                    checked={fields.isAccountAdmin}
                    onChange={handleFieldChange}
                    className={fields.isAccountAdmin && 'is-checked'}
                  />
                  <FormCheck.Label>Grant user account administrator privileges?</FormCheck.Label>
                </Form.Group>
              )}
              {joinType === 'account' && administeredAccounts.length > 1 && (
                <FormGroup className="accounts" controlId="accounts">
                  <FormLabel>Account Memberships</FormLabel>
                  <Select
                    classNamePrefix="account-select"
                    inputId="accounts"
                    isMulti
                    value={fields.accounts}
                    onChange={handleAccountsChange}
                    options={Object.values(administeredAccounts).map(({id, name}) => {
                      return {
                        label: name,
                        options: [
                          {value: `${id}-Contact`, label: `${name} (Contact)`, name},
                          {value: `${id}-Administrator`, label: `${name} (Administrator)`, name},
                        ],
                      }
                    })}
                  />
                  <Form.Text>
                    {errors.accounts && <span className="alert-danger">{errors.accounts}</span>}
                  </Form.Text>
                </FormGroup>
              )}
            </Form.Row>
            <Form.Row>
              {joinType === 'device' && administeredDevices.length > 1 && (
                <FormGroup className="devices" controlId="devices">
                  <FormLabel>Systems</FormLabel>
                  <Select
                    classNamePrefix="device-select"
                    inputId="devices"
                    isMulti
                    value={fields.devices}
                    onChange={handleDevicesChange}
                    options={Object.values(administeredDevices).map(({id, name}) => {
                      return {
                        label: name,
                        options: [
                          {value: `${id}-Contact`, label: `${name} (Contact)`, name},
                          {value: `${id}-Administrator`, label: `${name} (Administrator)`, name},
                        ],
                      }
                    })}
                  />
                  <Form.Text>
                    {errors.devices && <span className="alert-danger">{errors.devices}</span>}
                  </Form.Text>
                </FormGroup>
              )}
              {joinType === 'device' && administeredDevices.length === 1 && (
                <Form.Group controlId="isDeviceAdmin">
                  <FormCheck
                    inline
                    type="checkbox"
                    checked={fields.isDeviceAdmin}
                    onChange={handleFieldChange}
                    className={fields.isDeviceAdmin && 'is-checked'}
                  />
                  <FormCheck.Label>Grant user administrator privileges for systems?</FormCheck.Label>
                </Form.Group>
              )}
            </Form.Row>
            {props.user.isSuperuser && (
              <Form.Row>
                <Form.Group controlId="isSuperuser">
                  <FormCheck
                    inline
                    type="checkbox"
                    checked={fields.isSuperuser}
                    onChange={handleFieldChange}
                    className={fields.isSuperuser && 'is-checked'}
                  />
                  <FormCheck.Label>Grant user superuser privileges?</FormCheck.Label>
                </Form.Group>
              </Form.Row>
            )}
            <Form.Row>
              <Form.Group controlId="sendInvite">
                <FormCheck
                  inline
                  type="checkbox"
                  checked={fields.sendInvite}
                  onChange={handleFieldChange}
                  className={fields.sendInvite && 'is-checked'}
                />
                <FormCheck.Label>Send invitation with temporary password?</FormCheck.Label>
              </Form.Group>
            </Form.Row>
            {response && <Alert variant={'danger'}>{response}</Alert>}
          </Modal.Body>
          <Modal.Footer>
            <LoaderButton
              block
              type="submit"
              isLoading={isLoading}
              disabled={
                Object.keys(errors).length > 0 ||
                !fields.email ||
                !fields.phone_number ||
                !fields.given_name ||
                !fields.family_name
              }
            >
              {' '}
              Submit
            </LoaderButton>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
