import moment from 'moment'

/**
 *
 * @returns {string}
 */
export const getFunctionName = () => {
  const {stack} = new Error()
  return stack.split('\n')[2].split(/ +/)[2]
}
export const titleCase = str => str.replace(/\b\S/g, t => t.toUpperCase())
export const fromCamelToSpacedTitleCase = str => titleCase(str.replace(/([A-Z])/g, ' $1'))

/**
 *
 * @param voltage
 * @returns {number}
 */
export const simpleStateOfChargeFraction = voltage => {
  const batteryMinimumVoltage = 10.5
  const batteryMaximumVoltage = 12.8
  const fraction = (voltage - batteryMinimumVoltage) / (batteryMaximumVoltage - batteryMinimumVoltage)
  return Math.max(0, Math.min(1, fraction))
}
/**
 *
 * @param str1
 * @param str2
 * @param nChars
 * @returns {number}
 */
export const compareStrings = (str1, str2, nChars) => {
  const nGrams = (str, nChars) => {
    const numGrams = str.length - nChars + 1
    if (numGrams < 1) return []
    const grams = []
    for (let i = 0; i < numGrams; i++) grams.push(str.substring(i, i + nChars))
    return grams
  }

  const wordLetterPairs = (str, nChars) => {
    const words = str.trim().split(/[-_=!*.,\s]+/),
      allPairs = []
    for (let w = 0; w < words.length; w++) {
      const pairsInWord = nGrams(words[w], nChars)
      for (let p = 0; p < pairsInWord.length; p++) {
        allPairs.push(pairsInWord[p])
      }
    }
    return allPairs
  }

  const pairs1 = wordLetterPairs(str1.toLowerCase(), nChars)
  const pairs2 = wordLetterPairs(str2.toLowerCase(), nChars)
  let intersection = 0
  const union = pairs1.length + pairs2.length
  for (let i = 0; i < pairs1.length; i++) {
    const pair1 = pairs1[i]
    for (let j = 0; j < pairs2.length; j++) {
      const pair2 = pairs2[j]
      if (pair1 === pair2) {
        intersection++
        pairs2.splice(j, 1)
        break
      }
    }
  }
  return (2.0 * intersection) / union
}
/**
 *
 * @param value
 * @param precision
 * @returns {number}
 */
export const precision = (value, precision = 3) => {
  const f = Math.pow(10, precision)
  return Math.round(value * f) / f
}
/**
 *
 * @param metricsTimestamp
 * @param reportingIntervalSeconds
 * @returns {boolean}
 */
export const isConnected = (metricsTimestamp, reportingIntervalSeconds) => {
  if (typeof metricsTimestamp !== 'string' || !reportingIntervalSeconds) return false
  const durationSeconds = moment.duration(moment().diff(moment(metricsTimestamp))).asSeconds()
  const reportingLatencySeconds = 20 * 60
  return durationSeconds < reportingIntervalSeconds + reportingLatencySeconds
}
export const escapeRegex = string => string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
