import React from 'react'
import {Redirect, Route} from 'react-router'
import {getQueryVariable} from '../libs/queryLib'
import Busy from './Busy'

export default function UnauthenticatedRoute({
  component: Component,
  isAuthenticated,
  isBooting,
  appProps,
  ...rest
}) {
  // const redirect = getQueryVariable('redirect') || '/'
  // console.log(`Unauthenticated route for ${Component.name} redirect: '${redirect}' user: ${JSON.stringify(!!appProps.user)}`)
  if (isBooting || (isAuthenticated && !appProps.user))
    return (
      <div className="unauthenticated-route container">
        <Busy />
      </div>
    )
  return (
    <Route
      {...rest}
      render={props =>
        !appProps.user ? (
          <Component {...props} {...appProps} />
        ) : (
          <Redirect to={getQueryVariable('redirect') || '/'} />
        )
      }
    />
  )
}
