import React from 'react'

export default ({className = 'bolt', error = false}) => {
  const id = String(Math.random())
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
        <defs>
          <linearGradient
            id={id}
            x1="-234.87"
            y1="545.19"
            x2="-231.65"
            y2="547.64"
            gradientTransform="matrix(20.28, 0, 0, -29, 4812.92, 15914)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#345b82" />
            <stop offset="1" stopColor="#4b81a1" />
          </linearGradient>
        </defs>
        <g>
          <path
            d="M69.5,130h-11a5.68,5.68,0,0,1-5.57-5.8,5.29,5.29,0,0,1,.07-.8L60.59,86H42a5.4,5.4,0,0,1-4.51-8.47l38.5-55A5.51,5.51,0,0,1,80.5,20h11A5.7,5.7,0,0,1,97,26.59L89.41,64H108a5.4,5.4,0,0,1,4.51,8.47l-38.5,55A5.52,5.52,0,0,1,69.5,130Zm-4.29-11h1.43l30.8-44H82.68a5.55,5.55,0,0,1-5.49-5.61,6.64,6.64,0,0,1,.1-1L84.77,31H83.34L52.54,75H67.28a5.54,5.54,0,0,1,5.49,5.61,4.75,4.75,0,0,1-.1,1Z"
            fill={error ? 'white' : `url(#${id})`}
          />
        </g>
      </svg>
    </div>
  )
}
