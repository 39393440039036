import React, {useMemo, useState} from 'react'
import './Table.scss'
import {Button, Dropdown, DropdownButton, Table} from 'react-bootstrap'
import {usePagination, useSortBy, useTable} from 'react-table'
import {PropTypes} from 'prop-types'
import TableColumnChooser from '../components/TableColumnChooser'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

function PageControls({
  gotoPage,
  nextPage,
  previousPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
  pageSize,
  setPageSize,
}) {
  const [gotoPageValue, setGoToPageValue] = useState(Number(pageIndex + 1).toString())
  return (
    <div className="page-controls">
      <div>
        <Button onClick={() => gotoPage(0)} size="sm" disabled={!canPreviousPage} variant="outline-primary">
          <FontAwesomeIcon icon="fast-backward" />
        </Button>
        <Button
          onClick={() => previousPage()}
          size="sm"
          disabled={!canPreviousPage}
          variant="outline-primary"
        >
          <FontAwesomeIcon icon="step-backward" />
        </Button>
        <Button onClick={() => nextPage()} size="sm" disabled={!canNextPage} variant="outline-primary">
          <FontAwesomeIcon icon="step-forward" />
        </Button>
        <Button
          onClick={() => gotoPage(pageCount - 1)}
          size="sm"
          disabled={!canNextPage}
          variant="outline-primary"
        >
          <FontAwesomeIcon icon="fast-forward" />
        </Button>
      </div>
      <label>
        Go To Page
        <input
          type="text"
          value={gotoPageValue}
          onKeyDown={({key, target: {value}}) => {
            if (key === 'Enter' && value.length) gotoPage(parseInt(gotoPageValue) - 1)
          }}
          onChange={({target: {value}}) => {
            value = value.replace(/[^0-9]/, '')
            if (!value.length) setGoToPageValue('')
            else {
              value = Math.max(1, Math.min(pageCount, parseInt(value)))
              setGoToPageValue(String(value))
            }
          }}
        />
      </label>
      <DropdownButton
        size="sm"
        value={pageSize}
        title={`Show ${pageSize} Rows`}
        onSelect={pageSize => setPageSize(Number(pageSize))}
        variant="outline-primary"
      >
        {[10, 20, 30, 40, 50].map(pageSize => (
          <Dropdown.Item key={pageSize} eventKey={pageSize}>
            Show {pageSize} Rows
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  )
}

GenericTable.propTypes = {
  bannerItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.func])),
  cellInjections: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  heading: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string),
  onUpdate: PropTypes.func,
  sortBy: PropTypes.object,
  storageKey: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}
export default function GenericTable(props) {
  const withPagination = !!props.withPagination
  const columns = useMemo(() => props.columns, [props.columns])
  const data = useMemo(() => props.data, [props.data])
  const [info, setMessages] = useState([])
  const [warnings, setWarnings] = useState([])
  const [errors, setErrors] = useState([])
  const hiddenColumnsStorageKey = `${props.storageKey}-hiddenColumns`
  const pageSizeStorageKey = `${props.storageKey}-pageSize`
  const pageIndexStorageKey = `${props.storageKey}-pageIndex`
  const hiddenColumns = localStorage.getItem(hiddenColumnsStorageKey)
    ? JSON.parse(localStorage.getItem(hiddenColumnsStorageKey))
    : [].concat(
        ...columns.map(c => {
          return c.columns
            ? c.columns.filter(c => !c.default).map(c => c.accessor)
            : c.default
            ? []
            : [c.accessor]
        })
      )
  const initialState = {
    hiddenColumns,
    pageSize: parseInt(localStorage[pageSizeStorageKey]) || 10,
    pageIndex: parseInt(localStorage[pageIndexStorageKey]) || 0,
  }
  if (props.sortBy) initialState.sortBy = props.sortBy
  const displayMessage = message => setMessages([...info, message])
  const displayError = message => setErrors([...errors, message])
  const displayWarning = message => setWarnings([...warnings, message])
  const {
    getTableProps,
    setHiddenColumns,
    getTableBodyProps,
    headerGroups,
    allColumns,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageCount,
    // gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {
      pageIndex,
      pageSize,
      // sortBy,
      // groupBy,
      // expanded,
      // filters,
      // selectedRowIds,
    },
  } = useTable(
    {
      columns,
      data,
      initialState,
      ...props.cellInjections,
      displayMessage,
      displayError,
      displayWarning,
    },
    useSortBy,
    usePagination
  )

  localStorage[pageSizeStorageKey] = pageSize
  localStorage[pageIndexStorageKey] = pageIndex
  return (
    <div className="generic-table">
      {props.heading && (
        <div className="table-heading">
          <h2 className="jumbotron">{props.heading}</h2>
          <div className="messages">
            {info &&
              info.map((m, key) => (
                <div key={key} className="info">
                  {m}
                </div>
              ))}
            {warnings &&
              warnings.map((m, key) => (
                <div key={key} className="warning">
                  {m}
                </div>
              ))}
            {errors &&
              errors.map((m, key) => (
                <div key={key} className="error">
                  {m}
                </div>
              ))}
          </div>
        </div>
      )}
      <div className="banner-items">
        {props.bannerItems && props.bannerItems.map((item, i) => <div key={i}>{item}</div>)}
        <TableColumnChooser
          {...{
            allColumns,
            hiddenColumns,
            localStorageKey: hiddenColumnsStorageKey,
            setHiddenColumns,
          }}
        />
      </div>
      <Table striped bordered responsive {...props.tableProps} {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr key={Math.random()} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={Math.random()} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(withPagination ? page : rows).map(row => {
            prepareRow(row)
            return (
              <tr key={Math.random()} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td key={Math.random()} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      {withPagination && (
        <div className="footer">
          <PageControls
            {...{
              //gotoPage: pageIndex => pageIndex >= 0 && pageIndex < pageCount && gotoPage(pageIndex),
              nextPage,
              previousPage,
              canPreviousPage,
              canNextPage,
              pageCount,
              pageIndex,
              pageSize,
              setPageSize,
              storageKey: props.storageKey + '-pageControls',
            }}
          />
          <div className="page-number">
            <span>
              {'Page '}
              <strong>{pageIndex + 1}</strong>
              {' of '}
              <strong>{pageCount || 1}</strong>
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
