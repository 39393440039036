import React, {useState} from 'react'
import {useFormFields} from '../libs/formLib'
import {Alert, Col, Form, FormGroup, FormLabel, Modal} from 'react-bootstrap'
import ReactSelect from 'react-select'
import LoaderButton from './LoaderButton'
import './MetricsDownloadModal.scss'
import {api} from '../libs/apiLib'
import moment from 'moment'
import {download, objectArrayToCSV} from '../libs/downloadLib'

const attributeOptions = {
  ambientTemp: 'Ambient Temperature',
  arrayCurrent: 'Array Current',
  arrayVoltage: 'Array Voltage',
  batteryCurrent: 'Battery Current',
  batteryVoltage: 'Battery Voltage',
  loadCurrent: 'Load Current',
  loadVoltage: 'Load Voltage',
}

export default props => {
  const [errors, setErrors] = useState({})
  const [response, setResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const devices = props.user.allDevices || props.user.devices
  const [attributesWidth] = useState(() => {
    let width = 0
    Object.keys(attributeOptions).forEach(key => (width = Math.max(width, attributeOptions[key].length)))
    return `${width * 7 + 120}px`
  })
  const [devicesWidth] = useState(() => {
    let width = 0
    Object.keys(devices).forEach(id => (width = Math.max(width, devices[id].name.length)))
    return `${width * 7 + 120}px`
  })
  const validator = fields => {
    const errors = {}
    if (!fields.devices.length) errors.devices = 'Select at least one system'
    if (!fields.attributes.length) errors.attributes = 'Select at least one metric type'
    if (!fields.startTimestamp) errors.startTimestamp = 'Required'
    if (!fields.endTimestamp) errors.endTimestamp = 'Required'
    setErrors(errors)
    return errors
  }
  const toDeviceSelections = devices =>
    devices
      .map(device => {
        return {
          value: device.id,
          label: device.name,
        }
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1))
  const toAttributeSelections = attributes =>
    attributes
      .map(attribute => {
        return {
          value: attribute,
          label: attributeOptions[attribute],
        }
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1))
  const [initialFields] = useState(() => {
    const storedFields = localStorage.getItem('metrics-download-modal-fields')

    return storedFields
      ? JSON.parse(storedFields)
      : {
          devices: [],
          attributes: [],
          startTimestamp: undefined,
          endTimestamp: undefined,
        }
  })
  const [fields, handleFieldChange] = useFormFields(initialFields, validator)

  async function handleSubmit(event) {
    event.preventDefault()
    setErrors({})
    setResponse(false)
    setIsLoading(true)
    try {
      localStorage.setItem('metrics-download-modal-fields', JSON.stringify(fields))
      const params = {...fields}
      params.endMillis = moment(params.endTimestamp).endOf('day').valueOf()
      delete params.endTimestamp
      params.startMillis = moment(params.startTimestamp).valueOf()
      delete params.startTimestamp
      const {data} = await api.post('/device/metrics', params)
      if (data.length) {
        const csvData = objectArrayToCSV(data)
        download(csvData, 'text/json', 'metrics.csv')
      } else {
        setResponse({messages: ['No data found for selected criteria'], type: 'danger'})
      }
    } catch (e) {
      let message = e.message
      // look for a more descriptive message
      try {
        if (e.response.statusText) message = e.response.statusText
        if (e.response.error) message = e.response.error
      } catch (e) {}
      setResponse({messages: [message], type: 'danger'})
    } finally {
      setIsLoading(false)
    }
  }

  function handleDevicesChange(selectedOptions = []) {
    selectedOptions = selectedOptions || []

    handleFieldChange({
      target: {id: 'devices', value: selectedOptions.map(o => o.value), type: 'react-select'},
    })
  }

  function handleAttributesChange(selectedOptions = []) {
    selectedOptions = selectedOptions || []
    handleFieldChange({
      target: {id: 'attributes', value: selectedOptions.map(o => o.value), type: 'react-select'},
    })
  }

  let titleText = 'Download Metrics'
  if (response)
    return (
      <>
        <Modal className="metrics-download-form" show={props.show} backdrop="static">
          <Modal.Header>
            <Modal.Title>{titleText}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant={response.type}>
              {response.messages.map(message => (
                <p key={message}>{message}</p>
              ))}
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <LoaderButton
              onClick={() => {
                if (response.type === 'info') {
                  props.setModalShow(false)
                  /* reset values */
                  handleFieldChange({target: {type: 'reset'}})
                }
                setResponse(false)
              }}
            >
              Close
            </LoaderButton>
          </Modal.Footer>
        </Modal>
      </>
    )
  return (
    <>
      <Modal
        className="metrics-download-form"
        show={props.show}
        backdrop="static"
        onHide={() => props.setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{titleText}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Row>
              <Col>
                <FormGroup className="devices" controlId="devices">
                  <FormLabel>Systems</FormLabel>
                  <ReactSelect
                    classNamePrefix="device-select"
                    inputId="devices"
                    isMulti
                    style={{width: devicesWidth}}
                    onChange={handleDevicesChange}
                    value={toDeviceSelections(fields.devices.map(id => devices[id]))}
                    options={Object.values(devices).map(({id, name}) => {
                      return {
                        label: name,
                        value: id,
                      }
                    })}
                  />
                  <Form.Text>
                    {errors.devices && <span className="alert-danger">{errors.devices}</span>}
                  </Form.Text>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="attributes" controlId="attributes">
                  <FormLabel>Attributes</FormLabel>
                  <div style={{width: attributesWidth}}>
                    <ReactSelect
                      classNamePrefix="attribute-select"
                      inputId="attributes"
                      isMulti
                      style={{width: attributesWidth}}
                      onChange={handleAttributesChange}
                      value={toAttributeSelections(fields.attributes)}
                      options={Object.keys(attributeOptions).map(key => {
                        return {label: attributeOptions[key], value: key}
                      })}
                    />
                  </div>
                  <Form.Text>
                    {errors.attributes && <span className="alert-danger">{errors.attributes}</span>}
                  </Form.Text>
                </FormGroup>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <FormGroup className="start-timestamp" controlId="startTimestamp">
                  <FormLabel>Start Date</FormLabel>
                  <Form.Control
                    type="date"
                    max={fields.endTimestamp}
                    onChange={handleFieldChange}
                    value={fields.startTimestamp}
                  />
                  <Form.Text>
                    {errors.startTimestamp && <span className="alert-danger">{errors.startTimestamp}</span>}
                  </Form.Text>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="end-timestamp" controlId="endTimestamp">
                  <FormLabel>End Date</FormLabel>
                  <Form.Control
                    type="date"
                    min={fields.startTimestamp}
                    onChange={handleFieldChange}
                    value={fields.endTimestamp}
                  />
                  <Form.Text>
                    {errors.endTimestamp && <span className="alert-danger">{errors.endTimestamp}</span>}
                  </Form.Text>
                </FormGroup>
              </Col>
            </Form.Row>
            {response && <Alert variant={'danger'}>{response}</Alert>}
          </Modal.Body>
          <Modal.Footer>
            <LoaderButton block type="submit" isLoading={isLoading} disabled={Object.keys(errors).length > 0}>
              Download
            </LoaderButton>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
