import React from 'react'
import './AnchorButton.scss'
/* a button styled as a link */
export default props => {
  const {className, children, ...rest} = props
  return (
    <button className={`anchor-button ${className ? className : ''}`} {...rest}>
      {children}
    </button>
  )
}
