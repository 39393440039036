/* import icons used by containers or components; add to core library */
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDown,
  faAngleRight,
  faBell,
  faCarBattery,
  faChartLine,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faCog,
  faDownload,
  faExclamationTriangle,
  faExclamation,
  faFastBackward,
  faFastForward,
  faHeart,
  faHome,
  faLink,
  faMapMarkerAlt,
  faNetworkWired,
  faPencilAlt,
  faPlug,
  faPlusCircle,
  faSearch,
  faSignal,
  faSpinner,
  faSolarPanel,
  faStepBackward,
  faStepForward,
  faSync,
  faTimes,
  faTrash,
  faUser,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faAngleDown,
  faAngleRight,
  faCarBattery,
  faBell,
  faChartLine,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faCog,
  faDownload,
  faExclamationTriangle,
  faExclamation,
  faFastBackward,
  faFastForward,
  faHeart,
  faHome,
  faLink,
  faMapMarkerAlt,
  faNetworkWired,
  faPencilAlt,
  faPlug,
  faPlusCircle,
  faSearch,
  faSignal,
  faSpinner,
  faSolarPanel,
  faStepBackward,
  faStepForward,
  faSync,
  faTimes,
  faTrash,
  faUser,
  faUserPlus
)
