import moment from 'moment'
import React from 'react'

export default function TimeSince({timestamp, className = ''}) {
  return timestamp ? (
    <div
      className={`time-since ${className} ${
        moment().diff(timestamp) > 24 * 60 * 60 * 1000 ? 'CRITICAL' : ''
      }`}
    >
      {moment(timestamp).fromNow()}
    </div>
  ) : (
    <div className={className}>-</div>
  )
}
