import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Button, Card, Col, Container, Modal, Row} from 'react-bootstrap'
import './DeploySystem.scss'
import {Deployer} from '../libs/deploymentLib'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

/**
 *    | browser | <-- http --> | local REST server | <-- ssh --> | ubuntu-box | <-- usb/adb --> | avnet board |
 *                  (node js server)
 *  steps
 *  verify http connection to local REST server
 *  verify ssh connection from local REST server to ubuntu-box
 *  verify ubuntu box aws credentials
 *  verify ubuntu box toolchain installed
 *  get deployment status of attached jacobi — not found or undeployed or deployed
 *  check db device deployment status against attached jacobi deploying status
 *  if previously deployed, offer reflashing option
 *  install bundle on avnet board, unzip, reboot
 *
 */
export default props => {
  const {device, index, onUpdate} = props
  const [connectedSystem, setConnectedSystem] = useState(null)
  const [deployer] = useState(new Deployer())
  const [deploymentErrorMessage, setDeploymentErrorMessage] = useState(null)
  const [hostName, setHostName] = useState(null)
  const [isSystemDeployed, setIsSystemDeployed] = useState(null)
  const [isSystemUsbConnected, setIsSystemUsbConnected] = useState(null)
  const [isUbuntuAlive, setIsUbuntuAlive] = useState(null)
  const [os, setOs] = useState(null)
  const [seconds, setSeconds] = useState(0)
  const [sshConfig, setSshConfig] = useState(null)
  const [targetSystemInfo, setTargetSystemInfo] = useState(null)
  useEffect(() => {
    ;(async () => {
      if (props.show && isSystemDeployed === null) {
        const localAlive = await deployer.pingLocal()
        setSshConfig(localAlive)
        if (localAlive) {
          const isUbuntuAlive = await deployer.pingUbuntu()
          setIsUbuntuAlive(isUbuntuAlive)
          if (isUbuntuAlive) {
            await Promise.all([
              deployer.exec('lsb_release -d -r').then(result => {
                if (!result || !result.stdout) return
                const rows = result.stdout.split('\n')
                const columns = rows.map(row => row.split(':\t'))
                const os = columns.reduce((acc, [key, value]) => {
                  acc[key] = value
                  return acc
                }, {})
                setOs(os)
              }),
              deployer.exec('uname -a').then(result => {
                if (!result || !result.stdout) return
                setHostName(result.stdout.split(/[ \t]/)[1])
              }),
              deployer.exec('adb devices').then(async result => {
                if (!result) return
                const {stderr, stdout, code} = result
                if (
                  !code &&
                  typeof stderr === 'string' &&
                  !stderr.length &&
                  stdout &&
                  stdout.match(/WNC_ADB.*device/)
                ) {
                  setIsSystemUsbConnected(true)
                  try {
                    const [deviceName, thingName] = await Promise.all([
                      deployer.getFile('/CUSTAPP/sunwize/etc/device_name').then(fileText => {
                        if (!fileText) return null
                        /* quote raw hex values, so that file parses */
                        return fileText
                      }),
                      deployer.getFile('/CUSTAPP/shadow/thing_name').then(fileText => {
                        if (!fileText) return null
                        /* quote raw hex values, so that file parses */
                        return fileText
                      }),
                    ])
                    if (deviceName && thingName) setConnectedSystem({deviceName, thingName})
                    deployer
                      .exec('adb push sunwize-client/system_info /CUSTAPP')
                      .then(async result => {
                        if (!result) return
                        const {stderr} = result
                        // eslint-disable-next-line no-console
                        if (stderr && stderr.length) return console.error(`adb push error: ${stderr}`)
                        deployer.exec('adb shell /CUSTAPP/system_info').then(async result => {
                          if (!result) return
                          const {stderr, stdout, code} = result
                          if (code || (stderr && stderr.length))
                            // eslint-disable-next-line no-console
                            return console.error(`adb push error: ${stderr}`)
                          try {
                            const systemInfo = JSON.parse(stdout)
                            setTargetSystemInfo(systemInfo)
                          } catch (e) {
                            /* may fail if system rebooting */
                            // eslint-disable-next-line no-console
                            console.error(e.message)
                          }
                        })
                      })
                      .catch(e => {
                        // eslint-disable-next-line no-console
                        if (e.message !== 'conf not found') console.log(e.message)
                      })
                    deployer.exec('git rev-parse --short HEAD').then(async result => {
                      if (!result || result.code) {
                        // eslint-disable-next-line no-console
                        console.error(`error: can't read git hash`)
                      }
                    })
                  } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e.message)
                  }
                } else {
                  setIsSystemUsbConnected(false)
                }
              }),
            ])
          } else {
            // no communication to ubuntu host
            setConnectedSystem(null)
            setIsSystemUsbConnected(null)
          }
        } else {
          // no local connection
          setIsUbuntuAlive(null)
          setIsSystemUsbConnected(null)
          setConnectedSystem(null)
          setHostName(null)
        }
      }
    })()
  }, [props.show, isSystemDeployed, deployer, seconds])
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds + 1)
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  const onClick = async () => {
    const {imei, iccid} = targetSystemInfo
    setIsSystemDeployed(false)
    let result = await deployer.exec('git pull', {timeout: 60000})
    if (!result) {
      return setDeploymentErrorMessage('git pull failed')
    }
    result = await deployer.exec(
      `. env.sh && make CONTROLLER='${device.chargeControllerModel}' DEVICE_NAME="${
        device.name || ''
      }" DEVICE_ID=${device.id} adb-install`,
      {timeout: 60000}
    )
    if (!result) {
      return setDeploymentErrorMessage('unknown error')
    }
    const {stdout, stderr} = result
    if (!stdout || !stdout.match(/Deployment complete.$/)) {
      return setDeploymentErrorMessage(`${stderr ? stderr : stdout}`)
    }
    /* save the s3 url for the device */
    const deployedTimestamp = moment().format()
    await onUpdate(iccid, index, 'iccid')
    await onUpdate(imei, index, 'imei')
    await onUpdate(device.id, index, 'thingName')
    /* onUpdate() of deployedTimestamp is special:
     * 1) When it PUTs the current timestamp to the server, indicating the system has been deployed,
     * 2) The backend server—whenever it's asked to save the deployedTimestamp—always saves of the authenticated
     * user in device.deployedBy (so it can't be spoofed in the browser).
     * 3) onUpdate() then updates the local deployedBy value using the deployedBy value returned by the server
     *
     * C.f. onUpdate() defined in Systems.js
     */
    await onUpdate(deployedTimestamp, index, 'deployedTimestamp')
    setIsSystemDeployed(true)
  }
  const onHide = () => {
    setIsSystemDeployed(null)
    setDeploymentErrorMessage(null)
    props.setShow(false)
  }
  const height = [sshConfig, isSystemUsbConnected, isSystemDeployed].reduce((acc, item) => {
    return item !== null ? ++acc : acc
  }, 1)
  const iccid = targetSystemInfo
    ? ` (ICCID: ...${targetSystemInfo.iccid.substring(targetSystemInfo.iccid.length - 6)})`
    : ''
  const haveCellularSignal = targetSystemInfo && parseInt(targetSystemInfo.signalDBm) >= -100
  const haveIpAddress = targetSystemInfo && targetSystemInfo.ipAddress !== '0.0.0.0'
  return (
    <>
      {' '}
      <Modal className="deployment-form" size="lg" show={props.show} backdrop="static" onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Deploy {device.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Card
                  style={{
                    maxHeight: `${height * 18}px`,
                    minHeight: `${height * 18}px`,
                    transition: 'max-height .25s linear, min-height .25s linear',
                  }}
                >
                  <div
                    className={`connection-state ${
                      sshConfig === false || isUbuntuAlive === false ? 'error' : ''
                    }`}
                  >
                    <div className="local-proxy-status">
                      {sshConfig ? (
                        <div className="local-proxy">
                          <FontAwesomeIcon className="icon check" icon="check" />
                          <span>Local SSH Proxy:</span>
                          <span className="status">
                            Connected [{`${sshConfig.user}@${sshConfig.host}:${sshConfig.port}`}]
                          </span>
                        </div>
                      ) : (
                        <div className="local-proxy-error">
                          <FontAwesomeIcon className="icon times" icon="times" />
                          <span className="error status">Local Proxy Not Responding</span>
                        </div>
                      )}
                    </div>
                    {isUbuntuAlive !== null && (
                      <div className="ubuntu-host-connection-status">
                        {isUbuntuAlive ? (
                          <div className="os-info">
                            <FontAwesomeIcon className="icon check" icon="check" />
                            <span>Usb Host:</span>
                            <span className="status">
                              {hostName || 'Unknown'} {os && os.Description}
                            </span>
                          </div>
                        ) : (
                          <div className="error">
                            <FontAwesomeIcon className="icon times" icon="times" />
                            <span className="status error">Ubuntu Host Not Responding</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {isSystemUsbConnected === false && (
                    <div className={'usb-connected-system-not-present'}>
                      <div className="system-name">
                        <FontAwesomeIcon className={`icon warn exclamation`} icon="exclamation-triangle" />
                        <span>Usb Status:</span>
                        <span className="status warn">System not connected</span>
                      </div>
                    </div>
                  )}
                  {isSystemUsbConnected === true && (
                    <div className={'usb-connected-system-present'}>
                      <FontAwesomeIcon
                        className={`icon network-wired ${haveIpAddress ? 'have-ip' : 'no-ip'}`}
                        icon="network-wired"
                      />
                      <FontAwesomeIcon
                        className={`icon signal ${haveCellularSignal ? 'good' : 'bad'}`}
                        icon="signal"
                      />
                      <FontAwesomeIcon className={`icon warn exclamation`} icon="exclamation-triangle" />
                      <div className="system-name">
                        <span>Usb Status:</span>
                        {connectedSystem ? (
                          <span className="status warn">
                            {`Previously deployed '${connectedSystem.deviceName || connectedSystem.thingName}'
                            ${iccid} present. Will be overwritten.`}
                          </span>
                        ) : (
                          <span className="status">System detected{iccid}, ready for deployment</span>
                        )}
                      </div>
                    </div>
                  )}
                  {isSystemDeployed !== null && isSystemUsbConnected === true && (
                    <div className="deploy-system">
                      {isSystemDeployed ? (
                        <div>
                          <FontAwesomeIcon className="icon check" icon="check" />
                          <span>Deployment: </span>
                          <span className="status">Complete</span>
                        </div>
                      ) : (
                        <div>
                          {deploymentErrorMessage ? (
                            <FontAwesomeIcon className="icon times" icon="times" />
                          ) : (
                            <FontAwesomeIcon className="icon sync" icon="sync" spin />
                          )}
                          <span>Deployment: </span>
                          <span className={`status ${deploymentErrorMessage ? 'error' : ''}`}>
                            {deploymentErrorMessage ? deploymentErrorMessage : 'Underway...'}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {deploymentErrorMessage ? (
            <Button block onClick={onHide}>
              Close
            </Button>
          ) : (
            <Button
              block
              type={`${connectedSystem ? 'Overwrite' : 'Deploy'} Attached System as '${
                device.name || device.id
              }'`}
              disabled={!device || isSystemDeployed !== null || targetSystemInfo === null}
              onClick={onClick}
            >
              {connectedSystem
                ? `Deploy Attached System '${
                    connectedSystem.deviceName || connectedSystem.thingName
                  }' as System '${device.name || device.id}'`
                : `Deploy Attached System as Device '${device.name || device.id}'`}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}
