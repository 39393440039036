import React, {useRef, useState} from 'react'
import {LinkContainer} from 'react-router-bootstrap'
import './SystemCards.scss'
import SystemCard from '../components/SystemCard'
import {Button, Collapse} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Busy from './Busy'

export default function SystemCards(props) {
  const {user, favorites, connections} = props
  if (!user) return <Busy />
  const devices = Object.values(user.allDevices || user.devices || []).sort((a, b) =>
    a.name > b.name ? 1 : -1
  )
  const [myFavoritesOpen, setMyFavoritesOpen] = useState(true)
  const [isFavoritesPanelOpen, setIsFavoritesPanelOpen] = useState(false)
  const handleFavToggle = () => {
    setIsFavoritesPanelOpen(!isFavoritesPanelOpen)
  }
  const [isAlarmsPanelOpen, setAlarmsPanelOpen] = useState(false)
  const handleAlarmToggle = () => {
    setAlarmsPanelOpen(!isAlarmsPanelOpen)
  }
  const [isSystemsPanelOpen, setIsSystemsPanelOpen] = useState(false)
  const handleSystemToggle = () => {
    setIsSystemsPanelOpen(!isSystemsPanelOpen)
  }
  const [alarmsOpen, setAlarmsOpen] = useState(true)
  const [systemsOpen, setSystemsOpen] = useState(true)

  const favRef = useRef()
  const alarmRef = useRef()
  const sysRef = useRef()

  return (
    <div className="system-cards">
      <div ref={favRef} />
      <div className="panelGroupWrapper">
        <div className="group-header">
          <span
            className={isFavoritesPanelOpen ? 'open' : 'closed'}
            onClick={() => {
              favRef.current.scrollIntoView({block: 'nearest', behavior: 'smooth'})
              setMyFavoritesOpen(!myFavoritesOpen)
              handleFavToggle()
            }}
            aria-expanded={myFavoritesOpen}
          >
            <FontAwesomeIcon icon="heart" />
            My Favorites (5)
            <FontAwesomeIcon icon="chevron-down" />
          </span>
        </div>
        <div className="card-panel">
          <Collapse in={myFavoritesOpen}>
            <div>
              {devices
                .filter(d => favorites[d.id])
                .map(d => (
                  <LinkContainer key={d.id} to={`/system/${d.id}/detail`}>
                    <Button>
                      <SystemCard
                        errorConditions={d.id === 11 ? {battery: true} : null}
                        warningConditions={d.id === 19 ? {solar: true} : null}
                        {...d}
                      />
                    </Button>
                  </LinkContainer>
                ))}
            </div>
          </Collapse>
        </div>
      </div>
      <div className="divider" ref={alarmRef} />

      <div className="panelGroupWrapper">
        <div className="group-header">
          <span
            className={isAlarmsPanelOpen ? 'open' : 'closed'}
            onClick={() => {
              alarmRef.current.scrollIntoView({block: 'nearest', behavior: 'smooth'})
              setAlarmsOpen(!alarmsOpen)
              handleAlarmToggle()
            }}
            aria-expanded={alarmsOpen}
          >
            <FontAwesomeIcon icon="bell" />
            Alarms & Warnings (5)
            <FontAwesomeIcon icon="chevron-down" />
          </span>
        </div>
        <div className="card-panel">
          <Collapse in={alarmsOpen}>
            <div>
              {devices
                .filter(d => [11, 19].includes(d.id) || !connections[d.id])
                .map(d => (
                  <LinkContainer key={d.id} to={`/system/${d.id}/detail`}>
                    <Button>
                      <SystemCard
                        errorConditions={d.id === 11 ? {battery: true} : null}
                        warningConditions={d.id === 19 ? {solar: true} : null}
                        {...d}
                      />
                    </Button>
                  </LinkContainer>
                ))}
            </div>
          </Collapse>
        </div>
      </div>
      <div className="divider" ref={sysRef} />
      <div className="panelGroupWrapper">
        <div className="group-header">
          <span
            className={isSystemsPanelOpen ? 'open' : 'closed'}
            onClick={() => {
              sysRef.current.scrollIntoView({block: 'nearest', behavior: 'smooth'})
              setSystemsOpen(!systemsOpen)
              handleSystemToggle()
            }}
            aria-expanded={alarmsOpen}
          >
            <FontAwesomeIcon icon="solar-panel" />
            Systems (13)
            <FontAwesomeIcon icon="chevron-down" />
          </span>
        </div>
        <div className="card-panel">
          <Collapse in={systemsOpen}>
            <div>
              {devices
                .filter(d => ![11, 19].includes(d.id) && !favorites[d.id] && connections[d.id])
                .map(d => (
                  <LinkContainer key={d.id} to={`/system/${d.id}/detail`}>
                    <Button>
                      <SystemCard
                        href={`/system/${d.id}/detail`}
                        errorConditions={d.id === 11 ? {battery: true} : null}
                        warningConditions={d.id === 19 ? {solar: true} : null}
                        {...d}
                      />
                    </Button>
                  </LinkContainer>
                ))}
            </div>
          </Collapse>
        </div>
      </div>
      <div className="card-panel" />
    </div>
  )
}
