import React, {useEffect, useState} from 'react'
import {apiGet} from '../libs/apiLib'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import './SwaggerApi.scss'

export default function SwaggerAPI(props) {
  const [model, setModel] = useState(false)
  useEffect(() => {
    async function loadModel() {
      const api = apiGet()
      const {data: model} = await api.get('/model')
      model.servers = [{url: process.env.REACT_APP_API_ENDPOINT}]
      setModel(model)
    }

    loadModel()
  }, [props])
  if (model)
    return (
      <div className="container">
        <SwaggerUI
          allowTryItOut={false}
          spec={model}
          supportedSubmitMethods={['get', 'post', 'put', 'delete']}
          requestInterceptor={req => {
            req.headers.Auth = props.user.idToken
            return req
          }}
        />
      </div>
    )
  return null
}
