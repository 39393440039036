import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import './SystemSettingsModal.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import AnchorButton from './AnchorButton'
import SystemSettingsForm from './SystemSettingsForm'
import {apiGet} from '../libs/apiLib'
import {PropTypes} from 'prop-types'

const api = apiGet()

SystemSettingsModal.propTypes = {
  device: PropTypes.object,
}
export default function SystemSettingsModal(props) {
  const [versions, setVersions] = useState([])
  const {name, shadow, gitHash} = props.device
  const [show, setShow] = useState(props.show || false)
  const onHide = () => {
    setShow(false)
  }

  useEffect(() => {
    ;(async () => {
      try {
        if (show) {
          const {data: releases} = await api.get('/release')
          setVersions(releases.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1)))
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e.message)
      }
    })()
  }, [show])
  const onUpdate = async shadow => {
    await props.onUpdate(shadow, props.index, 'shadow')

    setShow(false)
  }

  return show ? (
    <div className="system-settings-modal">
      <Modal className="system-settings-form" size="lg" show={show} backdrop="static" onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{name} — Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SystemSettingsForm {...{onUpdate, versions, gitHash, shadow}} onCancel={() => setShow(false)} />
        </Modal.Body>
      </Modal>
    </div>
  ) : (
    <AnchorButton onClick={() => setShow(true)}>
      <FontAwesomeIcon className="icon" icon="cog" />
    </AnchorButton>
  )
}
