import React, {useEffect, useState} from 'react'
import './SystemDetail.scss'
import AnchorButton from '../components/AnchorButton'
import Events from '../components/Events'
import Favorite from '../components/Favorite'
import ImageCropperModal from '../components/ImageCropperModal'
import Metrics from '../components/Metrics'
import SystemNotes from '../components/SystemNotes'
import SystemOverview from '../components/SystemOverview'
import SystemPermissions from '../components/SystemPermissions'
import TimeSince from '../components/TimeSince'
import WeatherStrip from '../components/WeatherStrip'
import moment from 'moment-timezone'
import stubbedSystemImage from './stubbed-system-image.png'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {apiGet} from '../libs/apiLib'
import {isConnected} from '../libs/utilLib'

export default function SystemDetail(props) {
  const [device] = useState((props.user.allDevices || props.user.devices)[parseInt(props.match.params.id)])
  const localStorageKey = `${device.id}-system-detail-tab`
  const [tabs] = useState(['metrics', 'overview', 'events', 'notes', 'permissions'])
  const [tab, setTab] = useState(
    (tabs.includes(props.match.params.tab) && props.match.params.tab) ||
      localStorage.getItem(localStorageKey) ||
      tabs[0]
  )
  const [showImageCropperModal, setShowImageCropperModal] = useState(false)
  const [canEdit] = useState(
    device.isAdmin || (device.account && device.account.isAdmin) || props.user.isSuperuser
  )
  const {name, metricsTimestamp, id} = device
  const localTime =
    device.location &&
    device.location.timezoneId &&
    moment().tz(device.location.timezoneId).format('h:mm A z')
  const [overview, setOverview] = useState(null)
  const [events, setEvents] = useState(null)
  const [metrics, setMetrics] = useState(null)
  const [notes, setNotes] = useState(null)
  const [permissions, setPermissions] = useState(null)
  const [resetKey, setResetKey] = useState(0)
  const onTabClick = tab => {
    setTab(tab)
    localStorage.setItem(localStorageKey, tab)
    window.history.replaceState(null, null, `/system/${id}/${tab}`)
  }
  useEffect(() => {
    switch (tab) {
      case 'overview':
        if (!overview) {
          setOverview(<SystemOverview {...device} />)
        }
        break

      case 'events':
        if (!events) {
          setEvents(<Events {...{id: device.id}} />)
        }
        break

      case 'metrics':
        if (!metrics) {
          setMetrics(<Metrics {...{id: device.id, user: props.user}} />)
        }
        break

      case 'notes':
        if (!notes) {
          setNotes(
            <SystemNotes {...{id, name, user: props.user, users: props.user.allUsers || props.user.users}} />
          )
        }
        break

      case 'permissions':
        if (!permissions) {
          setPermissions(<SystemPermissions {...props} />)
        }
        break

      default:
    }
  }, [device, events, id, metrics, name, notes, overview, permissions, props, tab])
  const onNewSystemPhoto = async base64Image => {
    device.systemPhoto = base64Image
    const api = apiGet()
    await api.put(`/device/${id}`, {systemPhoto: base64Image})
    setShowImageCropperModal(false)
  }
  const onRemoveSystemPhoto = async () => {
    device.systemPhoto = null
    const api = apiGet()
    await api.put(`/device/${id}`, {systemPhoto: null})
    setShowImageCropperModal(false)
  }
  const weatherTime = device.weather && device.weather.timestamp ? moment(device.weather.timestamp) : null
  const connected = isConnected(device.metricsTimestamp, device.reportingIntervalSeconds)
  return (
    <div className="system-detail">
      {canEdit && (
        <ImageCropperModal
          handleRemove={onRemoveSystemPhoto}
          handleSubmit={onNewSystemPhoto}
          imageSource={device.systemPhoto}
          onCancel={() => setShowImageCropperModal(false)}
          resetKey={resetKey}
          setShow={setShowImageCropperModal}
          show={showImageCropperModal}
          title={'System Image'}
        />
      )}
      <div className="system-detail-banner">
        <div className={`system-image-wrapper ${canEdit ? 'can-edit' : ''}`}>
          <img
            className="system-image"
            src={device.systemPhoto || stubbedSystemImage}
            onDoubleClick={() => setShowImageCropperModal(true)}
            alt=""
          />
          <FontAwesomeIcon
            className={'pencil-alt'}
            icon={'pencil-alt'}
            onDoubleClick={() => {
              setShowImageCropperModal(true)
              setResetKey(resetKey + 1)
            }}
          />
        </div>
        <div className="system-detail-status-bar">
          <div className="left-side">
            <div className="system-name">{name}</div>
            <div className="connection">
              <div className={`heading ${connected ? '' : 'error'}`}>last updated</div>
              <TimeSince
                timestamp={metricsTimestamp}
                className={`last-connection ${connected ? '' : 'error'}`}
              />
            </div>
          </div>
          <div className={'right-side'}>
            {weatherTime && moment.duration(moment().diff(weatherTime)).asHours() < 3 && (
              <WeatherStrip {...device.weather} />
            )}
            {localTime && (
              <div className="time">
                <div className="heading">local time</div>
                <div className="value">{localTime}</div>
              </div>
            )}
            <Favorite
              className={`hover-mode ${props.favorites[id] ? 'is-favorite' : ''}`}
              onClick={() => props.toggleFavorite(id)}
            />
          </div>
        </div>
      </div>
      <div className="system-detail-button-bar">
        <AnchorButton
          className={tab === 'overview' && 'is-active'}
          disabled={tab === 'overview'}
          onClick={() => onTabClick('overview')}
        >
          System Overview
        </AnchorButton>
        <AnchorButton
          className={tab === 'metrics' && 'is-active'}
          disabled={tab === 'metrics'}
          onClick={() => onTabClick('metrics')}
        >
          Metrics
        </AnchorButton>
        <AnchorButton
          className={tab === 'events' && 'is-active'}
          disabled={tab === 'events'}
          onClick={() => onTabClick('events')}
        >
          Events
        </AnchorButton>
        <AnchorButton
          className={tab === 'notes' && 'is-active'}
          disabled={tab === 'notes'}
          onClick={() => onTabClick('notes')}
        >
          Notes
        </AnchorButton>
        <AnchorButton
          className={tab === 'permissions' && 'is-active'}
          onClick={() => onTabClick('permissions')}
        >
          Users and Accounts
        </AnchorButton>
      </div>
      <div className="selected-panel">
        {tab === 'metrics' && metrics}
        {tab === 'overview' && overview}
        {tab === 'events' && events}
        {tab === 'notes' && notes}
        {tab === 'permissions' && permissions}
      </div>
    </div>
  )
}
