import React, {useEffect, useState} from 'react'
import {Alert, Button, Form, FormLabel, Modal} from 'react-bootstrap'
import './EventDefinitionModal.scss'
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import AnchorButton from './AnchorButton'
import LoaderButton from '../components/LoaderButton'
import {useFormFields} from '../libs/formLib'
import FormGroup from './FormGroup'
import {apiGet} from '../libs/apiLib'
import AsyncDeviceSelect from './AsyncDeviceSelect'
import SeveritySelector from './SeveritySelector'
import {eventSeverityLevels, eventTypeMap, metricOnlyFields, metricsOperators} from '../libs/eventsLib'
import ReactSelect from 'react-select'
import EventTypeIcon from './EventTypeIcon'
import {getCanvasFontSize, getTextWidth} from '../libs/fontLib'

const metricEventTypes = Object.keys(eventTypeMap).filter(k => eventTypeMap[k].isMetricEventType)
const modbusEventTypes = Object.keys(eventTypeMap).filter(k => eventTypeMap[k].isModbusEventType)
const modbusOnlyFields = ['factor', 'mask', 'modbusDeviceTypeGroup', 'registerAddress', 'shift']

const toEventTypeSelection = eventType => {
  return {value: eventType, label: <EventTypeIcon eventType={eventType} />}
}

const metricEventTypeOptions = metricEventTypes.map(eventType => toEventTypeSelection(eventType))
const modbusEventTypeOptions = modbusEventTypes.map(eventType => toEventTypeSelection(eventType))

function EventDefinitionForm(props) {
  const isCreateMode = props.id === undefined
  const [isMetricMode] = useState(props.modalMode === 'metric')
  const [isModbusMode] = useState(props.modalMode === 'modbus')
  const [isLoading, setIsLoading] = useState(false)
  const [operandPixelWidth, setOperandPixelWidth] = useState(100)
  // map of event names used to prevent creation of definitions with duplicate names

  const [salesOrderSelectionOptions] = useState(
    (props.salesOrders || []).map(({id}) => {
      return {value: id, label: id}
    })
  )

  const [modbusDeviceTypeGroupOptions] = useState(
    (props.modbusDeviceTypeGroups || []).map(({id, name}) => {
      return {value: id, label: name}
    })
  )

  const [fields, handleFieldChange, handleReactSelectFieldChange] = useFormFields(
    {
      description: {value: props.description || '', formFieldOptions: {noTrim: true}},
      deviceSelections: props.deviceSelections || [],
      eventType: props.eventType
        ? toEventTypeSelection(props.eventType)
        : isMetricMode
        ? metricEventTypeOptions[0]
        : modbusEventTypeOptions[0],
      factor: props.factor ? String(props.factor) : '',
      id: props.id,
      isSystemGlobal: props.isSystemGlobal || false,
      mask: props.mask || '',
      modbusDeviceTypeGroupSelections: props.modbusDeviceTypeGroupSelections || '',
      name: props.name || '',
      operator: props.operator || '',
      operand: props.operand || '',
      registerAddress: props.registerAddress || '',
      salesOrderSelections: props.salesOrderSelections || [],
      severity: props.severity || '',
      shift: props.shift || '',
    },
    validateEventDefinitionForm
  )
  // save initial field values, so we can determine if there are changes to be submitted
  const [initialFields] = useState(fields)
  const [errors, setErrors] = useState({initially: 'errant'})

  const event = fields.eventType.value
  const eventType = eventTypeMap[event]
  const integerOnly = true
  // validate initial field values/
  useState(() => validateEventDefinitionForm(fields))

  function validateEventDefinitionForm(fields) {
    const errors = {}
    const validateNumber = (id, min, max, integerOnly) => {
      if (id === 'operand' || eventType?.requires?.[id]) {
        if (!fields[id].length) errors[id] = 'required'
        else {
          const value = Number(fields[id])
          if (Number.isNaN(value)) errors[id] = 'not a number'
          else if (integerOnly && !Number.isInteger(value)) errors[id] = 'must be an integer'
          else if (typeof min === 'number' && value < min) {
            errors[id] = `must be >= ${min}`
          } else if (typeof max === 'number' && value > max) {
            errors[id] = `must be <= ${max}`
          }
        }
      }
    }
    if (!fields.eventType.value) errors.eventType = 'required'
    // name required and must be unique
    if (!fields.name.length) errors.name = 'required'
    else {
      if (isCreateMode && props.eventNames[fields.name.toLowerCase()]) {
        errors.name = `event with name '${fields.name}' already exists`
      }
    }

    if (!fields.description.length) errors.description = 'required'
    if (!fields.severity.length) errors.severity = 'required'
    if (isModbusMode && !fields.registerAddress.length) errors.registerAddress = 'required'
    if (event !== 'boolean' && !fields.operator.length) errors.operator = 'required'

    /* validate optional fields when they are required */
    if (eventType?.requires?.mask) validateNumber('mask', 0, 0xffff, integerOnly)
    if (eventType?.requires?.shift) validateNumber('shift', 0, 0xffff, integerOnly)
    if (eventType?.requires?.factor) validateNumber('factor')

    /* all modbus types require a register address */
    if (isModbusMode) validateNumber('registerAddress', 0, 0xffff, integerOnly)

    /* validate the operand */
    if (!fields.operand.length) errors.operand = 'required'
    else {
      if (event === 'boolean' && fields.operand !== 'true' && fields.operand !== 'false')
        errors.operand = 'must be true or false'
      else
        validateNumber(
          'operand',
          eventType.min,
          eventType.max,
          /* integers */ ['signedUint15', 'signedUint7', 'unit16'].includes(eventType.operandType)
        )
    }
    if (
      !fields.isSystemGlobal &&
      !fields.deviceSelections.length &&
      !fields.salesOrderSelections.length &&
      !fields.modbusDeviceTypeGroupSelections.length
    ) {
      errors.deviceSelections = 'required'
      errors.salesOrderSelections = 'required'
      errors.modbusDeviceTypeGroupSelections = 'required'
    }
    setErrors(errors)
  }

  const isSubmitEnabled = () => {
    // no errors and at least one change required
    let hasDelta = false
    for (const field in fields)
      if (fields[field] !== initialFields[field]) {
        hasDelta = true
        break
      }

    return Object.keys(errors).length === 0 && hasDelta
  }

  async function handleSubmit(event) {
    try {
      event.preventDefault()
      const id = props.id
      setErrors({})
      setIsLoading(true)
      let requestBody = {}
      for (const key in fields)
        if (isModbusMode) {
          if (!metricOnlyFields.includes(key)) requestBody[key] = fields[key]
        } else {
          if (!modbusOnlyFields.includes(key)) requestBody[key] = fields[key]
        }
      // convert eventType from selection object back to string
      requestBody.eventType = requestBody.eventType.value
      const url = `/event/${isMetricMode ? 'metric' : 'modbus'}/definition${id ? `/${id}` : ''}`
      const api = apiGet()
      const {data: eventDefinition} = await api[id ? 'put' : 'post'](url, requestBody)

      /* let the owner of definitions know about the change so that it can update our copy of
       * the data without a round trip to the backend.
       */
      if (id) props.onUpdate(eventDefinition, props.rowIndex)
      else props.onCreate(eventDefinition)
    } catch (e) {
      setErrors({response: e.message})
    } finally {
      setIsLoading(false)
      props.setModalData(undefined)
      props.setModalShow(false)
    }
  }

  const handleNameFieldChange = ({target: {id, type, value}}) => {
    //alpha upper case only and '_'
    handleFieldChange({
      target: {id, value: value.toUpperCase().replace(/([^A-Z_,0-9])/, ''), type},
    })
  }

  useEffect(() => {
    /*
     * dynamically calculate operand pixel width including overlaid units, using actual operand field's font
     *
     * fudge width by adding an extra space to the string
     */
    const operandString = `${fields.operand}${eventType.units ? ` ${eventType.units}` : ''}`
    const operandElement = document.getElementById('operand')
    const operandPixelWidth = getTextWidth(operandString, getCanvasFontSize(operandElement))
    setOperandPixelWidth(operandPixelWidth + 20)
  }, [fields.operand, eventType])

  return (
    <Form className="register-event-form" onSubmit={handleSubmit}>
      {errors.response && <Alert variant={'danger'}>{errors.response}</Alert>}
      <div className="form-row">
        <FormGroup
          controlId={'name'}
          label="Name"
          {...{type: 'input', value: fields.name, errors, onChange: handleNameFieldChange}}
        />
      </div>
      <div className="form-row">
        <FormGroup
          controlId={'description'}
          type="textarea"
          label="Description"
          {...{value: fields.description, errors, onChange: handleFieldChange}}
        />
      </div>
      <div className="form-row scope">
        <div className="form-column device-selections">
          <Form.Group className="deviceSelections">
            <FormLabel>Systems</FormLabel>
            <AsyncDeviceSelect
              isDisabled={fields.isSystemGlobal}
              value={fields.isSystemGlobal ? [] : fields.deviceSelections}
              onChange={value => handleReactSelectFieldChange('deviceSelections', value)}
            />
            <Form.Text>
              {errors.deviceSelections && <span className="alert-danger">{errors.deviceSelections}</span>}
            </Form.Text>
          </Form.Group>
          <FormGroup
            controlId={'isSystemGlobal'}
            type="checkbox"
            label="Select All Systems"
            {...{value: fields.isSystemGlobal, errors, onChange: handleFieldChange}}
          />
        </div>
        {isMetricMode && (
          <div className="form-column sales-orders">
            <Form.Group className="salesOrderSelections">
              <FormLabel>Sales Orders</FormLabel>
              <ReactSelect
                isDisabled={fields.isSystemGlobal}
                className="react-select"
                classNamePrefix="react-select"
                isMulti
                value={fields.isSystemGlobal ? [] : fields.salesOrderSelections || []}
                onChange={value => handleReactSelectFieldChange('salesOrderSelections', value)}
                options={salesOrderSelectionOptions}
              />
              <Form.Text>
                {errors.salesOrderSelections && (
                  <span className="alert-danger">{errors.salesOrderSelections}</span>
                )}
              </Form.Text>
            </Form.Group>
          </div>
        )}
        {isModbusMode && (
          <div className="form-column modbus-device-type-group-selections">
            <Form.Group className="modbusDeviceTypeGroupSelections">
              <FormLabel>Modbus Device Groups</FormLabel>
              <ReactSelect
                isDisabled={fields.isSystemGlobal}
                className="react-select"
                classNamePrefix="react-select"
                isMulti
                value={fields.isSystemGlobal ? [] : fields.modbusDeviceTypeGroupSelections || []}
                onChange={value => handleReactSelectFieldChange('modbusDeviceTypeGroupSelections', value)}
                options={modbusDeviceTypeGroupOptions}
              />
              <Form.Text>
                {errors.modbusDeviceTypeGroupSelections && (
                  <span className="alert-danger">{errors.modbusDeviceTypeGroupSelections}</span>
                )}
              </Form.Text>
            </Form.Group>
          </div>
        )}
      </div>
      <div className="form-row">
        {isModbusMode && (
          <FormGroup
            controlId={'registerAddress'}
            label="In Register Address"
            {...{type: 'input', value: fields.registerAddress, errors, onChange: handleFieldChange}}
          />
        )}
        {isModbusMode && eventType?.requires?.shift && (
          <FormGroup
            controlId={'shift'}
            label="Right-Shifted By"
            {...{type: 'input', value: fields.shift, errors, onChange: handleFieldChange}}
          >
            <span className="bits">Bits</span>
          </FormGroup>
        )}
        {isModbusMode && eventType?.requires?.factor && (
          <FormGroup
            controlId={'factor'}
            label="Multiplied By"
            multiple={true}
            {...{type: 'input', value: fields.factor, errors, onChange: handleFieldChange}}
          />
        )}
        {isModbusMode && eventType?.requires?.mask && (
          <FormGroup
            controlId={'mask'}
            label="Logically ANDed With"
            {...{type: 'input', value: fields.mask, errors, onChange: handleFieldChange}}
          />
        )}
      </div>
      <div className="form-row last">
        <Form.Group className="eventType">
          <FormLabel>When</FormLabel>
          <ReactSelect
            className="react-select"
            classNamePrefix="react-select"
            value={fields.eventType || []}
            onChange={value => handleReactSelectFieldChange('eventType', value)}
            options={isMetricMode ? metricEventTypeOptions : modbusEventTypeOptions}
          />
          <Form.Text>
            {errors.eventType && <span className="alert-danger">{errors.eventType}</span>}
          </Form.Text>
        </Form.Group>
        <FormGroup
          controlId="operator"
          label={event !== 'boolean' ? 'Is' : undefined}
          type="select"
          value={fields.operator}
          errors={errors}
          onChange={handleFieldChange}
          options={(eventType?.operators || metricsOperators).map(({label, value}) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        />
        <Form.Group controlId="operand" className="operand">
          <Form.Label>
            {eventType?.labels?.operand !== undefined ? eventType?.labels?.operand : 'This Value'}
          </Form.Label>
          {eventType?.operands ? (
            <Form.Control as="select" onChange={handleFieldChange} value={fields.operand}>
              {eventType?.operands.map(({label, value}) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Form.Control>
          ) : (
            <div className="operand-wrapper">
              <Form.Control
                as="input"
                style={{
                  width: `${operandPixelWidth}px`,
                }}
                onChange={handleFieldChange}
                value={fields.operand}
              />
              {eventType?.units && <span>{eventType?.units}</span>}
            </div>
          )}
          {errors.operand && <Alert variant={'danger'}>{errors.operand}</Alert>}
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Generate</Form.Label>
          <SeveritySelector
            value={eventSeverityLevels[fields.severity]}
            onChange={value => handleReactSelectFieldChange('severity', value)}
          />
          {errors.operand && <Alert variant={'danger'}>{errors.operand}</Alert>}
        </Form.Group>
      </div>
      <div className="button-row">
        <Button onClick={() => props.onCancel && props.onCancel()}>Cancel</Button>
        <LoaderButton type="submit" isLoading={isLoading} disabled={!isSubmitEnabled()}>
          {props.id ? 'Save Changes' : 'Create Event'}
        </LoaderButton>
      </div>
    </Form>
  )
}

export default function EventDefinitionModal(props) {
  const onHide = () => {
    props.setModalShow(false)
    props.setModalData(undefined)
  }

  return (
    <Modal className="event-definition-form" size="lg" show={props.show} backdrop="static" onHide={onHide}>
      <Modal.Header>
        <Modal.Title>{props.name ? 'Edit Event' : 'Create Event'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EventDefinitionForm {...props} modalMode={props.modalMode} onCancel={onHide} />
      </Modal.Body>
    </Modal>
  )
}
