import React from 'react'
import './ProfilePicture.scss'

export default ({initials, isAdmin, isSuperuser, image}) => {
  const yellow = 'rgb(245, 225, 6)'
  const fontFamily = 'PosterGothicATF, sans-serif'
  return (
    <div className="profile-picture">
      <div className="image-or-initials" title={isAdmin ? 'Administrator' : 'Basic User'}>
        <svg
          viewBox="0 0 500 500"
          style={{borderRadius: '50%'}}
          width="500px"
          height="500px"
          xmlns="http://www.w3.org/2000/svg"
        >
          {!image && (
            <g>
              <circle
                cx="250"
                cy="250"
                r="245"
                style={{
                  fillRule: 'nonzero',
                  strokeWidth: isAdmin ? '15px' : '10px',
                  fill: '#d6d6d6',
                  stroke: isAdmin ? 'blue' : 'grey',
                }}
              />
              <text
                y="209"
                x="168.667"
                transform="matrix(17.136538, 0, 0, 18.706121, -2634.177734, -3557.940186)"
                style={{
                  fontFamily,
                  fill: 'rgb(51,51,51)',
                  fontSize: '16',
                  fontWeight: 700,
                  lineHeight: '7.35588px',
                  textAnchor: 'middle',
                }}
              >
                {initials}
              </text>
            </g>
          )}
          {image && <image href={image} height={500} width={500} />}
        </svg>
      </div>
      {isSuperuser && (
        <div title={'Superuser'} className={'superuser-overlay'}>
          <svg viewBox="0 0 500 500" width="500px" height="500px" xmlns="http://www.w3.org/2000/svg">
            <circle
              cy="250"
              r="240"
              cx="250"
              style={{fillRule: 'nonzero', fill: 'none', stroke: yellow, strokeWidth: '15px'}}
            />
            <path
              d="M 620.496 89.55 L 629.993 116.873 L 658.914 117.462 L 635.863 134.938 L 644.24 162.625 L 620.496 146.103 L 596.752 162.625 L 605.129 134.938 L 582.078 117.462 L 610.999 116.873 Z"
              style={{fill: yellow, stroke: 'black'}}
              transform="matrix(-0.406896, 0.913474, -0.913474, -0.406896, 830.308561, -474.606043)"
            />
            <path
              d="M 620.496 89.55 L 629.993 116.873 L 658.914 117.462 L 635.863 134.938 L 644.24 162.625 L 620.496 146.103 L 596.752 162.625 L 605.129 134.938 L 582.078 117.462 L 610.999 116.873 Z"
              style={{fill: yellow, stroke: 'black'}}
              transform="matrix(-0.136267, 0.990672, -0.990672, -0.136267, 252.234713, -556.070343)"
              origin="0.442 0.369"
            />
            <path
              d="M 620.496 89.55 L 629.993 116.873 L 658.914 117.462 L 635.863 134.938 L 644.24 162.625 L 620.496 146.103 L 596.752 162.625 L 605.129 134.938 L 582.078 117.462 L 610.999 116.873 Z"
              transform="matrix(0.5, 0.866025, -0.866025, 0.5, 254.298674, -143.312422)"
              style={{fill: yellow, stroke: 'black'}}
            />
            <path
              d="M 620.496 89.55 L 629.993 116.873 L 658.914 117.462 L 635.863 134.938 L 644.24 162.625 L 620.496 146.103 L 596.752 162.625 L 605.129 134.938 L 582.078 117.462 L 610.999 116.873 Z"
              style={{fill: yellow, stroke: 'black'}}
              transform="matrix(0.987688, 0.156434, -0.156434, 0.987688, -547.535387, 232.769308)"
            />
          </svg>
        </div>
      )}
    </div>
  )
}
