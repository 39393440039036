import axios from 'axios'

export class Deployer extends Object {
  constructor(props) {
    super()
    this.props = props
    this.axiosLocal = axios.create({baseURL: 'http://localhost:3000', timeout: 60000})
  }

  async pingLocal() {
    try {
      const {data: sshConfig} = await this.axiosLocal.get('/config')
      return sshConfig
    } catch (e) {
      return false
    }
  }
  async pingUbuntu() {
    try {
      const result = await this.exec('date', {timeout: 4000})
      return !!result
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message)
      return false
    }
  }

  async exec(command, options) {
    try {
      const result = await this.axiosLocal.post('/exec', {command}, options)
      return result.data
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message)
    }
  }

  async getFile(filepath, options) {
    try {
      const {data} = await this.axiosLocal.get('/file' + filepath, options)
      return data
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error && e.response.data.error === 'No such file')
        return null
      // eslint-disable-next-line no-console
      console.error(e.message)
      return null
    }
  }
}
