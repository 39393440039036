import React from 'react'

export default ({className = 'check-mark'}) => {
  const id = String(Math.random())
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
        <defs>
          <linearGradient
            id={id}
            x1="-397.42"
            y1="377.75"
            x2="-400.53"
            y2="374.56"
            gradientTransform="matrix(31, 0, 0, -31, 12442.33, 11736.67)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#4ca956" />
            <stop offset="1" stopColor="#36a557" />
          </linearGradient>
        </defs>
        <g>
          <rect
            id="Rectangle_2877-2"
            data-name="Rectangle 2877-2"
            x="20"
            y="20"
            width="110"
            height="110"
            rx="35.48"
            fill={`url(#${id})`}
          />
          <path
            d="M62.58,102.71,37.45,77.57a3.87,3.87,0,0,1,0-5.47l5.47-5.47a3.87,3.87,0,0,1,5.47,0L65.33,83.57l36.28-36.28a3.87,3.87,0,0,1,5.47,0l5.47,5.47a3.87,3.87,0,0,1,0,5.47L68.06,102.71a3.86,3.86,0,0,1-5.46,0Z"
            fill="#fff"
          />
        </g>
      </svg>
    </div>
  )
}
