import React from 'react'
import './Map.scss'

export default function Map() {
  return (
    <div className="map">
      <div className="content-header">
        <div className="header-title">Systems Map</div>
      </div>
      <div className="content-body">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d35929.936275724904!2d-115.84360167370245!3d37.25274426509085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80b81baaba3e8c81%3A0x970427e38e6237ae!2sArea%2051%2C%20NV!5e0!3m2!1sen!2sus!4v1643516110731!5m2!1sen!2sus"
          width="100%"
          height="100%"
          allowFullScreen=""
          loading="lazy"
          title="map"
        />
      </div>
    </div>
  )
}
