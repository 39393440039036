import React, {useEffect, useState} from 'react'
import './Events.scss'
import {apiGet} from '../libs/apiLib'
import {DateTimeCell, MetricsCell} from './TableCells'
import {getFunctionName} from '../libs/utilLib'
import Busy from './Busy'
import Table from './Table'

const HAVE_DATA = 'HAVE_DATA',
  BUSY = 'BUSY'
const api = apiGet()

export default function Events(props) {
  const [deviceId] = useState(props.id)
  const [name] = useState(props.name)
  const [eventsData, setEventsData] = useState([])
  const [eventsState, setEventsState] = useState(BUSY)

  const eventColumns = [
    {accessor: 'severity', Header: 'Type', default: true},
    {accessor: 'description', Header: 'Event', default: true},
    {accessor: 'metrics', Header: 'Metrics', Cell: MetricsCell, default: true},
    {accessor: 'ts', Header: 'When', Cell: ({value}) => <DateTimeCell value={value * 1000} />, default: true},
  ]

  useEffect(() => {
    ;(async () => {
      try {
        if (deviceId) {
          const result = await api.get(`/device/${deviceId}/events`)
          setEventsData(result.data)
          setEventsState(HAVE_DATA)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e.message)
      }
    })()
  }, [deviceId])

  return (
    <div className="events-panel">
      {eventsState === HAVE_DATA && eventsData.length === 0 ? (
        <div className="span4 alert alert-info text-center">
          <em>No events found for System {name}</em>
        </div>
      ) : (
        <Table
          {...{
            columns: eventColumns,
            storageKey: [getFunctionName(), 'events'].join('-'),
            data: eventsData,
          }}
        />
      )}
      {eventsState === BUSY && <Busy />}
    </div>
  )
}
