import React from 'react'
import './Favorite.scss'
import {ReactComponent as FavoriteIcon} from './favorite.svg'

export default function Favorite({className, ...rest}) {
  return (
    <div className={`favorite ${className || ''}`} {...rest}>
      <FavoriteIcon />
    </div>
  )
}
