import React, {useEffect, useState} from 'react'
import './MetricsGraph.scss'

import {apiGet} from '../libs/apiLib'
import Busy from './Busy'
import {Line} from 'react-chartjs-2'

export default function MetricsGraph(props) {
  const HAVE_DATA = 'HAVE_DATA',
    NO_DATA = 'NO_DATA',
    BUSY = 'BUSY'
  const [state, setState] = useState(BUSY)
  const [chartData, setChartData] = useState(false)
  const api = apiGet()

  useEffect(() => {
    let mounted = true

    async function loadMetrics(deviceId, startMillis, endMillis, attributes) {
      try {
        setState(BUSY)
        //console.log(`/device/${deviceId}/metrics?startMillis=${startMillis}&endMillis=${endMillis}&attributes=${attributes}`)
        const {data: chartData} = await api.get(
          `/device/${deviceId}/metrics?startMillis=${startMillis}&endMillis=${endMillis}&attributes=${attributes}`
        )
        if (!mounted) return

        /*
         * can't display time ticks in device's timezone due to this bug in chartjs:
         *    https://github.com/chartjs/Chart.js/issues/4334
         */
        setChartData(chartData)
        setState('HAVE_DATA')
      } catch (e) {
        // check error message and response code, deal with it in the UI, etc.
        setState(NO_DATA)
      }
      return function cleanUp() {
        mounted = false
      }
    }

    try {
      loadMetrics(props.deviceId, props.startMillis, props.endMillis, props.attributes)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e.message)
    }
  }, [props.deviceId, props.startMillis, props.endMillis, props.attributes, props.timezone, api])

  /*
   * chart.js modifies the chart data, adding state, which it then errantly relies on in subsequent renders.
   * To avoid these cases, always give the chart a fresh copy of the data
   */
  const chartDataCopy = JSON.parse(JSON.stringify(chartData))
  if (chartDataCopy.options)
    chartDataCopy.options.elements = {
      point: {
        width: ({dataset, dataIndex}) => {
          switch (dataset.label) {
            case 'Battery Voltage': {
              const value = dataset.data[dataIndex].y
              return value < 11.5 ? 3 : 1
            }
            default:
              return dataset._pointRadius
          }
        },
        radius: ({dataset, dataIndex}) => {
          switch (dataset.label) {
            case 'Battery Voltage': {
              const value = dataset.data[dataIndex].y
              return value < 11.5 ? 3 : 1
            }
            default:
              return dataset._pointRadius
          }
        },
        backgroundColor: ({dataset, dataIndex}) => {
          switch (dataset.label) {
            case 'Battery Voltage': {
              const value = dataset.data[dataIndex].y
              return value < 11.5 ? 'red' : dataset._backgroundColor
            }
            default:
              return dataset._backgroundColor
          }
        },
        borderColor: ({dataset, dataIndex}) => {
          switch (dataset.label) {
            case 'Battery Voltage': {
              const value = dataset.data[dataIndex].y
              return value < 11.5 ? 'red' : dataset._borderColor
            }
            default:
              return dataset._borderColor
          }
        },
      },
      line: {
        borderColor: ({dataset}) => {
          return dataset._borderColor
        },
      },
    }
  return (
    <div className="metrics-graph">
      {state === HAVE_DATA && chartData && (
        <div>
          {props.children}
          <Line {...Object.assign({}, chartDataCopy)} height={100} redraw />
        </div>
      )}
      {state === 'NO_DATA' && (
        <div className="no-data">
          <h3 className="jumbotron shadow">
            <span role="img" aria-label="rainy day">
              🌦️
            </span>
            No Chart Data
            <span role="img" aria-label="rainy day">
              🌦️
            </span>
          </h3>
        </div>
      )}
      {state === 'BUSY' && <Busy />}
    </div>
  )
}
