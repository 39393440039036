import React, {useState} from 'react'
import {LinkContainer} from 'react-router-bootstrap'
import Auth from '@aws-amplify/auth'
import {Alert, Form, FormControl, FormGroup, FormLabel} from 'react-bootstrap'
import LoaderButton from '../components/LoaderButton'
import {useFormFields} from '../libs/formLib'
import './Login.scss'
import {validatePassword, validatePhoneNumber} from '../libs/validateLib'
import FormPage from '../components/FormPage'
import AnchorButton from '../components/AnchorButton'

export default function Login(props) {
  // console.log(`Login user: ${JSON.stringify(!!props.user)}`)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [challengedUser, setChallengedUser] = useState(null)
  const [phoneNumberRequired, setPhoneNumberRequired] = useState(false)
  let validator = null
  if (challengedUser) {
    if (challengedUser.challengeName === 'SMS_MFA') validator = validateMfaForm
    else if (challengedUser.challengeName === 'NEW_PASSWORD_REQUIRED') validator = validateNewPasswordForm
  }

  const [fields, handleFieldChange] = useFormFields(
    {
      email: '',
      mfaCode: '',
      password: '',
      newPassword: '',
      newPassword2: '',
      phoneNumber: '',
    },
    validator
  )

  async function handleSubmit(event) {
    event.preventDefault()
    setErrors({})
    setIsLoading(true)

    try {
      // email addresses are always lowercase to prevent case issues
      const user = await Auth.signIn(fields.email.toLowerCase(), fields.password)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // User, likely new, is being forced to change their password
        // save user, which will trigger new password form rendering
        // and is needed to complete the password change
        setChallengedUser(user)
        if (user.challengeParam.requiredAttributes)
          user.challengeParam.requiredAttributes.forEach(p => {
            if (p === 'phone_number') setPhoneNumberRequired(true)
          })
      } else if (user.challengeName === 'SMS_MFA') {
        setChallengedUser(user)
      } else if (user.challengeName) {
        // eslint-disable-next-line no-console
        console.error(`unimplemented challenge: ${user.challengeName}`)
      } else {
        props.setIsAuthenticated(true)
      }
    } catch (e) {
      setErrors({response: e.message})
    } finally {
      setIsLoading(false)
    }
  }

  function validateLoginForm() {
    return fields.email.length > 0 && fields.password.length > 0
  }

  function renderLoginForm() {
    return (
      <Form className="form-page" onSubmit={handleSubmit}>
        {errors.response && <Alert variant={'danger'}>{errors.response}</Alert>}
        <div className="login-pic" />
        <FormGroup controlId="email">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
            autoComplete="email"
          />
        </FormGroup>
        <FormGroup controlId="password">
          <FormLabel>Password</FormLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
            autoComplete="current-password"
          />
        </FormGroup>
        <div className="button-row">
          <LoaderButton type="submit" isLoading={isLoading} disabled={!validateLoginForm()}>
            {' '}
            Sign In{' '}
          </LoaderButton>
          <LinkContainer to="/new-password">
            <AnchorButton>Forgot Password</AnchorButton>
          </LinkContainer>
        </div>
      </Form>
    )
  }

  async function handleMfaSubmit(event) {
    event.preventDefault()
    setErrors({})
    setIsLoading(true)

    try {
      await Auth.confirmSignIn(challengedUser, fields.mfaCode)
      props.setIsAuthenticated(true)
    } catch (e) {
      setErrors({response: e.message})
    } finally {
      setIsLoading(false)
    }
  }

  function validateMfaForm(fields) {
    const errors = {}
    if (!fields.mfaCode.match(/^[0-9]{6}$/)) errors.mfaCode = 'Invalid verification code'
    setErrors(errors)
  }

  function renderMfaForm() {
    return (
      <Form className="form-page" onSubmit={handleMfaSubmit}>
        <div className="title">VERIFY YOUR IDENTITY</div>
        {errors.response && <Alert variant={'danger'}>{errors.response}</Alert>}
        <FormGroup controlId="mfaCode">
          <FormLabel>Verification Code</FormLabel>
          <FormControl
            type="tel"
            onChange={handleFieldChange}
            value={fields.mfaCode}
            placeholder="Please check phone for code"
          />
          {errors.mfaCode && <Alert variant={'danger'}>{errors.mfaCode}</Alert>}
        </FormGroup>
        <div className="button-row">
          <LoaderButton type="submit" isLoading={isLoading} disabled={Object.keys(errors).length}>
            {' '}
            Submit{' '}
          </LoaderButton>
        </div>
      </Form>
    )
  }

  async function handleSubmitNewPassword(event) {
    event.preventDefault()
    setErrors({})
    setIsLoading(true)

    try {
      await Auth.completeNewPassword(
        challengedUser,
        fields.newPassword,
        phoneNumberRequired ? {phone_number: '+1' + fields.phoneNumber.replace(/-/g, '')} : {}
      )
      props.setIsAuthenticated(true)
    } catch (e) {
      setErrors({response: e.message})
    } finally {
      setIsLoading(false)
    }
  }

  function validateNewPasswordForm(fields) {
    const {newPassword, newPassword2, phoneNumber} = fields
    const errors = {}
    if (!Object.keys(fields).length) return setErrors(errors)
    validatePassword(newPassword, errors)
    if (!errors.password && newPassword2 && newPassword2 !== newPassword)
      errors.password2 = "Passwords don't match"
    Object.assign(errors, validatePhoneNumber(phoneNumber))
    setErrors(errors)
  }

  function renderNewPasswordForm() {
    if (!errors.phoneNumber || errors.phoneNumber === 'Not enough digits') {
      if (fields.phoneNumber.length === 3 || fields.phoneNumber.length === 7) fields.phoneNumber += '-'
    }
    return (
      <Form className="form-page" onSubmit={handleSubmitNewPassword}>
        <div className="title">Password Change Required</div>
        <FormGroup controlId="newPassword">
          <FormLabel>New Password</FormLabel>
          <FormControl
            type="password"
            value={fields.newPassword}
            onChange={handleFieldChange}
            autoComplete="off"
          />
          {errors.password && <Alert variant={'danger'}>{errors.password}</Alert>}
        </FormGroup>
        <FormGroup controlId="newPassword2">
          <FormLabel>Confirm New Password</FormLabel>
          <FormControl
            type="password"
            value={fields.newPassword2}
            onChange={handleFieldChange}
            autoComplete="off"
          />
          {errors.password2 && <Alert variant={'danger'}>{errors.password2}</Alert>}
        </FormGroup>
        {phoneNumberRequired && (
          <FormGroup controlId="phoneNumber">
            <FormLabel>Mobile Phone</FormLabel>
            <FormControl
              type="tel"
              value={fields.phoneNumber}
              onChange={handleFieldChange}
              autoComplete="tel-national"
              placeholder="XXX-XXX-XXXX"
            />
            {errors.phoneNumber && <Alert variant={'danger'}>{errors.phoneNumber}</Alert>}
          </FormGroup>
        )}
        {errors.response && <Alert variant={'danger'}>{errors.response}</Alert>}
        <div className="button-row">
          <LoaderButton type="submit" isLoading={isLoading} disabled={Object.keys(errors).length > 0}>
            Submit
          </LoaderButton>
        </div>
      </Form>
    )
  }

  return (
    <FormPage>
      {!challengedUser && renderLoginForm()}
      {challengedUser && challengedUser.challengeName === 'SMS_MFA' && renderMfaForm()}
      {challengedUser && challengedUser.challengeName === 'NEW_PASSWORD_REQUIRED' && renderNewPasswordForm()}
    </FormPage>
  )
}
