import React, {useEffect, useState} from 'react'
import {Alert, Form, Modal} from 'react-bootstrap'
import './ImageCropperModal.scss'
import LoaderButton from '../components/LoaderButton'
import {createImageSourceUrl, cropImage, ImageEdit} from './ImageEdit'

export default function ImageCropperModal(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [initialImageSource, setInitialImageSource] = useState(null)
  const [initialCroppedImage, setInitialCroppedImage] = useState(null)
  const [imageSource, setImageSource] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  useEffect(() => {
    ;(async () => {
      const imageSourceUrl = props.imageSource ? await createImageSourceUrl(props.imageSource) : null
      setIsLoading(false)
      setErrors({})
      setInitialImageSource(imageSourceUrl)
      setInitialCroppedImage(null)
      setImageSource(imageSourceUrl)
      setCroppedImage(null)
    })()
  }, [props.resetKey, props.imageSource])
  async function onCropComplete(croppedAreaPixels) {
    const croppedImage = await cropImage(imageSource, croppedAreaPixels, 100, 100)
    setCroppedImage(croppedImage)
    if (!initialCroppedImage) setInitialCroppedImage(croppedImage)
  }

  async function handleSubmit(event) {
    try {
      event.preventDefault()
      setIsLoading(true)
      await props.handleSubmit(croppedImage)
    } catch (e) {
      setErrors(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleRemove(event) {
    try {
      event.preventDefault()
      setIsLoading(true)
      await props.handleRemove()
    } catch (e) {
      setErrors(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleFileSelection = event => {
    setImageSource(URL.createObjectURL(event.target.files[0]))
  }

  const classPrefix = props.classPrefix ? `${props.classPrefix}-` : ''
  return (
    props.show && (
      <Modal
        className={`${classPrefix}image-cropper-modal`}
        size="sm"
        show={props.show}
        animation={true}
        backdrop="static"
        onHide={() => props.setShow(false)}
      >
        <button className={'cancel'} onClick={() => props.onCancel()}>
          x
        </button>
        <Modal.Header>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="container image-edit-form " onSubmit={handleSubmit}>
            {errors.response && <Alert variant={'danger'}>{errors.response}</Alert>}
            <ImageEdit
              {...{
                cropShape: 'round',
                handleFileSelection,
                imageSource,
                onCropComplete,
                resetKey: props.resetKey,
              }}
            />
            {!!imageSource && (
              <div className="cropped-row">
                <div className={'cropped-image-panel'}>
                  {croppedImage && <img src={croppedImage} alt={'cropping'} />}
                </div>
                <LoaderButton
                  type="submit"
                  isLoading={isLoading}
                  disabled={imageSource === initialImageSource && croppedImage === initialCroppedImage}
                >
                  Save
                </LoaderButton>
                {initialImageSource && (
                  <LoaderButton onClick={handleRemove} isLoading={isLoading} disabled={!croppedImage}>
                    Remove
                  </LoaderButton>
                )}
              </div>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    )
  )
}
