import {titleCase} from './utilLib'

export const getDevices = userMap =>
  [].concat(...Object.values(userMap.accounts).map(({devices}) => Object.values(devices)))
export const username = props => {
  const {email, family_name, given_name} = props
  let name
  if (family_name || given_name) name = [given_name || '', family_name || ''].join(' ')
  else {
    if (email) {
      const match = email.match(/^([a-zA-Z]+)\.*([a-zA-Z]+)@.*\..*$/)
      if (match) name = titleCase(match.slice(0, 2).join(' '))
      else name = email
    } else {
      name = JSON.stringify(props)
    }
  }
  return name
}
export const initials = user => {
  try {
    if (!user.given_name || !user.family_name) {
      return '??'
    }
    return user.given_name.substr(0, 1) + user.family_name.substr(0, 1)
  } catch (e) {
    return ''
  }
}
export const isAdmin = (accounts = [], devices = []) =>
  Object.values(accounts).filter(a => a.isAdmin).length > 0 ||
  Object.values(devices).filter(a => a.isAdmin).length > 0
