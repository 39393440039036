import React from 'react'
import './FormPage.scss'

export default function FormPage({children, ...rest}) {
  return (
    <div className="container-fluid form-page" {...rest}>
      <img className="jacobi-wheel" src="jacobi-logo.png" alt="" />
      <img className="sunwize-logo" src="sunwize-logo.png" alt="" />
      {children}
    </div>
  )
}
