export const units = {
  ambientTemp: '°C',
  arrayCurrent: 'Amps',
  arrayVoltage: '% Of Nominal',
  batteryCurrent: 'Amps',
  batteryVoltage: '% Of Nominal',
  loadCurrent: 'Amps',
  loadVoltage: '% Of Nominal',
}
export const modbusOnlyFields = [
  'factor',
  'mask',
  'registerAddress',
  'shift',
  'modbusDeviceTypeGroupSelections',
]
export const metricOnlyFields = ['salesOrderSelections']

export const operators = [
  {value: '', label: 'Select One'},
  {value: '<=', label: 'Less Than or Equal To'},
  {value: '>=', label: 'Greater Than Or Equal To'},
  {value: '<', label: 'Less Than'},
  {value: '>', label: 'Greater Than'},
  {value: '=', label: 'Equal To'},
]
export const metricsOperators = operators.filter(({value}) => ['', '<=', '>='].includes(value))
export const booleanOperators = operators.filter(({value}) => value === '=')
export const eventSeverityLevels = {
  info: {value: 'info', label: 'Info'},
  warning: {value: 'warning', label: 'Warning'},
  alarm: {value: 'alarm', label: 'Alarm'},
  recovery: {value: 'recovery', label: 'Recovery'},
}
export const eventTypeMap = {
  ambientTemp: {
    label: 'Ambient Temp',
    units: units.ambientTemp,
    operators: metricsOperators,
    isMetricEventType: true,
    operandType: 'float',
  },
  arrayCurrent: {
    label: 'Array Current',
    units: units.arrayCurrent,
    operators: metricsOperators,
    isMetricEventType: true,
    min: 0,
    operandType: 'float',
  },
  arrayVoltage: {
    label: 'Array Voltage',
    units: units.arrayVoltage,
    operators: metricsOperators,
    labels: {operand: 'This Percent of System Voltage'},
    isMetricEventType: true,
    min: 0,
    operandType: 'float',
  },
  batteryCurrent: {
    label: 'Battery Current',
    units: units.batteryCurrent,
    operators: metricsOperators,
    isMetricEventType: true,
    min: 0,
    operandType: 'float',
  },
  batteryVoltage: {
    label: 'Battery Voltage',
    units: units.batteryVoltage,
    operators: metricsOperators,
    labels: {operand: 'This Percent of System Voltage'},
    min: 0,
    isMetricEventType: true,
    operandType: 'float',
  },
  loadCurrent: {
    label: 'Load Current',
    units: units.loadCurrent,
    operators: metricsOperators,
    isMetricEventType: true,
    min: 0,
    operandType: 'float',
  },
  loadVoltage: {
    label: 'Load Voltage',
    units: units.loadVoltage,
    operators: metricsOperators,
    labels: {operand: 'This Percent of System Voltage'},
    min: 0,
    isMetricEventType: true,
    operandType: 'float',
  },
  float16: {
    label: 'Charge Controller Float16 Register Value',
    requires: {
      factor: true,
    },
    isModbusEventType: true,
    operandType: 'float',
  },
  uint16: {
    label: 'Charge Controller Register Uint16 Value',
    requires: {
      factor: true,
    },
    isModbusEventType: true,
    operandType: 'uint16',
  },
  signedUint7: {
    label: 'Charge Controller SignedUint7 Register Value',
    requires: {
      shift: true,
      factor: true,
    },
    isModbusEventType: true,
    operandType: 'signedUint7',
    min: (-1 << 7) + 1,
    max: (1 << 7) - 1,
  },
  signedUint15: {
    label: 'Charge Controller SignedUint15 Register Value',
    requires: {
      factor: true,
    },
    isModbusEventType: true,
    operandType: 'signedUint15',
    min: (-1 << 15) + 1,
    max: (1 << 15) - 1,
  },
  boolean: {
    label: 'Charge Controller Boolean Register Value',
    requires: {
      mask: true,
    },
    operators: booleanOperators,
    operands: [
      {value: '', label: 'Select One'},
      {value: 'true', label: 'True'},
      {value: 'false', label: 'False'},
    ],
    labels: {
      operand: null,
    },
    isModbusEventType: true,
    operandType: 'boolean',
  },
  booleanUint16: {
    label: 'Charge Controller Register Value',
    requires: {
      mask: true,
    },
    operators: booleanOperators,
    isModbusEventType: true,
    operandType: 'uint16',
    min: 0,
    max: (1 << 15) - 1,
  },
}
