import React, {useEffect, useState} from 'react'
import moment from 'moment'
import './SystemsList.scss'
import Table from './Table'
import {apiGet} from '../libs/apiLib'
import parseHTML from 'react-html-parser'
import {
  ChargeControllerCell,
  ContactsCell,
  CountCell,
  DateCell,
  DegreesCell,
  EditableCell,
  ElidedCell,
  LocationCell,
  OptionsCell,
  SystemsCell,
  TimeSinceCell,
  UserLinkCell,
} from './TableCells'
import {getFunctionName} from '../libs/utilLib'
import DeploySystem from './DeploySystem'
import AnchorButton from './AnchorButton'
import {LinkContainer} from 'react-router-bootstrap'
import AutoSuggest from 'react-autosuggest'
import Busy from './Busy'

const api = apiGet()

export const SolarModuleCell = ({options, row: {index}, column: {id}, onUpdate, value}) => {
  return (
    <OptionsCell
      {...{
        options,
        value,
        update: value => onUpdate(parseInt(value), index, id),
        className: 'solar-module-id',
      }}
    />
  )
}
export const BatteryTypeCell = ({options, row: {index}, column: {id}, onUpdate, value}) => {
  return (
    <OptionsCell
      {...{
        options,
        value,
        update: value => onUpdate(parseInt(value), index, id),
        className: 'battery-type-id',
      }}
    />
  )
}
export default function SystemsList(props) {
  // const [modalShow, setModalShow] = useState(false)
  const {
    user,
    location: {pathname},
  } = props
  if (!user) return <Busy />
  const devices = Object.values(user.allDevices || user.devices || []).sort((a, b) =>
    a.name > b.name ? 1 : -1
  )
  const deviceId = devices.length > 1 ? pathname.split('/')[2] : devices.length ? devices[0].id : null
  const [data, setData] = useState(deviceId ? devices.filter(d => d.id === parseInt(deviceId)) : devices)
  const [isAnAdmin] = useState(
    Object.values(user.allAccounts || user.accounts || []).filter(a => a.isAdmin).length > 0
  )
  const users = user.allUsers || user.users
  const [solarModuleOptions, setSolarModuleOptions] = useState([])
  const [batteryTypeOptions, setBatteryTypeOptions] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const onUpdate = async (value, row, column) => {
    const {id} = data[row]
    const result = await api.put(`/device/${id}`, {[column]: value})

    data[row][column] = value
    /* server adds deployedBy , so update locally */
    if (column === 'deployedTimestamp') data[row]['deployedBy'] = users[result.data.deployedBy]
    setData([...data])
  }

  useEffect(() => {
    let mounted = true
    try {
      if (!batteryTypeOptions.length) {
        api.get('/batteryType').then(({data}) => {
          if (!mounted) return
          setBatteryTypeOptions(
            data
              .sort((a, b) => (a.model > b.model ? 1 : -1))
              .map(({id, model, c100AmpHours}) => {
                return {value: id, display: `${model} (${c100AmpHours} AH)`}
              })
          )
        })
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e.message)
    }
    return () => (mounted = false)
  }, [batteryTypeOptions])
  useEffect(() => {
    let mounted = true
    try {
      if (!solarModuleOptions.length) {
        api.get('/solarModule').then(result => {
          if (!mounted) return
          setSolarModuleOptions(
            result.data
              .sort((a, b) => (a.model > b.model ? 1 : -1))
              .map(({id, model, pMPP, vMPP, iMPP}) => {
                return {value: id, display: `${model} (${pMPP}W, ${vMPP}V, ${iMPP}A)`}
              })
          )
        })
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e.message)
    }
    return () => (mounted = false)
  }, [solarModuleOptions])
  const DeploymentCell = props => {
    const {
      value: deployedTimestamp = '',
      row: {index, original: device},
      column: {id},
      user,
      users,
      allUsers,
    } = props
    const [show, setShow] = useState(false)
    return (
      <div className="deployment-cell">
        <DeploySystem {...{show, setShow, user, device, index, id, onUpdate}} />
        {deployedTimestamp ? (
          <div className="deployment-status">
            <div className="deployed-by">
              {'Deployed by'}
              <pre> </pre>
              {device.deployedBy ? (
                <UserLinkCell value={device.deployedBy} users={allUsers || users} />
              ) : (
                'D.B. Cooper'
              )}
              , <DateCell value={deployedTimestamp} />
            </div>
            <hr />
            <AnchorButton disabled={!user.isSuperuser} onClick={() => setShow(true)}>
              Redeploy
            </AnchorButton>
          </div>
        ) : (
          <div>
            <div className="deployment-status">Not Deployed</div>
            <hr />
            <AnchorButton disabled={!user.isSuperuser} onClick={() => setShow(true)}>
              Deploy
            </AnchorButton>
          </div>
        )}
      </div>
    )
  }

  /* Filter the columns based on superuser and admin status. Prevent triple filtering by waiting until
   * battery and solar module arrays have been fetched
   */
  // noinspection JSUnresolvedFunction
  const columns =
    !batteryTypeOptions.length || !solarModuleOptions.length
      ? []
      : [
          {
            Header: 'Identifiers',
            columns: [
              {
                accessor: 'name',
                Header: 'Project Name',
                default: true,
                Cell: props => (
                  <SystemsCell onUpdate={onUpdate} index={props.row.id} value={props.row.original} />
                ),
              },
              {accessor: 'description', Header: 'Description', Cell: EditableCell},
              {accessor: 'imei', Header: 'IMEI', Cell: ElidedCell, so: true},
              {accessor: 'iccid', Header: 'ICCID', default: true, Cell: ElidedCell, so: true},
              {accessor: 'id', Header: 'Mysql', so: true},
              {accessor: 'salesforceId', Header: 'Salesforce', Cell: ElidedCell, so: true},
            ],
          },
          {
            Header: 'Status',
            columns: [
              {
                accessor: 'metricsTimestamp',
                Header: 'Last Contact',
                Cell: props => (
                  <TimeSinceCell
                    {...props}
                    clazz={
                      moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).diff(props.value) >
                      24 * 60 * 60 * 1000
                        ? 'CRITICAL'
                        : ''
                    }
                  />
                ),
              },
              {accessor: 'location', Header: 'Current Location', default: true, Cell: LocationCell},
            ],
          },
          {
            Header: 'Deployment',
            columns: [
              {accessor: 'lastModifiedBy', Header: 'Last Modified By', Cell: UserLinkCell, ao: true},
              {
                accessor: 'deployedTimestamp',
                Header: 'Deployment Status',
                default: true,
                Cell: DeploymentCell,
                so: true,
              },
              {accessor: 'arrayAzimuth', Header: 'Array Azimuth', Cell: DegreesCell},
              {accessor: 'arrayTilt', Header: 'Array Tilt', Cell: DegreesCell},
              {accessor: 'deploymentGitHash', Header: 'Version Hash', so: true},
            ],
          },
          {
            Header: 'Account',
            columns: [
              {
                accessor: 'accountId',
                Header: 'Account Name',
                default: true,
                Cell: props => {
                  const {value: accountId} = props
                  const accounts = props.allAccounts || props.accounts
                  return accountId ? (
                    <LinkContainer to={`/account/${accountId}`}>
                      <AnchorButton>{accounts[accountId].name}</AnchorButton>
                    </LinkContainer>
                  ) : null
                },
              },
              {
                accessor: 'accountContacts',
                Header: 'Account Members',
                Cell: props => (
                  <ContactsCell
                    {...{
                      ...props,
                      joinType: 'account',
                      users: Object.values(user.users || user.allUsers),
                      contacts: Object.values(props.value || {}),
                      joinTypeId: props.row.original.accountId,
                    }}
                  />
                ),
              },
            ],
            ao: true,
          },
          {
            accessor: 'contacts',
            Header: 'System Users',
            ao: true,
            Cell: props => (
              <ContactsCell
                {...{
                  ...props,
                  joinType: 'device',
                  users: Object.values(user.users || user.allUsers),
                  contacts: Object.values(props.value || {}),
                  joinTypeId: props.row.original.id,
                }}
              />
            ),
          },
          {
            Header: 'Sales Input',
            columns: [
              {accessor: 'batteryGrade', Header: 'Battery Grade'},
              {accessor: 'c100BatteryCapacity', Header: 'C100 Battery Capacity'},
              {
                accessor: 'chargeControllerModel',
                Header: 'Charge Controller Model',
                Cell: ChargeControllerCell,
              },
              {accessor: 'createdBy', Header: 'Created By', Cell: UserLinkCell},
              {accessor: 'createdDate', Header: 'Created Date', sortType: 'datetime', Cell: DateCell},
              {accessor: 'opportunity', Header: 'Opportunity'},
              {accessor: 'ownedBy', Header: 'Owner', Cell: UserLinkCell},
              {accessor: 'solarArrayCellsInSeries', Header: 'Solar Array Cells In Series'},
              {accessor: 'solarArrayPower', Header: 'Solar Array Power'},
            ],
          },

          {
            Header: 'Design Specifications',
            columns: [
              {accessor: 'nominalBatteryVoltage', Header: 'System Voltage'},
              {
                accessor: 'batteryTypeId',
                Header: 'Battery',
                Cell: props => <BatteryTypeCell options={batteryTypeOptions} {...props} />,
              },
              {accessor: 'batteryCount', Header: 'Battery Count', Cell: CountCell},
              {
                accessor: 'solarModuleId',
                Header: 'Solar Module',
                Cell: props => <SolarModuleCell options={solarModuleOptions} {...props} />,
              },
              {accessor: 'solarModuleCount', Header: 'Solar Module Count', Cell: CountCell},
              {accessor: 'loadType', Header: 'Load Type'},
              {accessor: 'avgContinuousLoadAmps', Header: 'Average Continuous Load Amps'},
              {accessor: 'avgDailyLoadAHAtBattery', Header: 'Daily Load AH At Battery'},
              {accessor: 'designDaysOfAutonomy', Header: 'Design Days Of Autonomy'},
              {accessor: 'designLOLP', Header: 'Design LOLP'},
              {accessor: 'designMinimumArrayToLoadRatio', Header: 'Design Minimum Array To Load Ratio'},
            ],
          },
        ].filter(column => {
          const columnFilter = column => {
            const {so /* superuser only */, ao /* admin only */} = column || {}
            return user.isSuperuser || (!so && (isAnAdmin || !ao))
          }
          if (column.accessor) return columnFilter(column)
          else {
            column.columns = column.columns.filter(columnFilter)
            return true
          }
        })
  const onSuggestionsFetchRequested = async ({value}) => {
    if (value.length > 1) {
      const api = apiGet()
      const result = await api.get(
        `/device?search=${value}&attributes=${['systemPhoto', 'name']}&withHighlights=1`
      )
      setSuggestions(result.data.device)
    }
  }

  const onSuggestionsClearRequested = async () => {
    setSuggestions([])
    setSearchTerm('')
  }

  const onSearchTermChange = (event, {newValue}) => {
    // eslint-disable-next-line no-console
    console.log(`searchTerm: ${JSON.stringify(newValue)}`)
    setSearchTerm(newValue)
  }

  const onSuggestionSelected = (event, {suggestion}) => {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(suggestion))
  }

  return (
    <div className="systems-list">
      {/*<NewUserModal{...{setModalShow, user, show: modalShow, joinType: 'device'}}/>*/}
      <AutoSuggest
        {...{
          getSuggestionValue: value => value,
          inputProps: {
            onChange: onSearchTermChange,
            value: searchTerm,
            type: 'search',
            placeholder: 'Enter a search term',
          },
          onSuggestionsClearRequested,
          onSuggestionsFetchRequested,
          onSuggestionSelected,
          renderSuggestion: device => {
            return (
              <div className={'suggestion'}>
                <div className="name-and-photo">
                  {device['System Photo'] && (
                    <img className="system-photo" src={device['System Photo']} alt="system" />
                  )}
                  <div className="system-name">{parseHTML(device.Name)}</div>
                </div>
                {Object.keys(device)
                  .filter(key => !['id', 'System Photo', 'Name'].includes(key))
                  .sort()
                  .map(key => (
                    <div className={'field'} key={key}>
                      <div className={'name'}>{`${key}`}</div>
                      <div className={'value'}>{parseHTML(device[key])}</div>
                    </div>
                  ))}
              </div>
            )
          },
          suggestions: suggestions || [], // alway pass an array to avoid crashes
        }}
      />
      {!searchTerm.length && (
        <Table
          {...{
            cellInjections: {
              users: user.users,
              allUsers: user.allUsers,
              user,
              accounts: user.accounts,
              allAccounts: user.allAccounts,
              api,
              onUpdate,
            },
            columns,
            storageKey: getFunctionName(),
            data: data,
          }}
        />
      )}
    </div>
  )
}
