import React, {useCallback, useEffect, useState} from 'react'
import timezoneMoment from 'moment-timezone'
import {formatPhoneNumber, validateEmail} from '../libs/validateLib'
import {PropTypes} from 'prop-types'
import './TableCells.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ReactSelect from 'react-select'
import diff from 'diff-arrays-of-objects'
import SystemSettingsModal from './SystemSettingsModal'
import {username} from '../libs/userLib'
import {titleCase} from '../libs/utilLib'
import TimeSince from './TimeSince'
import {LinkContainer} from 'react-router-bootstrap'
import AnchorButton from './AnchorButton'

const sunwizeBlue = '#005FA5'
const sunwizeYellow = '#FFC132'

export const SystemsCell = props => {
  let devices = Array.isArray(props.value) ? props.value : props.value ? [props.value] : []
  return (
    <div className="systems-cell">
      {devices.map(device => {
        return (
          <div key={device.id} className="system-cell">
            <LinkContainer to={`/system/${device.id}`}>
              <button>
                {device.systemPhoto && (
                  <img src={device.systemPhoto} className={'system-photo'} alt={'system'} />
                )}
              </button>
            </LinkContainer>
            <LinkContainer to={`/system/${device.id}`}>
              <AnchorButton>{device.name}</AnchorButton>
            </LinkContainer>
            <SystemSettingsModal onUpdate={props.onUpdate} device={device} index={props.index} />
          </div>
        )
      })}
    </div>
  )
}
export const EditableCell = ({
  value: initialValue = '',
  row: {index},
  column: {id},
  onUpdate,
  disabled = false,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue)
  const [editMode, setEditMode] = useState(false)
  const onChange = e => {
    setValue(e.target.value)
  }

  const onBlur = () => {
    // refocus when update fails
    setEditMode(false)
    if (value !== initialValue) onUpdate(value, index, id)
  }

  const onKeyUp = async ({key}) => {
    if (key === 'Enter') {
      onBlur()
    } else if (key === 'Escape') {
      setValue(initialValue)
      setEditMode(false)
    }
  }

  useEffect(() => {
    if (editMode) document.getElementById(`${id}|${index}`).focus()
  }, [editMode, id, index])

  return (
    <div className="editable-cell">
      {editMode ? (
        <input id={`${id}|${index}`} {...{value, onChange, onBlur, onKeyUp}} />
      ) : (
        <div
          className="inactive"
          onDoubleClick={() => {
            if (!disabled) setEditMode(true)
          }}
        >
          {!disabled && (
            <div className="pencil">
              <FontAwesomeIcon icon="pencil-alt" />
            </div>
          )}
          <div className="value">{value}</div>
        </div>
      )}
    </div>
  )
}
const ControlledEditableCell = ({
  value,
  disabled = false,
  update,
  onChange,
  onCancel,
  formatValue = value => value,
  validate = () => true,
  className = '',
}) => {
  const [id] = useState(Math.floor(Math.random() * 1000000000))
  const [editMode, setEditMode] = useState(false)
  const onBlur = () => {
    // refocus when update fails
    if (update()) {
      return setEditMode(false)
    }
    setFocus()
  }

  const onKeyUp = async ({key}) => {
    if (key === 'Enter') {
      onBlur()
    } else if (key === 'Escape') {
      onCancel()
      setEditMode(false)
    }
  }
  const onDoubleClick = () => {
    if (!disabled) setEditMode(true)
  }
  const setFocus = () => document.getElementById(id).focus()
  const setFocusCallback = useCallback(setFocus, [setFocus])

  useEffect(() => {
    if (editMode) setFocusCallback()
  }, [editMode, setFocusCallback])

  return (
    <div className={`editable-cell controlled ${className}`}>
      {editMode ? (
        <input
          {...{
            onBlur,
            onChange,
            onKeyUp,
            className: `${className} ${validate(value) ? '' : 'error'}`,
            value: formatValue(value) || ' ',
            id,
          }}
        />
      ) : (
        <div className="inactive" onDoubleClick={onDoubleClick}>
          {!disabled && (
            <div className="pencil">
              <FontAwesomeIcon icon="pencil-alt" />
            </div>
          )}
          <div className="value">{validate(value) ? formatValue(value) : value || ' '}</div>
        </div>
      )}
    </div>
  )
}
ControlledEditableCell.propTypes = {
  value: PropTypes.string,
  update: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  formatValue: PropTypes.func,
  validate: PropTypes.func,
  className: PropTypes.string,
}
export const ElidedCell = ({value, nShown = 4}) => {
  if (!value) return null
  return (
    <div title={value} className="elided-cell">
      {value.length <= nShown ? value : `...${value.substring(value.length - nShown)}`}
    </div>
  )
}
export const CountCell = props => {
  const {
    row: {index},
    column: {id},
    value: initialValue = ' ',
  } = props
  const formatValue = value => value
  const [value, setValue] = useState(String(initialValue))
  const validate = value => value && value.match(/^0$|^[1-9]?[0-9]*$/)
  const onChange = ({target: {value}}) => {
    if (value !== '0') value = value.replace(/[^0-9]/g, '').substring(0, 10)
    setValue(value)
  }
  const onCancel = () => setValue(initialValue)
  const update = () => {
    if (value === initialValue) return true
    if (validate(value)) {
      props.onUpdate(parseInt(value), index, id)
      return true
    }
    return false
  }

  const inputProps = {
    validate,
    value: value || ' ',
    className: 'phone',
    onChange,
    onCancel,
    formatValue,
    update,
  }
  return <ControlledEditableCell {...inputProps} />
}
export const PhoneCell = props => {
  const {
    row: {index},
    column: {id},
    value: initialValue = ' ',
    disabled = false,
  } = props
  const formatValue = formatPhoneNumber
  const [value, setValue] = useState(initialValue)
  const validate = value => value && value.match(/^\+1[1-9][0-9]{2}[1-9][0-9]{6}$/)
  const onChange = e => {
    const backspace = e.target.value.length === formatPhoneNumber(value).length - 1
    if (backspace) setValue(value.length === 3 ? '' : value.substring(0, value.length - 1))
    else {
      const nationalNumber = e.target.value.replace(/[^0-9]/g, '').substring(0, 10)
      setValue(nationalNumber.length ? '+1' + e.target.value.replace(/[^0-9]/g, '').substring(0, 10) : '')
    }
  }
  const onCancel = () => setValue(initialValue)
  const update = () => {
    if (value === initialValue) return true
    if (validate(value)) {
      props.onUpdate(value, index, id)
      return true
    }
    return false
  }

  const inputProps = {
    validate,
    value: value || ' ',
    className: 'phone',
    onChange,
    onCancel,
    formatValue,
    update,
    disabled,
  }
  return <ControlledEditableCell {...inputProps} />
}
export const EmailCell = props => {
  const {
    row: {index},
    column: {id},
    value: initialValue = ' ',
  } = props
  const [value, setValue] = useState(initialValue)
  const validate = value => value && !validateEmail(value).email
  const onChange = e => setValue(e.target.value)
  const onCancel = () => setValue(initialValue)
  const update = () => {
    if (value === initialValue) return true
    if (validate(value)) {
      props.onUpdate(value, index, id)
      return true
    }
    return false
  }

  const inputProps = {validate, value: value || ' ', className: 'phone', onChange, onCancel, update}
  return <ControlledEditableCell {...inputProps} />
}
export const DateCell = ({value}) => (value ? timezoneMoment(value).format('MMMM Do, YYYY') : '')
export const DateTimeCell = ({value}) =>
  value ? timezoneMoment(value).format('MMMM Do, YYYY [at] h:mm A z') : ''
export const TimeSinceCell = ({value}) => (value ? <TimeSince timestamp={value} /> : '—')
export const LocationCell = ({value}) =>
  value
    ? `${Math.abs(value.latitude)}${value.latitude > 0 ? '°N' : '°S'}, ${Math.abs(value.longitude)}${
        value.longitude > 0 ? '°E' : '°W'
      }, ↑${value.altitudeMeters}M ±${value.accuracyMeters}M`
    : ''
export const DegreesCell = ({value}) => (value ? `${value}°` : '')
export const UserLinkCell = props => {
  const {value: user, suffix = ''} = props
  return typeof user === 'object' ? (
    <LinkContainer to={`/user/${user.cognitoUsername}`}>
      <AnchorButton>
        {username(user)}
        {suffix}
      </AnchorButton>
    </LinkContainer>
  ) : (
    ''
  )
}
export const ChargeControllerCell = ({value}) => {
  if (!value) return null
  if (value.match(/^PS-/i))
    return <a href="https://www.morningstarcorp.com/products/prostar/#specifications">{value}</a>
  if (value.match(/^SS-MPPT-/i))
    return <a href="https://www.morningstarcorp.com/products/sunsaver-mppt">{value}</a>
  if (value.match(/^TS-MPPT-/i))
    return <a href="https://www.morningstarcorp.com/products/tristar-mppt">{value}</a>
  return value
}
export const OptionsCell = ({value: initialValue, options, update, className = ''}) => {
  const [id] = useState(String(Math.floor(Math.random() * 1000000000)))
  const setFocus = () => document.getElementById(id).focus()
  const setFocusCallback = useCallback(setFocus, [setFocus])

  const [editMode, setEditMode] = useState(false)
  const [value, setValue] = useState(initialValue)
  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    if (editMode) setFocusCallback()
  }, [editMode, setFocusCallback])

  const onChange = e => {
    const value = e.target.value
    switch (typeof initialValue) {
      case 'boolean':
        setValue(value === 'true')
        break
      case 'number':
        setValue(parseInt(value))
        break
      default:
        setValue(value)
    }
  }
  const onBlur = () => {
    if (value !== initialValue) update(value)
    setEditMode(false)
  }
  const onKeyUp = async ({key}) => {
    if (key === 'Enter') {
      onBlur()
    } else if (key === 'Escape') {
      setValue(initialValue)
      setEditMode(false)
    }
  }
  const onDoubleClick = () => {
    setEditMode(true)
  }

  // if (value && options.length) {
  //   const option = options.filter(o => String(o.value) === String(value))
  //   if (!option.length) {
  //     console.error(`option not found: ${value} in ${JSON.stringify(options)}`)
  //   }
  // }
  return (
    <div className={`editable-cell options ${className}`}>
      {editMode ? (
        <select {...{id, value, onBlur, onChange, onKeyUp}}>
          {options.map(({display, value}) => (
            <option key={value} value={value}>
              {display}
            </option>
          ))}
        </select>
      ) : (
        <div className="inactive" onDoubleClick={onDoubleClick}>
          <div className="pencil">
            <FontAwesomeIcon icon="pencil-alt" />
          </div>
          <div className="value">
            {value && options.length ? options.filter(o => String(o.value) === String(value))[0].display : ''}
          </div>
        </div>
      )}
    </div>
  )
}
OptionsCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  update: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
}
export const BooleanCell = ({row: {index}, column: {id}, onUpdate, value = false}) => {
  return (
    <OptionsCell
      {...{
        options: [
          {display: 'No', value: false},
          {display: 'Yes', value: true},
        ],
        value,
        update: value => onUpdate(value, index, id),
        className: 'yes-no',
      }}
    />
  )
}
export const BooleanZeroOneYesNoCell = ({row: {index}, column: {id}, onUpdate, value = 0}) => {
  return (
    <OptionsCell
      {...{
        options: [
          {display: 'No', value: 0},
          {display: 'Yes', value: 1},
        ],
        value,
        update: value => onUpdate(parseInt(value), index, id),
        className: 'zero-one-yes-no',
      }}
    />
  )
}
export const MetricsCell = props => {
  const {
    row: {
      original: {metric, severity},
    },
    value: metrics,
  } = props
  const [open, setOpen] = useState(false)
  const values = []
  const labels = []
  let width = 0
  const onClick = () => setOpen(!open)
  Object.keys(metrics).forEach(key => {
    const {label, value, units} = metrics[key]
    width = Math.max(width, [label, value, units].join('').length + 1)
    labels.push(
      <div
        key={key}
        className={`label ${key === metric ? `${severity}` : ''} ${
          open || key === metric ? 'open' : 'closed'
        }`}
      >
        {label}:
      </div>
    )
    values.push(
      <div
        key={key}
        className={`value ${key === metric ? `${severity}` : ''} ${
          open || key === metric ? 'open' : 'closed'
        } `}
      >
        {[value, units].join(' ')}
      </div>
    )
  })
  return (
    <div
      className={`metrics-cell ${open ? 'open' : 'closed'}`}
      onClick={onClick}
      style={{width: `calc(${width / 2}rem)`}}
    >
      <div className="labels">{labels}</div>
      <div className="values">{values}</div>
    </div>
  )
}
const Select = props => {
  const [inputId] = useState(Math.floor(Math.random() * 1000000).toString())
  const [editMode, setEditMode] = useState(false)
  const [initialSelections, setInitialSelections] = useState(props.selections)
  const [selections, setSelections] = useState(initialSelections)
  const [options, setOptions] = useState([])
  const [removeMaxWidth, setRemoveMaxWidth] = useState('0')

  useEffect(() => {
    ;(async () => {
      const sleep = async millis =>
        new Promise(resolve => {
          setTimeout(resolve, millis)
        })
      if (editMode) {
        document.getElementById(inputId).focus()
        const interval = 20,
          duration = 250
        for (let millis = 0; millis < duration; millis += interval) {
          await sleep(interval)
          setRemoveMaxWidth(`${(40 * millis) / duration}px`)
        }
      }
    })()
  }, [editMode, inputId])

  const onBlur = async () => {
    const {added, removed} = diff(initialSelections, selections, 'value')
    if (added.length || removed.length) {
      await props.onBlur(added, removed)
      /* update initial selections now that they have been effected */
      setInitialSelections(selections)
    }
    setEditMode(false)
  }
  const onKeyDown = async ({key}) => {
    if (key === 'Enter') {
      onBlur()
    } else if (key === 'Escape') {
      setSelections(initialSelections)
      setEditMode(false)
    }
  }
  const onDoubleClick = () => {
    if (!editMode) {
      setOptions(typeof props.options === 'function' ? props.options() : props.options)
      setEditMode(true)
    }
  }
  const onChange = selections => {
    selections = selections || []
    selections = props.onChange ? props.onChange(selections) : selections
    setSelections(selections)
  }
  const {width} = props
  const height = editMode ? selections.length + 1 : selections.length
  return (
    <div
      className={`editable-cell cell ${editMode ? 'active' : 'inactive'}`}
      style={{
        width: width,
        maxHeight: `${height * 18}px`,
        minHeight: `${height * 18}px`,
        transition: 'max-height .25s linear, min-height .25s linear',
      }}
    >
      {editMode && (
        <ReactSelect
          classNamePrefix="react-select"
          className="react-select"
          isMulti
          autoFocus
          {...{value: selections, options, inputId, onChange, onKeyDown, onBlur}}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
          }}
          /* remove focus border and shadow */
          styles={{
            control: (base, state) => ({
              ...base,
              backgroundColor: '#00000000',
              border: state.isFocused ? 0 : 0,
              boxShadow: state.isFocused ? 0 : 0,
              '&:hover': {
                border: state.isFocused ? 0 : 0,
              },
              minHeight: '12px',
            }),
            input: base => ({
              ...base,
              backgroundColor: '#00000000',
              padding: 0,
              margin: 0,
            }),
            valueContainer: base => ({
              ...base,
              display: 'block',
              backgroundColor: '#00000000',
              padding: 0,
            }),
            multiValue: base => ({
              ...base,
              backgroundColor: '#00000000',
              margin: 0,
            }),
            multiValueRemove: base => ({
              ...base,
              margin: '0 2px',
              maxWidth: removeMaxWidth,
              backgroundColor: '#00000000',
              '&:hover': {
                backgroundColor: sunwizeBlue,
                color: sunwizeYellow,
              },
              transition: 'background-color .25s linear',
            }),
            multiValueLabel: base => ({
              ...base,
              padding: '0 1px',
              paddingLeft: '1px',
              fontSize: '12px',
            }),
          }}
        />
      )}
      {!editMode && (
        <div className="inactive" onDoubleClick={onDoubleClick}>
          <div className="pencil">
            <FontAwesomeIcon icon="pencil-alt" />
          </div>
          <div className="values">
            {selections.map(({value, label}) => (
              <div className="value" key={value}>
                {label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
const displayApiError = (e, props) => {
  const {
    response: {statusText},
  } = e
  props.displayError(`Action not allowed: ${statusText}`)
}
export const UserJoinCell = props => {
  const {
    row: {
      original: {cognitoUsername},
    },
    user,
    api,
    joinType,
    route = joinType,
  } = props
  const joinTypes = props.row.original[joinType + 's']
  const [width] = useState(() => {
    let width = 0
    Object.values(user[joinType + 's']).forEach(a => (width = Math.max(width, a.name.length)))
    return `${(width + ' (Administrator)'.length) * 13}px`
  })
  const sort = (a, b) => (a.sortValue > b.sortValue ? 1 : -1)
  const toSelection = ({name, isAdmin, id}) => {
    return isAdmin
      ? {
          isSelection: true,
          sortValue: `${name} (Administrator)`,
          value: `${id}|Administrator`,
          label: (
            <LinkContainer to={`/${route}/${id}`}>
              <AnchorButton>{name} (Administrator)</AnchorButton>
            </LinkContainer>
          ),
        }
      : {
          isSelection: true,
          sortValue: `${name} (Contact)`,
          value: `${id}|Contact`,
          label: (
            <LinkContainer to={`/${route}/${id}`}>
              <AnchorButton>{name} (Contact)</AnchorButton>
            </LinkContainer>
          ),
        }
  }
  const selections = joinTypes ? Object.values(joinTypes).map(toSelection).sort(sort) : []

  const options = () =>
    Object.values(user[`all${titleCase(joinType)}s`] || user[joinType + 's'])
      .map(({id, name}) => {
        return {
          label: name,
          options: [
            {
              name,
              id,
              sortValue: `${name} (Contact)`,
              value: `${id}|Contact`,
              label: (
                <span>
                  {name}
                  {' (Contact)'}
                </span>
              ),
            },
            {
              name,
              id,
              isAdmin: true,
              sortValue: `${name} (Administrator)`,
              value: `${id}|Administrator`,
              label: (
                <span>
                  {name}
                  {' (Administrator)'}
                </span>
              ),
            },
          ],
        }
      })
      .sort(sort)

  function onChange(selectedOptions) {
    selectedOptions = selectedOptions || []
    // convert added options to selections
    selectedOptions = selectedOptions.map(option => {
      /* is already a selection value? */
      if (option.isSelection) return option
      return toSelection(option)
    })
    /* when user selects both a contact and admin role for the same joinType, discard the older choice */
    const selectionsMap = {}
    for (const selection of selectedOptions || []) selectionsMap[selection.value.split('|')[0]] = selection
    return Object.values(selectionsMap).sort(sort)
  }

  const onBlur = async (addedSelections, removedSelections) => {
    const mods = [],
      creates = [],
      deletes = [],
      addMap = {},
      deleteMap = {}
    addedSelections.forEach(selection => {
      const [id, type] = selection.value.split('|')
      const joinTypeId = parseInt(id)
      const isAdmin = type === 'Administrator'
      addMap[joinTypeId] = {joinTypeId, isAdmin}
    })
    removedSelections.forEach(selection => (deleteMap[parseInt(selection.value.split('|')[0])] = true))
    const joinTypeIds = [...new Set([...Object.keys(addMap), ...Object.keys(deleteMap)])].map(id =>
      parseInt(id)
    )
    joinTypeIds.forEach(joinTypeId => {
      if (deleteMap[joinTypeId] && addMap[joinTypeId]) mods.push(addMap[joinTypeId])
      else if (deleteMap[joinTypeId]) deletes.push(joinTypeId)
      else creates.push(addMap[joinTypeId])
    })
    try {
      await Promise.all(
        creates.map(async ({joinTypeId, ...rest}) => {
          try {
            await api.post(`/${joinType}/${joinTypeId}/user/${cognitoUsername}`, rest)
          } catch (e) {
            displayApiError(e, props)
          }
        })
      )
      await Promise.all(
        mods.map(async ({joinTypeId, ...rest}) => {
          try {
            await api.put(`/${joinType}/${joinTypeId}/user/${cognitoUsername}`, rest)
          } catch (e) {
            displayApiError(e, props)
          }
        })
      )
      await Promise.all(
        deletes.map(async joinTypeId => {
          try {
            await api.delete(`/${joinType}/${joinTypeId}/user/${cognitoUsername}`)
          } catch (e) {
            displayApiError(e, props)
          }
        })
      )
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message)
      props.displayError(e.message)
    }
  }
  return <Select {...{onBlur, selections, width, options, onChange}} />
}
export const ContactsCell = props => {
  const {contacts, users, joinType, joinTypeId, api} = props
  const [width] = useState(() => {
    let width = 0
    users.forEach(user => (width = Math.max(width, username(user).length)))
    return `${(width + ' (Administrator)'.length) * 7}px`
  })
  const toSelections = contacts =>
    contacts
      .map(user => {
        const name = username(user)
        const {cognitoUsername, isAdmin} = user
        return {
          sortValue: `${name}${isAdmin ? ' (Administrator)' : ''}`,
          value: `${cognitoUsername}${isAdmin ? '|Administrator' : '|Contact'}`,
          label: (
            <div>
              <LinkContainer to={`/user/${cognitoUsername}`}>
                <AnchorButton>{name}</AnchorButton>
              </LinkContainer>
              <span>{isAdmin ? ' (Administrator)' : ''}</span>
            </div>
          ),
        }
      })
      .sort((a, b) => (a.sortValue > b.sortValue ? 1 : -1))
  const selections = toSelections(contacts)
  const options = users
    .sort((u1, u2) => (username(u1) > username(u2) ? 1 : -1))
    .map(user => {
      const name = username(user)
      return {
        label: name,
        options: [
          {
            name,
            isAdmin: true,
            value: `${user.cognitoUsername}|Administrator`,
            label: <span>{`${name} (Administrator)`}</span>,
          },
          {
            name,
            value: `${user.cognitoUsername}|Contact`,
            label: <span>{`${name} (Contact)`}</span>,
          },
        ],
      }
    })
    .sort((a, b) => (a.label > b.label ? 1 : -1))

  const onChange = selectedOptions => {
    selectedOptions = selectedOptions || []
    /* when user selects both a contact and admin role for the same join, discard the older choice */
    selectedOptions = selectedOptions.map(option => {
      /* is already a selection value? */
      if (option.sortValue) return option

      /* convert option to selection */
      const {value, name, isAdmin} = option
      return {
        sortValue: `${name}${isAdmin ? ' (Administrator)' : ''}`,
        value,
        label: (
          <>
            <LinkContainer to={`/user/${option.value.split('|')[0]}`}>
              <AnchorButton>{name}</AnchorButton>
            </LinkContainer>
            <span>{isAdmin ? ' (Administrator)' : ''}</span>
          </>
        ),
      }
    })
    const selectionsMap = {}
    for (const selection of selectedOptions || []) selectionsMap[selection.value.split('|')[0]] = selection
    return Object.values(selectionsMap).sort((a, b) => (a.sortValue > b.sortValue ? 1 : -1))
  }

  const onBlur = async (addedSelections, removedSelections) => {
    const mods = [],
      creates = [],
      deletes = [],
      addMap = {},
      deleteMap = {}
    addedSelections.forEach(selection => {
      const [cognitoUsername, type] = selection.value.split('|')
      const isAdmin = type === 'Administrator'
      addMap[cognitoUsername] = {cognitoUsername, isAdmin}
    })
    removedSelections.forEach(selection => (deleteMap[selection.value.split('|')[0]] = true))
    const cognitoUsernames = [...new Set([...Object.keys(addMap), ...Object.keys(deleteMap)])]
    cognitoUsernames.forEach(cognitoUsernames => {
      if (deleteMap[cognitoUsernames] && addMap[cognitoUsernames]) mods.push(addMap[cognitoUsernames])
      else if (deleteMap[cognitoUsernames]) deletes.push(cognitoUsernames)
      else creates.push(addMap[cognitoUsernames])
    })
    await Promise.all(
      creates.map(async ({cognitoUsername, ...rest}) => {
        try {
          await api.post(`/${joinType}/${joinTypeId}/user/${cognitoUsername}`, rest)
        } catch (e) {
          displayApiError(e, props)
        }
      })
    )
    await Promise.all(
      mods.map(async ({cognitoUsername, ...rest}) => {
        try {
          await api.put(`/${joinType}/${joinTypeId}/user/${cognitoUsername}`, rest)
        } catch (e) {
          displayApiError(e, props)
        }
      })
    )
    await Promise.all(
      deletes.map(async cognitoUsername => {
        try {
          await api.delete(`/${joinType}/${joinTypeId}/user/${cognitoUsername}`)
        } catch (e) {
          displayApiError(e, props)
        }
      })
    )
  }
  return <Select {...{onBlur, selections, width, options, onChange}} />
}
