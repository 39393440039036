export const download = (data, mimeType = 'text/csv', filename = 'download.csv') => {
  const blob = new Blob([data], {type: mimeType})
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('hidden', '')
  a.setAttribute('href', url)
  a.setAttribute('download', filename)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
export const objectArrayToCSV = jsonArray => {
  const csvRows = []
  const headers = Object.keys(jsonArray[0])
  csvRows.push(headers.join(','))
  for (const row of jsonArray) {
    const values = headers.map(h => {
      const escaped = String(row[h]).replace(/"/g, '\\"')
      return `"${escaped}"`
    })
    csvRows.push(values.join(','))
  }
  return csvRows.join('\n')
}
